import React, { createContext, ReactNode, useContext } from "react";

import { IRentalCommissionApprovalService } from "../types/services/rental-commission-approval-service";

const RentalCommissionApprovalServiceContext = createContext<
  IRentalCommissionApprovalService
>(undefined as any);

interface CommissionApprovalServiceProviderProps {
  service: IRentalCommissionApprovalService;
  children: ReactNode;
}

function RentalCommissionApprovalServiceProvider(
  props: CommissionApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalCommissionApprovalServiceContext.Provider value={service}>
      {children}
    </RentalCommissionApprovalServiceContext.Provider>
  );
}

function useRentalCommissionApprovalService() {
  return useContext(RentalCommissionApprovalServiceContext);
}

export { RentalCommissionApprovalServiceProvider, useRentalCommissionApprovalService };

import * as Yup from "yup";

import { IndexedValue } from "../../types/indexed-value";

const EMAIL_VALIDATION = Yup.string().trim().email("invalid email");

const TRANSFORM_EMAIL_TO_INDEXED_VALUE = (value: string, iv?: IndexedValue) => {
  return {
    id: iv?.id,
    value: value,
  };
};

const TRANSFORM_EMAIL_TO_INDEXED_VALUE_UPDATED = (
  value: string,
  iv?: IndexedValue,
  destroy?:boolean,
) => {
  return {
    id: iv?.id,
    value: value,
    destroy:destroy,
  };
};

export default EMAIL_VALIDATION;

export { TRANSFORM_EMAIL_TO_INDEXED_VALUE ,TRANSFORM_EMAIL_TO_INDEXED_VALUE_UPDATED};

import React, { createContext, ReactNode, useContext } from "react";

import { IHomeAcquisitionService } from "../types/services/home-acquisition-service";

const HomeAcquisitionServiceContext = createContext<IHomeAcquisitionService>(
  undefined as any,
);

interface HomeAcquisitionServiceProviderProps {
  service: IHomeAcquisitionService;
  children: ReactNode;
}

function HomeAcquisitionServiceProvider(
  props: HomeAcquisitionServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <HomeAcquisitionServiceContext.Provider value={service}>
      {children}
    </HomeAcquisitionServiceContext.Provider>
  );
}

function useHomeAcquisitionService() {
  return useContext(HomeAcquisitionServiceContext);
}

export { HomeAcquisitionServiceProvider, useHomeAcquisitionService };

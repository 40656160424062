import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import clx from "classnames";
import React, { MouseEventHandler, useMemo } from "react";

import { useMe } from "../../../contexts/me-context";
import useCollapsed from "../../../hooks/use-collapsed";
import { Page } from "../../../types/page-template";
import LinkRouter from "../LinkRouter";
import useMobileHeaderStyles from "./MobileHeaderStyles";

interface MobileHeaderProps {
  name: string;
  routes: Page[];
  className?: string;
  isDropdown?: boolean;
  path?: string;
  defaultCollapsed?: boolean;
  onClick?: MouseEventHandler;
}

function MobileHeader(props: MobileHeaderProps) {
  const { hasAccessTo } = useMe(),
    classNames = useMobileHeaderStyles(),
    { defaultCollapsed = false } = props,
    { collapsed, toggleCollapse } = useCollapsed(defaultCollapsed),
    {
      name,
      routes: _routes,
      className,
      isDropdown = false,
      onClick,
      path = "#",
    } = props,
    routes: Page[] = useMemo(() => {
      if (!isDropdown) {
        return [];
      }

      return _routes.filter(route => {
        const { name, acl } = route,
          haveAccess = acl ? hasAccessTo(acl) : true;

        return name && haveAccess;
      });
    }, [isDropdown, _routes]),
    linkClassName = clx([classNames.link, className]),
    expandNode = (
      <span className="material-icons">
        {collapsed ? "expand_less" : "expand_more"}
      </span>
    );

  if (!isDropdown) {
    return (
      <ListItem>
        <LinkRouter
          className={linkClassName}
          color="inherit"
          onClick={onClick}
          to={path}
        >
          <ListItemText primary={name} />
        </LinkRouter>
      </ListItem>
    );
  }

  if (!routes.length) {
    return null;
  }

  return (
    <>
      <ListItem
        button={true}
        onClick={toggleCollapse.bind(null, !collapsed)}
      >
        <ListItemText primary={name} />
        {expandNode}
      </ListItem>
      <Collapse
        in={collapsed}
        timeout="auto"
        unmountOnExit={true}
      >
        <List
          component="div"
          disablePadding={true}
        >
          {routes.map(route => {
            const { name, path } = route;
            return (
              <ListItem
                button={true}
                className={classNames.nestedLinkItem}
                key={path}
                onClick={onClick}
              >
                <LinkRouter
                  className={linkClassName}
                  color="inherit"
                  to={path}
                >
                  <ListItemText primary={name} />
                </LinkRouter>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default MobileHeader;

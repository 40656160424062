import {useCallback} from "react";

import {useRentalTripService} from "../../contexts/rental-trip-service-context";
import useDataAdapter from "../../hooks/use-data-adapter";

function useRentalTrips(phoneNo: string) {
  const {getTrips} = useRentalTripService(),
    onGetTrips = useCallback(
      async (
        phone: string,
      ) => {
        if (!phone) {
          return {
            response: [],
            paginate: undefined,
            error: null,
          };
        }

        return getTrips(
          phone || undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "1",
          "10",
        );
      },
      [getTrips],
    ),
    tripsDataAdapter = useDataAdapter(
      onGetTrips,
      [],
      phoneNo,
    );

  return tripsDataAdapter;
}

export default useRentalTrips;

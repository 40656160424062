import {UA, WebSocketInterface} from "jssip";

function createUa (args: UAConfig): UA {
  const {
    domain,
    username,
    password,
    ha1,
    h1,
    h1b,
    socket,
  } = args;

  const uaConfig = {
    sockets: [socket],
    realm: domain,
    domain: domain,
    ha1: ha1,
    h1: h1,
    h1b: h1b,
    uri: `sip:${username}@${domain}`,
    password: password,
  };

  return new UA(uaConfig);
}

export default createUa;

type UAConfig = {
  socket: WebSocketInterface;
  domain: string;
  ha1: string;
  h1: string;
  h1b: string;
  username: string;
  password: string;
};

import makeStyles from '@mui/styles/makeStyles';

const useModalStyles = makeStyles({
  modalCard: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
    maxHeight: "95vh",
    overflow: "auto",
    "@media (max-width: 768px)": {
      width: "90%",
    },
    "@media (min-width: 768px)": {
      minWidth: "450px",
    },
  },
});

export default useModalStyles;

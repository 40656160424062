import React, { createContext, ReactNode, useContext } from "react";

import { IDropdownOptionsService } from "../types/services/dropdown-options-service";

const DropdownOptionsServiceContext = createContext<IDropdownOptionsService>(
  undefined as any,
);

interface DropdownOptionsServiceProviderProps {
  service: IDropdownOptionsService;
  children: ReactNode;
}

function DropdownOptionsServiceProvider(
  props: DropdownOptionsServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <DropdownOptionsServiceContext.Provider value={service}>
      {children}
    </DropdownOptionsServiceContext.Provider>
  );
}

function useDropdownOptionsService() {
  return useContext(DropdownOptionsServiceContext);
}

export { DropdownOptionsServiceProvider, useDropdownOptionsService };

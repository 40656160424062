import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { AccessControl } from "../types/acl";
import { Staff } from "../types/staff";

export type AccessControlQueryArg = {
  [k: string]: AccessControl | "";
};

export type MeState = {
  me: Staff | null;
  updateMe: (me: Staff | null) => any;
  hasAccessTo: (ac: AccessControl | string) => boolean;
  hasAccessToQuery: (
    ac: AccessControlQueryArg,
  ) => { [K in keyof AccessControlQueryArg]: boolean };
};

export const DEFAULT_ME_STATE: MeState = {
  me: null,
  updateMe: () => null,
  hasAccessTo: () => true,
  hasAccessToQuery: () => ({}),
};

const MeContext = createContext<MeState>(DEFAULT_ME_STATE);

interface MeProviderProps {
  children: ReactNode;
}

function MeProvider(props: MeProviderProps) {
  const { children } = props,
    [me, updateMe] = useState<Staff | null>(null),
    hasAccessTo = useCallback(
      (ac: AccessControl | string) => {
        if (!me) {
          return true;
        }

        if (!ac) {
          return false;
        }

        const { acl } = me,
          isRegex = ac.includes("*");

        if (!isRegex) {
          return acl.has(ac as AccessControl);
        }

        const regExp = new RegExp(ac),
          aclArray = Array.from(acl);
        for (let i = 0, iL = aclArray.length; i < iL; i++) {
          const ac = aclArray[i];
          if (ac.match(regExp)) {
            return true;
          }
        }

        return false;
      },
      [me],
    ),
    hasAccessToQuery = useCallback(
      (acq: AccessControlQueryArg) => {
        if (!me) {
          return {};
        }

        const { acl } = me,
          queryResult = Object.entries(acq).reduce((compiled, [key, ac]) => {
            return {
              ...compiled,
              [key]: ac ? acl.has(ac as AccessControl) : false,
            };
          }, {} as { [K: string]: boolean });

        return queryResult;
      },
      [me],
    ),
    value = useMemo(
      () => ({
        me,
        updateMe,
        hasAccessTo,
        hasAccessToQuery,
      }),
      [me, hasAccessTo],
    );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
}

function useMe() {
  return useContext(MeContext);
}

export { MeProvider, useMe };

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalCalendarService } from "../types/services/rental-calendar-service";

const RentalCalendarServiceContext = createContext<IRentalCalendarService>(
  undefined as any,
);

interface RentalCalendarServiceProviderProps {
  service: IRentalCalendarService;
  children: ReactNode;
}

function RentalCalendarServiceProvider(
  props: RentalCalendarServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalCalendarServiceContext.Provider value={service}>
      {children}
    </RentalCalendarServiceContext.Provider>
  );
}

function useRentalCalendarService() {
  return useContext(RentalCalendarServiceContext);
}

export { RentalCalendarServiceProvider, useRentalCalendarService };

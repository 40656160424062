import fetchBase from "./fetch-base";

async function PATCH<T>(url: string, data: any, options: RequestInit = {}) {
  return fetchBase<T>(url, data, {
    ...options,
    method: "PATCH",
  });
}

export default PATCH;

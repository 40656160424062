import { IndexedValueUpdate } from "../types/indexed-value";

function parseIndexedEmails(values: any[]) {
  return (values || []).map((each: any) => {
    const { id, email: value } = each,
      formatted: IndexedValueUpdate = { value: value };
    if (id !== undefined) {
      formatted.id = id;
    }
    return formatted;
  });
}

export default parseIndexedEmails;

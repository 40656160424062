import React, { createContext, ReactNode, useContext } from "react";

import { IRentalTripApprovalService } from "../types/services/rental-trip-approval-service";

const RentalTripApprovalServiceContext = createContext<
  IRentalTripApprovalService
>(undefined as any);

interface TripApprovalServiceProviderProps {
  service: IRentalTripApprovalService;
  children: ReactNode;
}

function RentalTripApprovalServiceProvider(
  props: TripApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalTripApprovalServiceContext.Provider value={service}>
      {children}
    </RentalTripApprovalServiceContext.Provider>
  );
}

function useRentalTripApprovalService() {
  return useContext(RentalTripApprovalServiceContext);
}

export { RentalTripApprovalServiceProvider, useRentalTripApprovalService };

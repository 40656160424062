import makeStyles from '@mui/styles/makeStyles';

const useStartActionStyles = makeStyles({
  "@keyframes fade-in": {
    from: {
      opacity: 0,
      transfrom: "translateX(-40px)",
    },
    to: {
      opacity: 1,
      transfrom: "translateX(0)",
    },
  },
  brandLogo: {
    height: "40px",
    width: "auto",
    animation: "$fade-in 0.3s ease-in-out",
  },
  navigationIcon: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    animation: "$fade-in 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: "32px",
  },
});

export default useStartActionStyles;

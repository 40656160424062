import { MasterOption } from "../types/masters";
import { Pagination } from "../types/pagination";
import {
  NearbyPlace,
  NEW_SAMPLE_PROPERTY,
  RentalProperty,
  RentalPropertyConfig,
  RentalPropertyItem,
  Sight,
} from "../types/rental-property";
import {
  IRentalPropertyService,
  NEW_PROPERTY_ADD_KEYS,
  NewRentalProperty,
} from "../types/services/rental-property-service";
import { FetchResult, GET, PATCH, POST } from "../utils/fetch";

type RentalPropertyListPaginated = {
  properties: RentalPropertyItem[];
  paginate: Pagination;
};

const newPropertyAddKeysSet = new Set(NEW_PROPERTY_ADD_KEYS);

type RentalPropertyResponse = {
  property: any;
};

type RentalPropertyConfigsResponse = {
  property_configs: any[];
};

class RentalPropertyService implements IRentalPropertyService {
  async listProperties(
    location: string,
    rooms: string,
    guest_capacity: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalPropertyItem[]>> {
    const base = "/api/v2/rental/properties",
      qp = new URLSearchParams(),
      qpOptions = [
        {
          key: "location_id",
          value: location,
        },
        {
          key: "rooms",
          value: rooms,
        },
        {
          key: "max_guests",
          value: guest_capacity,
        },
        {
          key: "name",
          value: search,
        },
        {
          key: "per_page",
          value: page_size,
        },
        {
          key: "page",
          value: page,
        },
      ];

    qpOptions.forEach(({ key, value }) => {
      if (!value) {
        return;
      }

      qp.append(key, value);
    });

    const qpM = qp.toString();
    let url = base;
    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<RentalPropertyListPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: response.properties,
      paginate: response.paginate,
      error,
    };
  }

  async addNewProperty(
    property: NewRentalProperty,
  ): Promise<FetchResult<string>> {
    const keyMap: any = {
        location_category: "location_id",
        adults_count: "adults",
        children_count: "children",
        extra_adults_count: "extra_adults",
        extra_adults_fare: "adults_rate",
        extra_adults_fare_currency: "extra_person_currency",
        extra_child_fare: "children_rate",
        extra_children_count: "extra_children",
        pet_friendly: "pets_allowed",
        rentable: "rentable",
        trending_listed: "trending",
        channel_manager_id: "sf_hotel_id",
        security_deposit: "deposit",
        maintenance_days_before_checkin: "maintenance_days_before_check_in",
        maintenance_days_after_checkout: "maintenance_days_after_check_out",
      },
      formData = new FormData();

    Object.entries(property).forEach(([key, value]) => {
      if (
        value === undefined ||
        value === null ||
        !newPropertyAddKeysSet.has(key)
      ) {
        return;
      }
      formData.append(keyMap[key] || key, value.toString());
    });

    formData.set("brand_id", property.brand_id.toString());

    formData.set("villa_alias", property.alias.toString());

    if (property.publication_features) {
      property.publication_features.forEach(feature =>
        formData.append("featured_in[]", feature),
      );
    }

    const {
      seo_title,
      seo_description,
      seo_keywords,
      seo_canonical,
    } = property;

    if (seo_title !== undefined) {
      formData.set("seo_attributes[title]", seo_title);
    }

    if (seo_description !== undefined) {
      formData.set("seo_attributes[description]", seo_description);
    }

    if (seo_keywords !== undefined) {
      formData.set("seo_attributes[keywords]", seo_keywords);
    }

    if (seo_canonical !== undefined) {
      formData.set("seo_attributes[canonical]", seo_canonical);
    }

    formData.append("currency_id", "1");

    if (
      property.homeowner_email &&
      property.homeowner_name &&
      property.homeowner_email &&
      property.homeowner_phone_no
    ) {
      formData.append("home_owners_attrs[][primary]", "true");
      formData.append("home_owners_attrs[][active]", "true");
      formData.append("home_owners_attrs[][name]", property.homeowner_name);
      formData.append("home_owners_attrs[][email]", property.homeowner_email);

      const [countryCode, phoneNo] = property.homeowner_phone_no.split(" ");

      formData.append("home_owners_attrs[][country_code]", countryCode);
      formData.append("home_owners_attrs[][mobile]", phoneNo);
    }

    if (
      property.contract_type &&
      property.contract_rev_share_percentage &&
      property.contract_free_nights &&
      property.contract_effective_date &&
      property.contract_expiry_date
    ) {
      formData.set("contract_attributes[active]", "true");

      formData.append(
        "contract_attributes[license_type]",
        property.contract_type,
      );
      formData.append(
        "contract_attributes[rev_share_percentage]",
        property.contract_rev_share_percentage.toString(),
      );
      formData.append(
        "contract_attributes[permitted_nights]",
        property.contract_free_nights.toString(),
      );
      formData.append(
        "contract_attributes[effective_date]",
        property.contract_effective_date,
      );
      formData.append(
        "contract_attributes[expiry_date]",
        property.contract_expiry_date,
      );
    }

    if (property.youtube_video.id) {
      formData.set("videos_attrs[][id]", property.youtube_video.id.toString());
    }

    if (property.youtube_video.value) {
      formData.set("videos_attrs[][video_id]", property.youtube_video.value);
    }

    formData.set("rental_staff_id", property.rental_staff_id.toString());

    formData.set(
      "maintenance_staff_id",
      property.maintenance_staff_id.toString(),
    );

    formData.set(
      "guest_relation_staff_id",
      property.guest_relation_staff_id.toString(),
    );

    const url = "/api/v2/rental/properties",
      { response, error } = await POST<RentalPropertyResponse>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const { slug } = response.property;

    return {
      response: slug,
      error,
    };
  }

  async getProperty(
    property_slug: string,
  ): Promise<FetchResult<RentalProperty>> {
    const url = `/api/v2/rental/properties/${property_slug}`,
      { response, error } = await GET<{ property: any }>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("unknown error"),
      };
    }

    const { property } = response;

    const rentalProperty: RentalProperty = JSON.parse(
        JSON.stringify(NEW_SAMPLE_PROPERTY),
      ),
      keyMap: Record<string, string> = {
        location_id: "location_category",
        adults: "adults_count",
        children: "children_count",
        extra_adults: "extra_adults_count",
        adults_rate: "extra_adults_fare",
        extra_person_currency: "extra_adults_fare_currency",
        children_rate: "extra_child_fare",
        extra_children: "extra_children_count",
        pets_allowed: "pet_friendly",
        rentable: "rentable",
        trending: "trending_listed",
        sf_hotel_id: "channel_manager_id",
        featured_in: "publication_features",
        rental_staff_id: "rental_staff_id",
        maintenance_staff_id: "maintenance_staff_id",
        guest_relation_staff_id: "guest_relation_staff_id",
      },
      locationMap: Record<string, string> = {
        address_line: "address_line",
        city: "city",
        state: "state",
        google_place_id: "google_location_url",
        lat: "latitude",
        long: "longitude",
      };

    for (const key in property) {
      const nativeValue = property[key],
        value = nativeValue === null ? "" : nativeValue;
      if (key in rentalProperty) {
        (rentalProperty as any)[key] = value;
      }
      if (key in keyMap) {
        (rentalProperty as any)[keyMap[key]] = value;
      }
    }

    for (const key in locationMap) {
      const nativeValue = property[key],
        value = nativeValue === null ? "" : nativeValue;
      (rentalProperty.location as any)[locationMap[key]] = value;
    }

    rentalProperty.property_images =
      property.images?.map((each: any) => {
        const { id, url, alt, caption, sequence, category, orientation } = each;
        return {
          id: id,
          url: url || "",
          alt: alt || "",
          caption: caption || "",
          sequence: sequence || -1,
          name: category,
          aspect_ratio: orientation || "",
        };
      }) || [];

    rentalProperty.cancellation_policies = property.cancellation_rules;

    rentalProperty.nearby_places =
      property.nearby_places?.map((each: any) => {
        const {
            id,
            name,
            city,
            estimated_time,
            lat,
            long,
            category,
            state,
          } = each,
          place: NearbyPlace = {
            id: id,
            name: name || "",
            category: category || "",
            city: city || "",
            distance_from_property: estimated_time || "",
            latitude: lat || "",
            longitude: long || "",
            state: state || "",
          };

        return place;
      }) || [];

    rentalProperty.things_to_do =
      property.things_to_do?.map((each: any) => {
        const { id, name, state, image } = each,
          sight: Sight = {
            id,
            name: name || "",
            state: state || "",
            image: image?.url || "",
          };

        return sight;
      }) || [];

    const { seo, property_configs = [] } = property;

    if (seo) {
      const { id, title, description, keywords, canonical } = seo;
      rentalProperty.seo = {
        ...rentalProperty.seo,
        id: id || undefined,
        title: title || "",
        description: description || "",
        keywords: keywords || "",
        canonical: canonical || "",
      };
    }

    rentalProperty.upcoming = property.upcoming || false;

    if (property.contract) {
      const {
        effective_date,
        expiry_date,
        id,
        license_type,
        permitted_nights,
        rev_share_percentage,
      } = property.contract;

      if (id !== undefined) {
        rentalProperty.contract_id = id;
      }

      rentalProperty.contract_type = license_type || "";
      rentalProperty.contract_rev_share_percentage = rev_share_percentage || 0;
      rentalProperty.contract_free_nights = permitted_nights || 0;
      rentalProperty.contract_effective_date = effective_date || "";
      rentalProperty.contract_expiry_date = expiry_date || "";
    }

    if (property.home_owners?.length) {
      const [first] = property.home_owners,
        { id, name, email, country_code, mobile } = first,
        phoneNo = [country_code || "", mobile || ""].join(" ").trim();

      rentalProperty.homeowner_id = id;
      rentalProperty.homeowner_name = name;
      rentalProperty.homeowner_email = email;
      rentalProperty.homeowner_phone_no = phoneNo;
    }

    rentalProperty.security_deposit = property.deposit || 0;

    rentalProperty.maintenance_days_before_checkin =
      property.maintenance_days_before_check_in || 0;

    rentalProperty.maintenance_days_after_checkout =
      property.maintenance_days_after_check_out || 0;

    rentalProperty.rental_staff_id = property.rental_staff_id;

    rentalProperty.maintenance_staff_id = property.maintenance_staff_id;

    rentalProperty.guest_relation_staff_id = property.guest_relation_staff_id;

    rentalProperty.configOptions = property_configs.map((each: any) => {
      const option: MasterOption = {
        value: each.id.toString(),
        name: `${each.bedrooms} Bedrooms`,
      };

      return option;
    });

    rentalProperty.brand_id = property.brand_id || 0;

    rentalProperty.alias = property.villa_alias || "";

    rentalProperty.youtube_video = property.videos?.length
      ? { id: property.videos[0].id, value: property.videos[0].video_id }
      : { value: "" };

    return {
      response: rentalProperty,
      error: null,
    };
  }

  async updateProperty(
    property_slug: string,
    property: Partial<RentalProperty>,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    if (property.amenities) {
      property.amenities.forEach(amenity => {
        const { id } = amenity;
        formData.append("rental_amenity_ids[]", id.toString());
      });
    }

    if (property.moods) {
      property.moods.forEach(mood => {
        const { id } = mood;
        formData.append("rental_mood_ids[]", id.toString());
      });
    }

    if (property.things_to_do) {
      property.things_to_do.forEach((thing_to_do, i) => {
        const { id, image, name, state } = thing_to_do;
        if (id !== undefined) {
          formData.append(`rental_things_todo_attrs[${i}][id]`, id.toString());
        } else {
          formData.append(
            `rental_things_todo_attrs[${i}][file]`,
            image as File,
          );
        }
        formData.append(`rental_things_todo_attrs[${i}][name]`, name);
        formData.append(`rental_things_todo_attrs[${i}][state]`, state);
      });
    }

    if (property.highlights) {
      property.highlights.forEach(highlight => {
        const { id } = highlight;
        formData.append("rental_highlight_ids[]", id.toString());
      });
    }

    if (property.house_rules) {
      property.house_rules.forEach(house_rule => {
        const { id, content } = house_rule;
        if (id !== undefined) {
          formData.append("house_rules_attrs[][id]", id.toString());
        }
        formData.append("house_rules_attrs[][content]", content);
      });
    }

    if (property.cancellation_policies) {
      property.cancellation_policies.forEach(cancellation_policy => {
        const { id, content } = cancellation_policy;
        if (id !== undefined) {
          formData.append("cancellation_rules_attrs[][id]", id.toString());
        }
        formData.append("cancellation_rules_attrs[][content]", content);
      });
    }

    if (property.bedrooms) {
      property.bedrooms.forEach(bedroom => {
        const { id, name, bed_type } = bedroom;
        if (id !== undefined) {
          formData.append("rental_bedrooms_attrs[][id]", id.toString());
        }
        formData.append("rental_bedrooms_attrs[][name]", name);
        formData.append("rental_bedrooms_attrs[][bed_type]", bed_type);
      });
    }

    if (property.property_images) {
      property.property_images.forEach((property_image, i) => {
        const {
          id,
          url,
          name,
          caption,
          alt,
          sequence,
          aspect_ratio,
        } = property_image;

        if (id === undefined) {
          formData.append(`images_attrs[${i}][file]`, url as File);
        } else {
          formData.append(`images_attrs[${i}][id]`, id.toString());
        }

        formData.append(`images_attrs[${i}][caption]`, caption);

        formData.append(`images_attrs[${i}][alt]`, alt);

        formData.append(`images_attrs[${i}][orientation]`, aspect_ratio);

        if (sequence !== undefined) {
          formData.append(`images_attrs[${i}][sequence]`, sequence.toString());
        }

        if (name !== undefined) {
          formData.append(`images_attrs[${i}][category]`, name);
        }
      });
    }

    if (property.nearby_places) {
      property.nearby_places.forEach(nearby_place => {
        const {
          id,
          name,
          city,
          state,
          category,
          distance_from_property,
          latitude,
          longitude,
        } = nearby_place;
        if (id !== undefined) {
          formData.append("rental_nearby_places_attrs[][id]", id.toString());
        }
        formData.append("rental_nearby_places_attrs[][name]", name);
        formData.append("rental_nearby_places_attrs[][city]", city);
        formData.append("rental_nearby_places_attrs[][state]", state);
        formData.append("rental_nearby_places_attrs[][category]", category);
        formData.append(
          "rental_nearby_places_attrs[][estimated_time]",
          distance_from_property,
        );
        formData.append("rental_nearby_places_attrs[][lat]", latitude);
        formData.append("rental_nearby_places_attrs[][long]", longitude);
      });
    }

    if (property.publication_features?.length) {
      property.publication_features.forEach(feature =>
        formData.append("featured_in[]", feature),
      );
    } else {
      formData.set("featured_in[]", "");
    }

    if (property.seo) {
      const {
        id: seo_id,
        title: seo_title,
        description: seo_description,
        keywords: seo_keywords,
        canonical: seo_canonical,
      } = property.seo;
      if (seo_id) {
        formData.set("seo_attributes[id]", seo_id.toString());
      }
      if (seo_title !== undefined) {
        formData.set("seo_attributes[title]", seo_title);
      }
      if (seo_description !== undefined) {
        formData.set("seo_attributes[description]", seo_description);
      }
      if (seo_keywords !== undefined) {
        formData.set("seo_attributes[keywords]", seo_keywords);
      }
      if (seo_canonical !== undefined) {
        formData.set("seo_attributes[canonical]", seo_canonical);
      }
    }

    if (
      property.homeowner_email &&
      property.homeowner_name &&
      property.homeowner_email &&
      property.homeowner_phone_no
    ) {
      if (property.homeowner_id) {
        formData.append(
          "home_owners_attrs[][id]",
          property.homeowner_id.toString(),
        );
      }

      formData.append("home_owners_attrs[][primary]", "true");
      formData.append("home_owners_attrs[][active]", "true");
      formData.append("home_owners_attrs[][name]", property.homeowner_name);
      formData.append("home_owners_attrs[][email]", property.homeowner_email);

      const [countryCode, phoneNo] = property.homeowner_phone_no.split(" ");

      formData.append("home_owners_attrs[][country_code]", countryCode);
      formData.append("home_owners_attrs[][mobile]", phoneNo);
    }

    if (
      property.contract_type !== undefined &&
      property.contract_rev_share_percentage !== undefined &&
      property.contract_free_nights !== undefined &&
      property.contract_effective_date !== undefined &&
      property.contract_expiry_date !== undefined
    ) {
      if (property.contract_id) {
        formData.append(
          "contract_attributes[id]",
          property.contract_id.toString(),
        );
      }
      formData.set("contract_attributes[active]", "true");
      formData.append(
        "contract_attributes[license_type]",
        property.contract_type,
      );
      formData.append(
        "contract_attributes[rev_share_percentage]",
        property.contract_rev_share_percentage.toString(),
      );
      formData.append(
        "contract_attributes[permitted_nights]",
        property.contract_free_nights.toString(),
      );
      formData.append(
        "contract_attributes[effective_date]",
        property.contract_effective_date,
      );
      formData.append(
        "contract_attributes[expiry_date]",
        property.contract_expiry_date,
      );
    }

    if (property.rental_staff_id !== undefined) {
      formData.set("rental_staff_id", property.rental_staff_id.toString());
    }

    if (property.maintenance_staff_id !== undefined) {
      formData.set(
        "maintenance_staff_id",
        property.maintenance_staff_id.toString(),
      );
    }

    if (property.guest_relation_staff_id !== undefined) {
      formData.set(
        "guest_relation_staff_id",
        property.guest_relation_staff_id.toString(),
      );
    }

    if (property.brand_id !== undefined) {
      formData.set("brand_id", property.brand_id.toString());
    }

    if (property.alias !== undefined) {
      formData.set("villa_alias", property.alias.toString());
    }

    if (property.youtube_video?.id) {
      formData.set("videos_attrs[][id]", property.youtube_video.id.toString());
    }

    if (property.youtube_video?.value) {
      formData.set("videos_attrs[][video_id]", property.youtube_video.value);
    }

    const keyMap: Record<string, string> = {
      location_category: "location_id",
      adults_count: "adults",
      children_count: "children",
      extra_adults_count: "extra_adults",
      extra_adults_fare: "adults_rate",
      extra_adults_fare_currency: "extra_person_currency",
      extra_child_fare: "children_rate",
      extra_children_count: "extra_children",
      pet_friendly: "pets_allowed",
      rentable: "rentable",
      trending_listed: "trending",
      google_location_url: "google_place_id",
      latitude: "lat",
      longitude: "long",
      channel_manager_id: "sf_hotel_id",
      upcoming: "upcoming",
      security_deposit: "deposit",
      maintenance_days_before_checkin: "maintenance_days_before_check_in",
      maintenance_days_after_checkout: "maintenance_days_after_check_out",
    };

    Object.entries(property).forEach(([key, value]) => {
      if (
        value === undefined ||
        value === null ||
        !newPropertyAddKeysSet.has(key)
      ) {
        return;
      }

      formData.append(keyMap[key] || key, value.toString());
    });

    const url = `/api/v2/rental/properties/${property_slug}`,
      { error } = await PATCH<RentalPropertyResponse>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error,
    };
  }

  async listConfigs(
    property_slug: string,
  ): Promise<FetchResult<RentalPropertyConfig[]>> {
    const qp = new URLSearchParams();

    qp.set("property_slug", property_slug);

    const qpM = qp.toString();

    let url = "/api/v2/rental/property_configs";

    if (qpM) {
      url = `${url}?${qpM}`;
    }

    const { response, error } = await GET<RentalPropertyConfigsResponse>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = response.property_configs.map(each => {
      const { id = 0, bedrooms = 0, active = false } = each,
        formatted: RentalPropertyConfig = {
          id: id || 0,
          bedroom_count: bedrooms || 0,
          active: active || false,
        };

      return formatted;
    });

    return {
      response: formatted,
      error,
    };
  }

  async updateConfig(
    property_slug: string,
    config_id: number,
    bedroom_count: number,
    active: boolean,
  ): Promise<FetchResult<void>> {
    const payload = {
      bedrooms: bedroom_count,
      active: active,
    };

    const qp = new URLSearchParams();

    qp.set("property_slug", property_slug);

    const qpM = qp.toString();

    let url = `/api/v2/rental/property_configs/${config_id}`;

    if (qpM) {
      url = `${url}?${qpM}`;
    }

    const { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error,
    };
  }

  async addConfig(
    property_slug: string,
    bedroom_count: number,
    active: boolean,
  ): Promise<FetchResult<void>> {
    const payload = {
      bedrooms: bedroom_count,
      active: active,
    };

    const qp = new URLSearchParams();

    qp.set("property_slug", property_slug);

    const qpM = qp.toString();

    let url = `/api/v2/rental/property_configs`;

    if (qpM) {
      url = `${url}?${qpM}`;
    }

    const { error } = await POST<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error,
    };
  }
}

export default RentalPropertyService;

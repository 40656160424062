import React, { createContext, ReactNode, useContext } from "react";

import { IAgentService } from "../types/services/agents-service";

const AgentServiceContext = createContext<IAgentService>(undefined as any);

interface AgentServiceProviderProps {
  service: IAgentService;
  children: ReactNode;
}

function AgentServiceProvider(props: AgentServiceProviderProps) {
  const { service, children } = props;

  return (
    <AgentServiceContext.Provider value={service}>
      {children}
    </AgentServiceContext.Provider>
  );
}

function useAgentService() {
  return useContext(AgentServiceContext);
}

export { AgentServiceProvider, useAgentService };

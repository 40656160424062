import { FetchResult } from "./fetch-result";

async function fetchBase<T = any>(
  url: string,
  data?: any,
  options?: RequestInit,
): Promise<FetchResult<T>> {
  const headers: any = options?.headers || {},
    method = options?.method || "GET";

  headers["Accept"] = "application/json";

  const _options: RequestInit = {
    ...options,
    method,
    headers,
  };

  if (data) {
    if (data instanceof FormData) {
      _options.body = data;
    } else {
      _options.body = JSON.stringify(data);
      headers["Content-Type"] = "application/json";
    }
  }

  try {
    const response = await fetch(url, _options),
      { status } = response,
      unmarshalled = (await response.json()) as any;

    if (status < 200 || status > 399) {
      let err = "";
      if (unmarshalled?.meta?.message) {
        err = unmarshalled?.meta?.message;
      } else if (unmarshalled?.error) {
        if (unmarshalled.error.message) {
          err = unmarshalled.error.message;
        } else if (typeof unmarshalled?.error === "string") {
          err = unmarshalled?.error;
        } else {
          err = JSON.stringify(err, null, 2);
        }
      } else if (unmarshalled) {
        err = JSON.stringify(unmarshalled, null, 2);
      } else {
        err = "unknown error";
      }

      return {
        response: null,
        error: new Error(err),
      };
    }

    if (unmarshalled.error) {
      return {
        response: null,
        error: new Error(unmarshalled.message || "Unknown error"),
      };
    }

    if (unmarshalled.meta?.error) {
      return {
        response: null,
        error: new Error(unmarshalled.meta.message || "Unknown error"),
      };
    }

    return {
      response: unmarshalled as T,
      error: null,
    };
  } catch (e: any) {
    return {
      response: null,
      error: e,
    };
  }
}

export default fetchBase;

import React, { createContext, ReactNode, useContext } from "react";

import { IConciergeService } from "../types/services/concierge-service";

const ConciergeServiceContext = createContext<IConciergeService>(
  undefined as any,
);

interface ConciergeServiceProviderProps {
  service: IConciergeService;
  children: ReactNode;
}

function ConciergeServiceProvider(props: ConciergeServiceProviderProps) {
  const { service, children } = props;

  return (
    <ConciergeServiceContext.Provider value={service}>
      {children}
    </ConciergeServiceContext.Provider>
  );
}

function useConciergeService() {
  return useContext(ConciergeServiceContext);
}

export { ConciergeServiceProvider, useConciergeService };

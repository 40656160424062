import React, { createContext, ReactNode, useContext } from "react";

import { IRentalTripGuestService } from "../types/services/rental-trip-guest-service";

const RentalTripGuestServiceContext = createContext<IRentalTripGuestService>(
  undefined as any,
);

interface RentalTripGuestServiceProviderProps {
  service: IRentalTripGuestService;
  children: ReactNode;
}

function RentalTripGuestServiceProvider(
  props: RentalTripGuestServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalTripGuestServiceContext.Provider value={service}>
      {children}
    </RentalTripGuestServiceContext.Provider>
  );
}

function useRentalTripGuestService() {
  return useContext(RentalTripGuestServiceContext);
}

export { RentalTripGuestServiceProvider, useRentalTripGuestService };

import { IndexedValueUpdate } from "../types/indexed-value";

function parseIndexedPhoneNos(values: any[]) {
  return (values || []).map((each: any) => {
    const { id, country_code = "", mobile = "" } = each,
      formatted: IndexedValueUpdate = { value: `${country_code} ${mobile}`.trim() };
    if (id !== undefined) {
      formatted.id = id;
    }
    return formatted;
  });
}

export default parseIndexedPhoneNos;

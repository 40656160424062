import { Pagination } from "../types/pagination";
import { RentalDepositApprovalItem } from "../types/rental-deposit-approval";
import { IRentalDepositApprovalService } from "../types/services/rental-deposit-approval-service";
import { FetchResult, GET, POST } from "../utils/fetch";

class RentalDepositApprovalService implements IRentalDepositApprovalService {
  async getDepositRequests (
    status?: string,
    from_date?: string,
    until_date?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalDepositApprovalItem[]>> {
    const qp = new URLSearchParams();

    if (search) {
      qp.set("query", search);
    }

    if (from_date) {
      qp.set("start_date", from_date);
    }

    if (until_date) {
      qp.set("end_date", until_date);
    }

    if (status) {
      qp.set("status", status);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/rental/security_deposits";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const {error, response} = await GET<ApprovalsListResponse>(url);

    if (error) {
      return {
        error: null,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown Error"),
        response: null,
      };
    }

    const properties = response.reservations.map(each => {
      const {
          billing_name,
          check_out,
          damage_amount,
          // days_past,
          deposit,
          guest_name,
          id,
          poc_exec,
          property_name,
          refund_amount,
          opportunity_slug,
          reservation_slug,
          status,
          updated_at,
          pending_reason,
          refund_text,
        } = each,
        nameSplits = (guest_name || "").split(" "),
        billingNameSplits = (billing_name || "").split(" "),
        property: RentalDepositApprovalItem = {
          id: id,
          opportunity_slug: opportunity_slug || "",
          reservation_slug: reservation_slug || "",
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          billing_first_name: billingNameSplits[0] || "",
          billing_last_name: billingNameSplits.length > 1 ? billingNameSplits.slice(1).join(" ") : "",
          property_name: property_name || "",
          poc_name: poc_exec || "",
          deposit_amount: deposit ?? 0,
          damage_amount: damage_amount ?? 0,
          refund_amount: refund_amount ?? 0,
          requested_date: updated_at || "",
          checkout_date: check_out || "",
          status: status || "",
          pending_reason: pending_reason || "",
          refund_text: refund_text || "",
        };

      return property;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: properties,
    };
  }

  async refundRequest (
    reservationSlug: string,
    id: number,
    refund_amount: number,
  ): Promise<FetchResult<void>> {
    const url = "/api/v2/rental/security_deposits/refund_deposit",
      {error} = await POST<ApprovalResponse>(
        url,
        {
          amount: refund_amount,
          reservation_slug: reservationSlug,
        },
      );

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async pendingRequest (
    reservationSlug: string,
    id: number,
    reason: string,
  ): Promise<FetchResult<void>> {
    const url = "/api/v2/rental/security_deposits/pending_issue",
      {error} = await POST<ApprovalResponse>(
        url,
        {
          reservation_slug: reservationSlug,
          reason: reason,
        },
      );

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default RentalDepositApprovalService;

type ApprovalsListResponse = {
  reservations: any[];
  paginate: Pagination;
};

type ApprovalResponse = {
  property: any;
};

import React, { createContext, ReactNode, useContext } from "react";

import { IDashboardService } from "../types/services/dashboard-service";

const DashboardServiceContext = createContext<IDashboardService>(
  undefined as any,
);

interface DashboardServiceProviderProps {
  service: IDashboardService;
  children: ReactNode;
}

function DashboardServiceProvider(props: DashboardServiceProviderProps) {
  const { service, children } = props;

  return (
    <DashboardServiceContext.Provider value={service}>
      {children}
    </DashboardServiceContext.Provider>
  );
}

function useDashboardService() {
  return useContext(DashboardServiceContext);
}

export { DashboardServiceProvider, useDashboardService };

import * as Yup from "yup";

const NAME_VALIDATION = Yup.string()
  .trim()
  .matches(/^[a-zA-Z\s]+$/gi, "only alphabets allowed");

const NAME_VALIDATION_ALPHANUMERIC = Yup.string()
  .trim()
  .matches(/^[a-zA-Z0-9\s]+$/gi, "no special characters allowed");
  
export default NAME_VALIDATION ;

export {NAME_VALIDATION_ALPHANUMERIC} ;
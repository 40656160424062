import "./App.css";

import React from "react";

import { PageTemplate } from "../../types/page-template";
import CallManager from "../CallManager";
import MastersManager from "../MastersManager";
import Navigation from "../Navigation";
import {RentalTripAddModal} from "../RentalTripAdd";
import Routes from "../Routes";
import ServiceProvider, { ServiceProviderProps } from "../ServiceProvider";
import StateProvider from "../StateProvider";

interface AppProps extends Omit<ServiceProviderProps, "children"> {
  pageTemplates: PageTemplate[];
  intent?: string;
}

function App(props: AppProps) {
  const { pageTemplates, intent, ...rest } = props;

  return (
    <ServiceProvider {...rest}>
      <StateProvider>
        <>
          <Navigation pages={pageTemplates} />
          <Routes
            intent={intent}
            pages={pageTemplates}
          />
          <MastersManager />
          <RentalTripAddModal />
          <CallManager />
        </>
      </StateProvider>
    </ServiceProvider>
  );
}

export default App;

import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clx from "classnames";
import React, { MouseEvent, useMemo } from "react";

import { useMe } from "../../../contexts/me-context";
import { Page } from "../../../types/page-template";
import LinkRouter from "../LinkRouter";
import useDesktopHeaderStyles from "./DesktopHeaderStyles";

interface DesktopHeaderProps {
  name: string;
  routes: Page[];
  className?: string;
  isDropdown?: boolean;
  path?: string;
}

function DesktopHeader(props: DesktopHeaderProps) {
  const { hasAccessTo } = useMe(),
    classNames = useDesktopHeaderStyles(),
    [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null),
    {
      name,
      routes: _routes,
      className,
      isDropdown = false,
      path = "#",
    } = props,
    routes: Page[] = useMemo(() => {
      if (!isDropdown) {
        return [];
      }

      return _routes.filter(route => {
        const { name, acl } = route,
          haveAccess = acl ? hasAccessTo(acl) : true;

        return name && haveAccess;
      });
    }, [isDropdown, _routes]),
    linkClassName = clx(classNames.link, className),
    iconClassName = clx("material-icons", classNames.expandIcon),
    onOpenMenu = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    onCloseMenu = () => {
      setAnchorEl(null);
    };

  if (!isDropdown) {
    return (
      <LinkRouter
        className={linkClassName}
        color="inherit"
        to={path}
      >
        {name}
      </LinkRouter>
    );
  }

  if (!routes.length) {
    return null;
  }

  return (
    <>
      <Link
        aria-haspopup="true"
        className={linkClassName}
        color="inherit"
        onClick={onOpenMenu}
      >
        {name}
        <span className={iconClassName}>
          {anchorEl ? "expand_less" : "expand_more"}
        </span>
      </Link>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom" as any,
          horizontal: "left" as any,
        }}
        keepMounted={true}
        onClose={onCloseMenu}
        open={Boolean(anchorEl).valueOf()}
      >
        {routes.map(route => {
          const { name, path } = route;

          return (
            <MenuItem
              color="inherit"
              component={LinkRouter as any}
              key={path}
              onClick={onCloseMenu}
              to={path}
            >
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default DesktopHeader;

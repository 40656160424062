import Modal, {
  ModalProps as _ModalProps,
  ModalProvider,
  useModal,
} from "./Modal";

export default Modal;

export type ModalProps = _ModalProps;

export { ModalProvider, useModal };

import {Button} from "@mui/material";
import clx from "classnames";
import {formatDuration, intervalToDuration} from "date-fns";
import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";

import { useCustomerService } from "../../contexts/customer-service-context";
import {useMe} from "../../contexts/me-context";
import {useDisconnectOnUnload} from "../../contexts/sip-context";
import useDataAdapter from "../../hooks/use-data-adapter";
import {SipCall} from "../../services/sip";
import parsePhoneNo from "../../utils/parse-phone-no";
import {useModal} from "../Modal";
import {RENTAL_TRIP_ADD_MODAL_ID} from "../RentalTripAdd";
import StaffSelect from "../StaffSelect";
import useCallReceiverStyles from "./CallReceiverStyles";
import EnquiryTripItem from "./EnquiryTripItem";
import useCallTimer from "./use-call-timer";
import useRentalTrips from "./use-rental-trips";
import useSipStaff from "./use-sip-staff";

interface CallReceiverProps {
  call: SipCall;
}

function CallReceiver(props: CallReceiverProps) {
  const { showModal } = useModal(),
    {me} = useMe(),
    {call} = props,
    audioRef = useRef<HTMLAudioElement | null>(null),
    {range, onStartTimer} = useCallTimer(),
    [transferStaff, setTransferStaff] = useState<string>(""),
    [expanded, setExpanded] = useState<boolean>(true),
    elapsedFriendly = useMemo(
      () => {
        const [start, end] = range;

        if (start === end) {
          return "---";
        }

        const duration = intervalToDuration({start: start, end: end});

        const formatted = formatDuration(duration, {format: ["minutes", "seconds"]});

        return formatted;
      },
      [range],
    ),
    clxs = useCallReceiverStyles(),
    ccx = clx("material-icons", clxs.fab),
    handleExpandedToggle = useCallback(
      () => setExpanded(expanded => !expanded),
      [setExpanded],
    ),
    acx = clx("material-icons", clxs.cta),
    {getCustomers} = useCustomerService(),
    showAddTrip = Boolean(call.concluded || call.isEstablished).valueOf(),
    outGoingCustomerNumber = useMemo(() => {
      const number = call.to.uri.getHeader("Customernum");

      return number[0];
    }, [call]),
    {data: trips} = useRentalTrips(call.from.display_name || outGoingCustomerNumber),
    handleRing = async () => {
      const audio = audioRef.current;

      if (!audio) {
        return;
      }

      setExpanded(true);

      audio.srcObject = null;

      audio.src = RING_AUDIO_SRC;

      await audio.play();
    },
    handleHangup = async () => {
      const audio = audioRef.current;

      if (!audio) {
        return;
      }

      if (call.concluded) {
        return;
      }

      await call.hangup();

      audio.pause();

      audio.srcObject = null;

      audio.src = "";

    },
    handleAnswer = async () => {
      const audio = audioRef.current;

      if (!audio) {
        return;
      }

      audio.pause();

      audio.src = "";

      audio.srcObject = await call.answer();

      await onStartTimer(call);

      audio.srcObject = null;
    },
    handleTransferStaffChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      setTransferStaff(value);
    },
    handleTransferCall = () => {
      if (!transferStaff) {
        return;
      }

      const [user, host] = transferStaff.split("@");

      call.transfer({ user: user, host: host });
    },
    handleAddNewTrip = async () => {
      if (!me) {
        return;
      }

      const pn = call.from.display_name || outGoingCustomerNumber;

      if (!pn) {
        return;
      }

      const { fullPhoneNo: phoneNo } = parsePhoneNo(pn);

      const defaultValue = {
        phone_no: phoneNo,
        poc_exec: me.id,
      };

      const slug = await showModal(
        RENTAL_TRIP_ADD_MODAL_ID,
        { 
          defaultValue: defaultValue ,         
          callDialer:true,
        },
      );

      if (!slug) {
        return;
      }

      const url = new URL(window.location.href);

      url.pathname = `/rentals/trips/${slug}`;

      return window.open(url, "_blank");
    },
    extractPhoneNo = (phoneNumber: string) => {
      // Get the last 10 digits of the phone number and assuming only domestic calls and numbers from India.
      const updatedPhoneNumber = phoneNumber.slice(-10);

      return updatedPhoneNumber;
    },
    onGetCustomers = useCallback(
      async (verified: string, search: string, page: string) => {
        return getCustomers(
          undefined,
          undefined,
          undefined,
          undefined,
          extractPhoneNo(search),
          "+91", //Setting default country code for India.
          page,
          '2',
          true,
        );
      },
      [getCustomers],
    ),
    customersDataAdapter = useDataAdapter(
      onGetCustomers,
      [],
      'false',
      call.from?.display_name || outGoingCustomerNumber,
      '1',
    ),
    { data: customers } = customersDataAdapter,
    oCustomer:any = customers?.length ? customers[0] : null;

  useEffect(
    () => {
      handleRing()
    },
    [call],
  );

  useDisconnectOnUnload(call);

  return (
    <>
      <div
        className={ccx}
        onClick={handleExpandedToggle}
      >
        {expanded ? "remove" : "phone_in_talk"}
        <audio
          autoPlay={true}
          ref={audioRef}
          src="https://mobcup.net/va/33kMg3JGS6R"
        />
      </div>
      <div
        className={clxs.floatingPanel}
        data-open={expanded}
      >
        {!call.concluded && !call.isEstablished ? (
          <div className={clxs.incomingCall}>
            {call.mode === "incoming" ? (
              <>
                {"Incoming call from\n"}
                <span className={clxs.phoneNo}>
                  {call.from.display_name}
                </span>
              </>
            ) : (
              <>
                  {"Outgoing call\n"}
                  <span className={clxs.phoneNo}>
                    {outGoingCustomerNumber || "Outgoing"}
                  </span>
                </>
            )}
          </div>
        ) : (
          <div className={clxs.ongoingCall}>
            <div>
              {call.from.display_name ? (
                <>
                  {"Ongoing call\n"}
                  <span className={clxs.phoneNo}>
                    {call.from.display_name || "Outgoing"}
                  </span>
                </>
              ) : (
                <>
                  {"Ongoing call\n"}
                  <span className={clxs.phoneNo}>
                    {outGoingCustomerNumber || "Outgoing"}
                  </span>
                </>
              )}
            </div>
            <div className={clxs.callDuration}>
              {elapsedFriendly}
            </div>
          </div>
        )}
        {oCustomer ? (
          <div className={clxs.customerTagsContainer}>
            {oCustomer.first_name ? (<span className={clxs.customerTag}>{(oCustomer.first_name + " " +oCustomer.last_name)}</span>) : ""}
            {<span className={clxs.customerTag}>{oCustomer.points ? (oCustomer.points + " pts") : "0 pts"}</span>}
            {<span className={clxs.customerTag}>{oCustomer.tier ? oCustomer.tier : "No Tier"}</span>}
            {oCustomer.repeat_user && <span className={clx(clxs.customerTag,clxs.repeatTag)}>{'Repeat'}</span>}
            {oCustomer.referred_by_tier && <span className={clx(clxs.customerTag,clxs.referTag)}>{'Referred by - ' + oCustomer.referred_by_tier}</span>}
          </div>
        ): (
          <div className={clxs.customerTagsContainer}>
            <span className={clx(clxs.customerTag,clxs.newTag)}>{'New'}</span>
          </div>
        )}
        {showAddTrip && (
          <div className={clxs.newTrip}>
            Add Trip
            <Button
              className={clxs.newTripAction}
              color="secondary"
              onClick={handleAddNewTrip}
              variant="contained"
            >
              <span className="material-icons">
                add
              </span>
            </Button>
          </div>
        )}
        {(trips ?? []).map((trip, key) => {
          return (
            <EnquiryTripItem
              checkinDate={trip.checkin_date}
              checkoutDate={trip.checkout_date}
              className={clxs.enquiryTrip}
              createdAt={trip.last_modified}
              customerName={trip.name}
              key={key}
              opportunitySlug={trip.slug}
              pocName={trip.poc_executive}
            />
          );
        })}
        {call.isEstablished && (
          <div className={clxs.transferContainer}>
            <StaffSelect
              className={clxs.transfer}
              label="Staff"
              onChange={handleTransferStaffChange}
              optionsHook={useSipStaff}
              size="small"
              value={transferStaff}
              variant="outlined"
            />
            <Button
              color="secondary"
              onClick={handleTransferCall}
              type="button"
              variant="contained"
            >
              Transfer
            </Button>
          </div>
        )}
        <div className={clxs.actions}>
          {call.isEstablished && !call.isMuted && (
            <div
              className={acx}
              data-action="neutral"
              onClick={call.mute}
              title="Mute"
            >
              mic
            </div>
          )}
          {call.isEstablished && call.isMuted && (
            <div
              className={acx}
              data-action="neutral"
              onClick={call.unmute}
              title="Unmute"
            >
              mic_off
            </div>
          )}
          {!call.isEstablished && !call.hasEnded && (
            <div
              className={acx}
              data-action="positive"
              onClick={handleAnswer}
              title="Answer"
            >
              call
            </div>
          )}
          {!call.concluded && (
            <div
              className={acx}
              data-action="negative"
              onClick={handleHangup}
              title="Hangup"
            >
              call_end
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CallReceiver;

// const fakeEnquiry = {
//   customerName: "John Doe",
//   checkinDate: new Date().toISOString(),
//   checkoutDate: new Date().toISOString(),
//   createdAt: new Date().toISOString(),
//   pocName: "Jane Doe",
//   opportunitySlug: "xxx",
// };

const RING_AUDIO_SRC = "https://mobcup.net/va/33kMg3JGS6R";

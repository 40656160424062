import { TextFieldProps } from "@mui/material/TextField";
import React from "react";

import { useDropdownOptionsService } from "../../contexts/dropdown-options-service-context";
import ignoreProps from "../../utils/ignore-props";
import Autocomplete, { Option } from "../Autocomplete";

const IGNORE_PROPS = new Set(["getStaffOptions"]);

type NativeTextFieldProps = Omit<TextFieldProps, "value">;

interface AgentSelectProps {
  value?: string;
  verticals?: string[];
}

function AgentSelect(props: AgentSelectProps & NativeTextFieldProps) {
  const { getAgentOptions, getAgentOptionById } = useDropdownOptionsService(),
    { verticals, id, name, onChange, value, disabled, ...rest } = props,
    textFieldProps = ignoreProps(rest, IGNORE_PROPS),
    onGetAgentById = async (value: number) => {
      const { error, response: agentOption } = await getAgentOptionById(
        value.toString(),
      );
      if (error || !agentOption) {
        return null;
      }

      const { name, value: id } = agentOption,
        option: Option = {
          value: id.toString(),
          label: name,
        };

      return option;
    },
    onGetAgentOptions = async (value: string) => {
      const { error, response: agents } = await getAgentOptions(
        verticals,
        value,
      );
      if (error || !agents) {
        return [] as Option[];
      }
      const formatted: Option[] = agents.map(each => {
        const { name, value } = each;
        const formatted: Option = {
          value: value,
          label: name,
        };

        return formatted;
      });

      return formatted;
    };

  return (
    <Autocomplete
      disabled={disabled}
      getOptionForValue={onGetAgentById}
      getOptions={onGetAgentOptions}
      id={id}
      name={name}
      onChange={onChange}
      textFieldProps={textFieldProps}
      value={value}
    />
  );
}

export default AgentSelect;

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalTripService } from "../types/services/rental-trip-service";

const RentalTripServiceContext = createContext<IRentalTripService>(
  undefined as any,
);

interface RentalTripServiceProviderProps {
  service: IRentalTripService;
  children: ReactNode;
}

function RentalTripServiceProvider(props: RentalTripServiceProviderProps) {
  const { service, children } = props;

  return (
    <RentalTripServiceContext.Provider value={service}>
      {children}
    </RentalTripServiceContext.Provider>
  );
}

function useRentalTripService() {
  return useContext(RentalTripServiceContext);
}

export { RentalTripServiceProvider, useRentalTripService };

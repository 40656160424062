import fetchBase from "./fetch-base";

async function DELETE<T>(
  url: string,
  data: any = null,
  options: RequestInit = {},
) {
  return fetchBase<T>(url, data, {
    ...options,
    method: "DELETE",
  });
}

export default DELETE;

import React, { ReactElement, useMemo } from "react";
import {
  Navigate,
  RouteProps as NativeRouteProps, useLocation,
} from "react-router-dom";

import { useSecureState } from "../../../contexts/secure-state-context";
import NotFound from "../../NotFound";

interface SecureRouteProps extends NativeRouteProps {
  isPrivate?: boolean;
  children: ReactElement;
  intent?: string;
  redirect?: string;
}

function SecureRoute(props: SecureRouteProps) {
  const { isSecure } = useSecureState(),
    { pathname, search } = useLocation(),
    { isPrivate = false, redirect, intent, children } = props,
    redirectWithIntent = useMemo(
      () => {
        const safeIntent = (`${pathname}${search}`.trim() || intent) ?? "",
          qp = new URLSearchParams([["intent", safeIntent]]).toString(),
          redirectWithIntent = `${redirect}?${qp}`;

        return redirectWithIntent;
      },
      [pathname, search, redirect, intent],
    );

  if (isSecure || !isPrivate) {
    return children;
  }

  if (redirect) {
    return (
      <Navigate
        replace={true}
        to={redirectWithIntent}
      />
    );
  }

  return <NotFound/>;
}

export default SecureRoute;

import {
  OutboundOpportunity,
  OutboundOpportunityItem,
} from "../types/outbound-opportunity";
import { Pagination } from "../types/pagination";
import { IOutboundOpportunityService } from "../types/services/outbound-opportunity-service";
import { FetchResult, GET, PATCH, POST } from "../utils/fetch";

type OpportunityListResponse = {
  results: any[];
  paginate: Pagination;
};

type OpportunityResponse = {
  result: any;
};

type ConvertOpportunityResponse = {
  slug: string;
  success: boolean;
};

class OutboundOpportunityService implements IOutboundOpportunityService {
  async getOpportunities(
    brand?: string,
    location?: string,
    query_id?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<OutboundOpportunityItem[]>> {
    const qp = new URLSearchParams();

    if (brand !== undefined) {
      qp.append("brand_ids[]", brand);
    }

    if (location !== undefined) {
      qp.append("location_ids[]", location);
    }

    if (query_id !== undefined) {
      qp.set("query_id", query_id);
    }

    if (search !== undefined) {
      qp.set("query_string", search);
    }

    if (page !== undefined) {
      qp.set("page", page);
    }

    if (page_size !== undefined) {
      qp.set("page_size", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/query_results";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<OpportunityListResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.results.map(each => {
      const { id, name, mobile, email, attributes } = each,
        formatted: OutboundOpportunityItem = {
          id: id || -1,
          customer_name: name || "",
          customer_email: email || "",
          customer_phone_no: mobile || "",
          customer_features: attributes || [],
        };

      return formatted;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async getOpportunity(
    opportunity_id: string,
  ): Promise<FetchResult<OutboundOpportunity>> {
    const url = `/api/v2/query_results/${opportunity_id}`,
      { error, response } = await GET<OpportunityResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        name,
        mobile,
        email,
        poc_head_id,
        poc_exec_id,
        contact_id,
        attributes,
        status,
        query_description,
      } = response.result,
      formatted: OutboundOpportunity = {
        id: id || null,
        customer_name: name || "",
        customer_email: email || "",
        customer_phone_no: mobile || "",
        customer_id: contact_id || null,
        poc_executive: poc_exec_id || null,
        poc_head: poc_head_id || null,
        customer_features: attributes || [],
        status: status || "",
        description: query_description || "No description available",
      };

    return {
      error: null,
      response: formatted,
    };
  }

  async updatePOC(
    opportunity_id: string,
    poc_executive?: number,
    poc_head?: number,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "poc_exec_id",
          value: poc_executive,
        },
        {
          key: "poc_head_id",
          value: poc_head,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;

        if (value !== undefined) {
          compiled[key] = value;
        }

        return compiled;
      }, {} as { [k: string]: string | number | string[] }),
      url = `/api/v2/query_results/${opportunity_id}/update_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async convertLead(opportunity_id: string): Promise<FetchResult<string>> {
    const url = `/api/v2/query_results/${opportunity_id}/accept`,
      { response, error } = await POST<ConvertOpportunityResponse>(url, null);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response.slug,
    };
  }

  async closeLead(
    opportunity_id: string,
    reason: string,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/query_results/${opportunity_id}/reject`,
      { error } = await POST<void>(url, { rejection_reason: reason });

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default OutboundOpportunityService;

import {useCallback, useState} from "react";

import {SipCall} from "../../services/sip";

function useCallTimer() {
  const [range, setRange] = useState<[Date, Date]>(
    () => {
      const now = new Date();

      return [now, now];
    },
  );

  const onStartTimer = useCallback(
    async (call: SipCall) => {
      const now = new Date();

      setRange([now, now]);

      for await (const elapsed of callTimer(call)) {
        setRange(prev => [prev[0], elapsed]);
      }
    },
    [setRange],
  );

  return {
    range: range,
    onStartTimer: onStartTimer,
  };
}

export default useCallTimer;

async function* callTimer(call: SipCall) {
  while (true) {
    const shouldContinue = await Promise.race(
      [
        call.done(),
        new Promise(
          resolve => setTimeout(resolve, 1000, true),
        ),
      ],
    );

    if (!shouldContinue) {
      return;
    }

    yield new Date();
  }
}

import _CustomerService from "../services/customer-service";
import { ICustomerRelationService } from "../types/services/customer-relation-service";
import { ICustomerService } from "../types/services/customer-service";

const instance = new _CustomerService();

const CustomerService: ICustomerService = instance;

const CustomerRelationService: ICustomerRelationService = instance;

export { CustomerService, CustomerRelationService };

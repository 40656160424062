import {
  Agent,
  AgentAddress,
  AgentDocument,
  AgentItem,
  AgentSiteVisit,
  EnquiryAgent,
} from "../types/agent";
import { IndexedValue, IndexedValueUpdate } from "../types/indexed-value";
import { Pagination } from "../types/pagination";
import { IAgentService } from "../types/services/agents-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";
import parseIndexedEmails from "../utils/parse-indexed-emails";
import parseIndexedPhoneNos from "../utils/parse-indexed-phone-nos";

type AgentsListResponse = {
  agents: any[];
  paginate: Pagination;
};

type AgentResponse = {
  agent: any;
};

type AgentDocumentsResponse = {
  documents: any[];
};

type AgentSiteVisitResponse = {
  site_visit: any[];
};

class AgentService implements IAgentService {
  async getAgents(
    phone_no?: string,
    enquired_from?: string,
    enquired_until?: string,
    staff?: string,
    search?: string,
    verticals?: string[],
    agentType?: string[],
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<AgentItem[]>> {
    const qp = new URLSearchParams();
    if (phone_no) {
      qp.set("mobile", phone_no);
    }
    if (enquired_from) {
      qp.set("start_date", enquired_from);
    }
    if (enquired_until) {
      qp.set("end_date", enquired_until);
    }
    if (staff) {
      qp.set("poc_exec_id", staff);
    }
    if (search) {
      qp.set("query", search);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    if (verticals && verticals.length) {
      verticals.forEach((each) => {
        qp.append("verticals[]", each);
      });
    }

    if (agentType && agentType.length) {
      agentType.forEach((each) => {
        qp.append("agent_types[]", each);
      });
    }

    let url = "/api/v2/agents";

    const marshaled = qp.toString();

    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<AgentsListResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const agents = response.agents.map((each) => {
      const {
          id,
          emails,
          location,
          mobiles,
          name,
          poc_exec,
          slug,
          vertical,
          status,
          last_modified_at,
        } = each,
        nameSplits = (name || "").split(" "),
        agent: AgentItem = {
          id,
          slug,
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          emails: parseIndexedEmails(emails),
          phone_nos: parseIndexedPhoneNos(mobiles),
          location,
          poc_executive: poc_exec,
          last_modified: last_modified_at,
          vertical: vertical || "",
          status: status || "no_rating",
        };

      return agent;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: agents,
    };
  }

  async addAgent(
    name: string,
    emails: IndexedValue[],
    phone_nos: IndexedValue[],
    vertical: string,
    location: string,
    company_name: string,
    type: string,
    type_other?: string,
    poc_exec_id?: string,
    poc_head_id?: string,
    agentSource?: string,
  ): Promise<FetchResult<EnquiryAgent>> {
    const _type = type === "others" ? type_other : type,
      phoneNos = phone_nos.map((each) => {
        const { id, value } = each,
          [countryCode, phoneNo] = value.split(" "),
          formatted: any = { mobile: phoneNo, country_code: countryCode };

        if (id !== undefined) {
          formatted.id = id;
        }

        return formatted;
      }),
      emailId = emails.map((each) => ({ email: each.value })),
      payload: any = {
        name: name,
        primary_email_attributes: emailId[0],
        primary_mobile_attributes: phoneNos[0],
        vertical: vertical,
        source: agentSource,
        location: location,
        company_name: company_name,
        agent_type: _type,
      };

    if (poc_exec_id !== undefined) {
      payload.poc_exec_id = poc_exec_id;
    }

    if (poc_head_id !== undefined) {
      payload.poc_head_id = poc_head_id;
    }

    const url = "/api/v2/agents",
      { error, response } = await POST<AgentResponse>(url, payload);
    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { id, slug } = response.agent,
      agent: EnquiryAgent = {
        id,
        slug,
      };

    return {
      error: null,
      response: agent,
    };
  }

  async getAgent(agentSlug: string): Promise<FetchResult<Agent>> {
    const url = `/api/v2/agents/${agentSlug}`,
      { error, response } = await GET<AgentResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        company_name,
        emails,
        location,
        source,
        mobiles,
        name,
        poc_exec,
        poc_exec_id,
        poc_head_id,
        slug,
        vertical,
        status,
        updated_at,
        agent_type,
        commission,
        discount,
        pan,
        gst,
        primary_email,
        primary_mobile,
        address_details: { address, city, state, country },
      } = response.agent,
      nameSplits = (name || "").split(" "),
      primaryEmail: IndexedValueUpdate = {
        id: primary_email?.id ?? -1,
        value: primary_email?.email,
      },
      primaryPhoneNo: IndexedValueUpdate = {
        id: primary_mobile?.id ?? -1,
        value: `${primary_mobile?.country_code ?? ""} ${
          primary_mobile?.mobile ?? ""
        }`,
      },
      sanitizedEmails = parseIndexedEmails(emails),
      sanitizedPhoneNos = parseIndexedPhoneNos(mobiles),
      trackedAgentTypes = new Set([
        "influencer_broker",
        "city_broker",
        "local_broker",
        "wealth_manager",
        "ipc",
      ]),
      type =
        (trackedAgentTypes.has(agent_type)
          ? agent_type
          : !agent_type
          ? ""
          : "others") || "",
      agent: Agent = {
        id,
        slug,
        first_name: nameSplits[0],
        last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
        primary_email: primaryEmail,
        primary_phone_no: primaryPhoneNo,
        emails: sanitizedEmails,
        phone_nos: sanitizedPhoneNos,
        location: location || "",
        source: source || "",
        last_modified: updated_at || "",
        poc_executive_name: poc_exec || "",
        poc_executive: poc_exec_id,
        poc_head: poc_head_id,
        vertical: vertical || "",
        company_name: company_name || "",
        status: status || "",
        type: type,
        type_other: type === "others" ? agent_type : "",
        commission: commission || 0,
        discount: discount || 0,
        pan: pan || "",
        gst: gst || "",
        address: address || "",
        city: city || "",
        state: state || "",
        country: country || "",
      };

    return {
      error: null,
      response: agent,
    };
  }

  async updateAgent(
    agentSlug: string,
    first_name?: string,
    last_name?: string,
    primary_email?: IndexedValue,
    primary_phone_no?: IndexedValue,
    emails?: IndexedValueUpdate[],
    phone_nos?: IndexedValueUpdate[],
    location?: string,
    source?:string,
    type?: string,
    type_other?: string,
    vertical?: string,
    company_name?: string,
    commission?: number,
    discount?: number,
    pan?: string,
    gst?: string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    let name = first_name || "";

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    if (name) {
      payload.set("name", name);
    }

    [
      {
        key: "location",
        value: location,
      },
      {
        key: "source",
        value: source,
      },
      {
        key: "vertical",
        value: vertical,
      },
      {
        key: "company_name",
        value: company_name,
      },
      {
        key: "commission",
        value: commission,
      },
      {
        key: "discount",
        value: discount,
      },
      {
        key: "pan",
        value: pan,
      },
      {
        key: "gst",
        value: gst,
      },
    ].forEach((each: any) => {
      const { key, value } = each;
      if (value !== undefined) {
        payload.set(key, value);
      }
    });

    if (primary_email?.value) {
      const id = primary_email?.id;

      payload.set("primary_email_attributes[email]", primary_email.value);

      if (id && id > 0) {
        payload.set("primary_email_attributes[id]", id.toString());
      }
    }

    if (primary_phone_no?.value) {
      const [countryCode, phoneNo] = primary_phone_no.value.split(" ");

      const id = primary_phone_no?.id;

      payload.set("primary_mobile_attributes[country_code]", countryCode);

      payload.set("primary_mobile_attributes[mobile]", phoneNo);

      if (id && id > 0) {
        payload.set("primary_mobile_attributes[id]", id.toString());
      }
    }

    if (emails) {
      emails.forEach((each, i) => {
        const { id, value , destroy} = each;

        payload.set(`email_attributes[${i}][email]`, value);

        if (id !== undefined) {
          payload.set(`email_attributes[${i}][id]`, id.toString());
        }

        if (destroy){
          payload.set(`email_attributes[${i}][_destroy]`, "1");
        }
      });
    }

    if (phone_nos) {
      phone_nos.forEach((each, i) => {
        const { id, value, destroy } = each,
          [countryCode, phoneNo] = value.split(" ");

        payload.set(`mobile_attributes[${i}][mobile]`, phoneNo);

        payload.set(`mobile_attributes[${i}][country_code]`, countryCode);

        if (id !== undefined) {
          payload.set(`mobile_attributes[${i}][id]`, id.toString());
        }

        if (destroy){
          payload.set(`mobile_attributes[${i}][_destroy]`, "1");
        }
      });
    }

    if (type) {
      const typeData: any = type === "others" ? type_other : type;
      payload.set("agent_type", typeData);
    }

    const url = `/api/v2/agents/${agentSlug}`,
      { error } = await PATCH<void>(url, payload);
    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePOC(
    agentSlug: string,
    poc_executive?: number,
    poc_head?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {};

    if (poc_executive !== undefined) {
      payload.poc_exec_id = poc_executive;
    }
    if (poc_head !== undefined) {
      payload.poc_head_id = poc_head;
    }

    const url = `/api/v2/agents/${agentSlug}/update_poc`,
      { error } = await PATCH<void>(url, payload);
    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getAgentDocuments(
    opportunity_slug: string,
    document_type?: string,
  ): Promise<FetchResult<AgentDocument[]>> {
    let url = `/api/v2/agents/${opportunity_slug}/documents`;

    const qp = new URLSearchParams();

    if (document_type) {
      qp.set("document_type", document_type);
    }

    const marshaled = qp.toString();

    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<AgentDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: AgentDocument[] = response.documents.map((each) => {
      const { id, link, document_name, document_identifier, document_type } =
          each,
        formatted: AgentDocument = {
          id: id,
          type: document_type || "",
          name: document_name || document_identifier || "",
          file: link || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addAgentDocument(
    opportunity_slug: string,
    document_type: string,
    document_name: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    formData.set("document_type", document_type);
    formData.set("document_name", document_name);
    formData.set("file", file);

    const url = `/api/v2/agents/${opportunity_slug}/documents`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteAgentDocument(
    opportunity_slug: string,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/agents/${opportunity_slug}/documents/${document_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  /**
   * Used to fetch Site Visit Details
   */
  async getAgentSiteVisit(
    opportunity_slug: string,
    vertical: string,
  ): Promise<FetchResult<AgentSiteVisit[]>> {
    const url = `/api/v2/site_visits/get_visits?vertical=${vertical}&opportunity_slug=${opportunity_slug}`,
      { error, response } = await GET<AgentSiteVisitResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.site_visit.map((each: any) => {
      const {
          assignee_id,
          assignee_name,
          assigned_by_id,
          assigned_by_name,
          cancellation_reason,
          comments,
          guest_name,
          id,
          opportunity_slug,
          opportunity_status,
          description,
          plots,
          rental_properties,
          site_visit_histories,
          status,
          visit_date_time,
          follow_up_time,
          viewing_type,
          vertical,
          opportunity_mobile,
        } = each,
        selection: AgentSiteVisit = {
          assignedToId: assignee_id,
          assignedToName: assignee_name,
          assignedById: assigned_by_id,
          assignedByName: assigned_by_name,
          cancellation_reason,
          comments,
          guest_name,
          id,
          opportunity_slug,
          opportunity_status,
          description,
          plots,
          site_visit_histories,
          status,
          visit_date_time,
          follow_up_time,
          viewing_type,
          vertical,
          opportunity_mobile,
          rental_properties,
        };

      return selection;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  /**
   * Used to update Agent Address
   */
  async updateAgentAddress(
    agent_address_data: Partial<AgentAddress>,
    agent_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
      address_details: agent_address_data,
    };
    const url = new URL(
      `/api/v2/agents/${agent_slug}/update_address`,
      window.location.origin,
    );

    const { error } = await PATCH<void>(url.toString(), payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default AgentService;

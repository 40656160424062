import {useDropdownOptionsService} from "../../contexts/dropdown-options-service-context";
import {Option} from "../Autocomplete";

function useStaff(props: any) {
	const { getStaffOptionById, getStaffOptions } = useDropdownOptionsService(),
		{ verticals } = props,
		onGetStaffOptionById = async (value: string) => {
			const { error, response: staffOption } = await getStaffOptionById(value);

			if (error || !staffOption) {
				return null;
			}

			const { name, value: id } = staffOption,
				option: Option = {
					value: id.toString(),
					label: name,
				};

			return option;
		},
		onGetStaffOptions = async (value: string) => {
			const { error, response: staff } = await getStaffOptions(
				verticals,
				value,
			);

			if (error || !staff) {
				return [] as Option[];
			}

			const formatted: Option[] = staff.map(each => {
				const { value, name } = each,
					formatted: Option = {
						value: value,
						label: name,
					};
				return formatted;
			});

			return formatted;
		};

	return {
		getOptionById: onGetStaffOptionById,
		getOptions: onGetStaffOptions,
	};
}

export default useStaff;

import {useDropdownOptionsService} from "../../contexts/dropdown-options-service-context";
import {Option} from "../Autocomplete";


function useSipStaff() {
	const {getSipStaffOptionById, getSipStaffOptions} = useDropdownOptionsService(),
		onGetStaffOptionById = async (value: string) => {
			const {error, response: staffOption} = await getSipStaffOptionById(value);

			if (error || !staffOption) {
				return { value: value, label: value };
			}

			const {name, value: id} = staffOption,
				option: Option = {
					value: id.toString(),
					label: name,
				};

			return option;
		},
		onGetStaffOptions = async (value: string) => {
			const {error, response: staff} = await getSipStaffOptions(value);

			if (error || !staff) {
				return [] as Option[];
			}

			const formatted: Option[] = staff.map(each => {
				const {value, name} = each,
					formatted: Option = {
						value: value,
						label: name,
					};
				return formatted;
			});

			return formatted;
		};

	return {
		getOptionById: onGetStaffOptionById,
		getOptions: onGetStaffOptions,
	};
}

export default useSipStaff;

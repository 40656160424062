import React, { createContext, ReactNode, useContext } from "react";

import { IRentalDepositApprovalService } from "../types/services/rental-deposit-approval-service";

const RentalDepositApprovalServiceContext = createContext<IRentalDepositApprovalService>(
  undefined as any,
);

interface RentalDepositApprovalServiceProviderProps {
  service: IRentalDepositApprovalService;
  children: ReactNode;
}

function RentalDepositApprovalServiceProvider (
  props: RentalDepositApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalDepositApprovalServiceContext.Provider value={service}>
      {children}
    </RentalDepositApprovalServiceContext.Provider>
  );
}

function useRentalDepositApprovalService () {
  return useContext(RentalDepositApprovalServiceContext);
}

export { RentalDepositApprovalServiceProvider, useRentalDepositApprovalService };

import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';

const useNotFoundStyles = makeStyles((theme: Theme) => {
  const {
    palette: { secondary },
  } = theme;

  return {
    notFoundContainer: {
      display: "grid",
      gridTemplate: "auto/1fr",
      gridGap: "16px",
      paddingTop: "32px",
    },
    notFoundImg: {
      maxWidth: "800px",
      width: "100%",
      margin: "0 16px",
      justifySelf: "center",
    },
    title: {
      color: secondary.main,
      fontSize: "28px",
      textAlign: "center",
    },
    subtitle: {
      color: "#b0bec5",
      fontSize: "18px",
      textAlign: "center",
    },
    back: {
      justifySelf: "center",
    },
  };
});

export default useNotFoundStyles;

import React, { createContext, ReactNode, useContext } from "react";

import { IChapterService } from "../types/services/chapter-service";

const ChapterServiceContext = createContext<
	IChapterService
>(undefined as any);

interface ChapterServiceProviderProps {
	service: IChapterService;
	children: ReactNode;
}

function ChapterServiceProvider(
	props: ChapterServiceProviderProps,
) {
	const { service, children } = props;

	return (
		<ChapterServiceContext.Provider value={service}>
			{children}
		</ChapterServiceContext.Provider>
	);
}

function useChapterService() {
	return useContext(ChapterServiceContext);
}

export {
	ChapterServiceProvider,
	useChapterService,
};

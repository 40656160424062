import NativeAutocomplete, {
  AutocompleteProps as NativeAutocompleteProps,
} from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import clx from "classnames";
import debounce from "lodash.debounce";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";

import useLoading from "../../hooks/use-loading";
import useAutocompleteStyles from "./AutoCompleteStyles";

interface AutocompleteProps extends _NativeAutocompleteProps {
  name?: string;
  value?: string;
  getOptions: (query: string) => Promise<Option[]>;
  getOptionForValue?: (value: any) => Promise<Option | null>;
  textFieldProps?: TextFieldProps;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  prefetch?: boolean;
}

function Autocomplete(props: AutocompleteProps) {
  const classNames = useAutocompleteStyles(),
    [options, updateOptions] = useState<Option[]>([]),
    { loading, showLoading, hideLoading } = useLoading(false),
    [selection, updateSelection] = useState<Option | null>(null),
    {
      prefetch = false,
      getOptions,
      onChange,
      onBlur,
      name,
      textFieldProps = {},
      value,
      getOptionForValue,
      className,
      filterOptions = FILTER_OPTIONS,
      ...autocompleteProps
    } = props,
    { id } = autocompleteProps,
    containerClassName = clx(classNames.container, className),
    onGetOptions = async (value: string) => {
      showLoading();

      const options = await getOptions(value);

      updateOptions(options);

      hideLoading();
    },
    onValueChange = async (value: string) => {
      if (!getOptionForValue) {
        return;
      }

      showLoading();

      if (!value) {
        updateSelection(null);
      } else {
        const option = await getOptionForValue(value);

        updateSelection(option);
      }

      hideLoading();
    },
    onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget as HTMLInputElement,
        { value } = target;

      _dOnSearchChange(onGetOptions, value);
    },
    _onChange = async (e: ChangeEvent<any>, selection: Option | null) => {
      updateSelection(selection);
      if (!onChange) {
        return;
      }

      const payload: any = {
        id,
        name,
        value: selection?.value || "",
      };

      await onChange({
        target: payload,
        currentTarget: payload,
      } as any);
    };

  useEffect(() => {
    if ((value || null) === (selection?.value || null)) {
      return;
    }
    onValueChange(value || "");
  }, [value]);

  useEffect(
    () => {
      if (!prefetch) {
        return;
      }

      _dOnSearchChange(onGetOptions, "");
    },
    [],
  );

  return (
    <NativeAutocomplete
      {...autocompleteProps}
      blurOnSelect={true}
      className={containerClassName}
      filterOptions={filterOptions}
      getOptionLabel={GET_OPTION_LABEL}
      loading={loading}
      noOptionsText="Start typing"
      onBlur={onBlur}
      onChange={_onChange}
      options={options}
      renderInput={params => (
        <TextField
          {...textFieldProps}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading &&
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                }
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onBlur={noop}
          onChange={onSearchChange}
        />
      )}
      selectOnFocus={true}
      size={textFieldProps.size}
      value={selection}
    />
  );
}

export default Autocomplete;

const _dOnSearchChange = debounce(
  (update: (value: string) => any, value: string) => {
    update(value);
  },
  750,
);
const GET_OPTION_LABEL = (option: Option) => option.label;

const FILTER_OPTIONS = (options: Option[]) => options;

const noop = () => undefined;

type _NativeAutocompleteProps = Omit<
  NativeAutocompleteProps<Option, undefined, undefined, undefined>,
  "onChange" | "options" | "renderInput" | "value" | "defaultValue"
>;

export type Option = {
  label: string;
  value: string;
};

import { AccessControl } from "../types/acl";
import { Pagination } from "../types/pagination";
import { IStaffService } from "../types/services/staff-service";
import { SipCredentials, Staff } from "../types/staff";
import { FetchResult, GET, PATCH, POST } from "../utils/fetch";

export function staffFromAny(payload: any) {
  const {
      id,
      agent_id,
      name,
      email,
      handle,
      role_id,
      role,
      role_name,
      country_code = "",
      mobile = "",
      poc_head,
      verticals,
      calling_number,
      active,
      acl = [],
      sip_details = {} as any,
    } = payload,
    nameSplits = (name || "").split(" "),
    phoneNo = `${country_code} ${mobile}`.trim(),
    sipCredentials: SipCredentials = {
      domain: sip_details?.domain ?? "",
      ha1: sip_details?.ha1 ?? "",
      h1: sip_details?.h1 ?? "",
      h1b: sip_details?.h1b ?? "",
      password: sip_details?.password ?? "",
      username: sip_details?.username ?? "",
    },
    staff: Staff = {
      id: id,
      agent_id: agent_id,
      first_name: nameSplits[0] || "",
      last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
      email: email || "",
      handle: handle || "",
      role_id: role_id || "",
      role: role || "",
      role_name: role_name || "",
      phone_no: phoneNo,
      poc_head: poc_head || "",
      verticals: verticals || [],
      calling_phone_no: calling_number || "",
      active: active || false,
      acl: new Set<AccessControl>(acl),
      sipCredentials: sipCredentials,
    };

  return staff;
}

class StaffService implements IStaffService {
  async getStaff(
    role?: string,
    verticals?: string[],
    query?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<Staff[]>> {
    const base = "/api/v2/staffs",
      qp = new URLSearchParams(),
      qpOptions = [
        {
          key: "role",
          value: role,
        },
        {
          key: "query",
          value: query,
        },
        {
          key: "per_page",
          value: page_size,
        },
        {
          key: "page",
          value: page,
        },
      ];

    qpOptions.forEach(({ key, value }) => {
      if (!value) {
        return;
      }

      qp.append(key, value);
    });

    if (verticals && verticals.length) {
      verticals.forEach((vertical) => qp.append("verticals[]", vertical));
    }

    const qpM = qp.toString();

    let url = base;

    if (qpM) {
      url = `${base}?${qpM}`;
    }

    const { response, error } = await GET<StaffListPaginated>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const staffs: Staff[] = response.staffs.map(staffFromAny);

    return {
      response: staffs,
      paginate: response.paginate,
      error,
    };
  }

  async getStaffById(staff_id: string): Promise<FetchResult<Staff>> {
    const url = `/api/v2/staffs/${staff_id}/details`,
      { response, error } = await GET<StaffResponse>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const staff = staffFromAny(response.staff);

    return {
      response: staff,
      error,
    };
  }

  async updateStaff(
    staffId: number,
    active: boolean,
    first_name?: string,
    last_name?: string,
    email?: string,
    handle?: string,
    role?: string,
    phone_no?: string,
    poc_head?: string,
    verticals?: string[],
    calling_phone_no?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {},
      url = `/api/v2/staffs/${staffId}`;

    let name = first_name || "";
    payload.active = active;
    if (last_name) {
      name = `${name} ${last_name}`;
    }

    if (name !== undefined) {
      payload.name = name;
    }

    if (email !== undefined) {
      payload.email = email;
    }

    if (handle !== undefined) {
      payload.handle = handle;
    }

    if (role !== undefined) {
      payload.role_id = role;
    }

    if (phone_no !== undefined) {
      const [code, phoneNo] = phone_no.split(" ");

      payload.country_code = code;

      payload.mobile = phoneNo;
    }

    if (poc_head !== undefined) {
      payload.poc_head = poc_head;
    }

    if (verticals !== undefined) {
      payload.verticals = verticals;
    }

    if (calling_phone_no !== undefined) {
      payload.calling_number = calling_phone_no;
    }

    const { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getMe(): Promise<FetchResult<Staff>> {
    const url = "/api/v2/me",
      { response, error } = await GET<StaffResponse>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const {
        id,
        agent_id,
        name,
        email,
        handle,
        role_id,
        role,
        role_name,
        country_code = "",
        mobile = "",
        poc_head,
        verticals,
        calling_number,
        active,
        acl = [],
        sip_details = {} as any,
      } = response.staff,
      nameSplits = (name || "").split(" "),
      phoneNo = `${country_code} ${mobile}`.trim(),
      sipCredentials: SipCredentials = {
        domain: sip_details?.domain ?? "",
        ha1: sip_details?.ha1 ?? "",
        h1: sip_details?.h1 ?? "",
        h1b: sip_details?.h1b ?? "",
        password: sip_details?.password ?? "",
        username: sip_details?.username ?? "",
      },
      staff: Staff = {
        id: id,
        agent_id: agent_id,
        first_name: nameSplits[0] || "",
        last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
        email: email || "",
        handle: handle || "",
        role_id: role_id || "",
        role: role || "",
        role_name: role_name || "",
        phone_no: phoneNo,
        poc_head: poc_head || "",
        verticals: verticals || [],
        calling_phone_no: calling_number || "",
        active: active,
        acl: new Set<AccessControl>(acl),
        sipCredentials: sipCredentials,
      };

    return {
      response: staff,
      error,
    };
  }

  async addStaff(
    active: boolean,
    first_name: string,
    last_name: string,
    email: string,
    role: string,
    verticals: string[],
    phone_no: string,
    calling_phone_no?: string,
  ): Promise<FetchResult<Staff>> {
    const payload: any = {},
      url = `/api/v2/staffs/`;

    let name = first_name || "";

    payload.active = active;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    if (name !== undefined) {
      payload.name = name;
    }

    if (email !== undefined) {
      payload.email = email;
    }

    if (role !== undefined) {
      payload.role_id = role;
    }

    if (phone_no !== undefined) {
      const [code, phoneNo] = phone_no.split(" ");

      payload.country_code = code;

      payload.mobile = phoneNo;
    }

    if (verticals !== undefined) {
      payload.verticals = verticals;
    }

    if (calling_phone_no !== undefined) {
      payload.calling_number = calling_phone_no;
    }

    const { error } = await POST<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updateSipStatus(status: string): Promise<FetchResult<void>> {
    const payload: any = { status: status },
      url = "/api/v2/staffs/update_agent";

    const { error } = await POST<void>(url, payload);

    if (error) {
      return {
        response: null,
        error: error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }
}

export default StaffService;

type StaffResponse = {
  staff: any;
};

type StaffListPaginated = {
  staffs: any[];
  paginate: Pagination;
};

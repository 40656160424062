import React, { createContext, ReactNode, useContext, useState } from "react";

export type SecureState = {
  isSecure: boolean;
  updateIsSecure: (isSecure: boolean) => any;
};
const DEFAULT_SECURE_STATE = { isSecure: false, updateIsSecure: () => null };

const SecureStateContext = createContext<SecureState>(DEFAULT_SECURE_STATE);

interface SecureStateProviderProps {
  children: ReactNode;
}

function SecureStateProvider(props: SecureStateProviderProps) {
  const { children } = props,
    [isSecure, updateIsSecure] = useState<boolean>(false),
    secureState: SecureState = {
      isSecure,
      updateIsSecure,
    };

  return (
    <SecureStateContext.Provider value={secureState}>
      {children}
    </SecureStateContext.Provider>
  );
}

function useSecureState() {
  return useContext(SecureStateContext);
}

export { SecureStateProvider, useSecureState };

import "date-fns";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./components/App";
import AgentsService from "./core/agent-service";
import ChapterService from "./core/chapter-service";
import ConciergeService from "./core/concierge-service";
import {
  CustomerRelationService,
  CustomerService,
} from "./core/customer-service";
import DashboardService from "./core/dashboard-service";
import { DevelopmentOpportunityGuestService, DevelopmentOpportunityService } from "./core/development-opportunity-service";
import DropdownOptionsService from "./core/dropdown-options-service";
import { CallService, EnquiryService } from "./core/enquiry-service";
import HomeAcquisitionService from "./core/home-acquisition-service";
import LoginService from "./core/login-service";
import MastersService from "./core/masters-service";
import OutboundOpportunityService from "./core/outbound-opportunity-service";
import PAGE_TEMPLATES from "./core/pages";
import RentalCalendarService from "./core/rental-calendar-service";
import RentalCommissionApprovalService from "./core/rental-commission-approval-service";
import RentalDepositApprovalService from "./core/rental-deposit-approval-service";
import RentalForceBookApprovalService from "./core/rental-force-book-approval-service";
import RentalInvoiceApprovalService from "./core/rental-invoice-approval-service";
import RentalMastersService from "./core/rental-masters-service";
import RentalPaymentApprovalService from "./core/rental-payment-approval-service";
import RentalPropertyService from "./core/rental-property-service";
import RentalTripApprovalService from "./core/rental-trip-approval-service";
import RentalTripPaymentService from "./core/rental-trip-payment-service";
import {
  RentalTripGuestService,
  RentalTripService,
} from "./core/rental-trip-service";
import StaffService from "./core/staff-service";
import TaskService from "./core/task-service";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const ROOT_ELEMENT = document.getElementById("root");

if (!ROOT_ELEMENT) {
  throw new Error("not a browser environment");
}

const { pathname, search } = window.location,
  intent = `${pathname}${search}`.trim(),
  reactRoot = createRoot(ROOT_ELEMENT);

reactRoot.render(
  <App
    agentService={AgentsService}
    callService={CallService}
    chapterService={ChapterService}
    conciergeService={ConciergeService}
    customerRelationService={CustomerRelationService}
    customerService={CustomerService}
    dashboardService={DashboardService}
    developmentOpportunityGuestService={DevelopmentOpportunityGuestService}
    developmentOpportunityService={DevelopmentOpportunityService}
    dropdownOptionsService={DropdownOptionsService}
    enquiryService={EnquiryService}
    homeAcquisitionService={HomeAcquisitionService}
    intent={intent}
    loginService={LoginService}
    masterService={MastersService}
    outboundOpportunityService={OutboundOpportunityService}
    pageTemplates={PAGE_TEMPLATES}
    rentalCalendarService={RentalCalendarService}
    rentalCommissionApprovalService={RentalCommissionApprovalService}
    rentalDepositApprovalService={RentalDepositApprovalService}
    rentalForceBookApprovalService={RentalForceBookApprovalService}
    rentalInvoiceService={RentalInvoiceApprovalService}
    rentalMastersService={RentalMastersService}
    rentalPaymentApprovalService={RentalPaymentApprovalService}
    rentalPropertyService={RentalPropertyService}
    rentalTripApprovalService={RentalTripApprovalService}
    rentalTripGuestService={RentalTripGuestService}
    rentalTripPaymentService={RentalTripPaymentService}
    rentalTripService={RentalTripService}
    staffService={StaffService}
    taskService={TaskService}
  />,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

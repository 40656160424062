import {
  CallLog,
  Enquiry,
  EnquiryItem,
  EnquiryMobileCheck,
  EnquiryStats,
} from "../types/enquiry";
import { Pagination } from "../types/pagination";
import { ICallService } from "../types/services/call-service";
import { IEnquiryService } from "../types/services/enquiry-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

type EnquiriesListResponse = {
  enquiries: any[];
  paginate: Pagination;
};

type EnquiryResponse = {
  enquiry: any;
};

type CallLogsResponse = {
  call_logs: any[];
};

type CheckPhoneResponse = {
  contacts: any[];
  paginate: Pagination;
};

function callLogFromAny(call_log: any) {
  const {
      id,
      call_direction,
      call_duration,
      call_time,
      call_transfer_status,
      called_country_code = "",
      called_number,
      customer_country_code = "",
      customer_number,
      recording_url,
      answered_staff,
      new_customer,
    } = call_log,
    calledNumber = `${called_country_code} ${called_number}`.trim(),
    customerNumber = `${customer_country_code} ${customer_number}`.trim(),
    transferStatus = (call_transfer_status || "")
      .toLowerCase()
      .replace(/\s/gi, "_")
      .trim(),
    type = (call_direction || "").toLowerCase().includes("incoming")
      ? "incoming"
      : "outgoing",
    dialer_phone_no = type === "outgoing" ? calledNumber : customerNumber,
    receiver_phone_no = type === "outgoing" ? customerNumber : calledNumber,
    cl: CallLog = {
      id: id,
      type: type,
      dialer_phone_no: dialer_phone_no,
      receiver_phone_no: receiver_phone_no,
      missed: transferStatus.includes("missed"),
      error:
        !transferStatus.includes("missed") && transferStatus !== "connected",
      duration: call_duration || 0,
      call_date: call_time,
      recording: recording_url || undefined,
      staff_name: answered_staff || "",
      duplicate: !(new_customer && true),
    };

  return cl;
}

class EnquiryService implements IEnquiryService, ICallService {
  async getEnquiries(
    type?: string,
    departments?: string[],
    before?: string,
    after?: string,
    sources?: string[],
    search?: string,
    trash?: boolean,
    ivr?: string[],
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<EnquiryItem[]>> {
    const qp = new URLSearchParams();

    if (type) {
      qp.set("type", type);
    }

    if (departments) {
      departments.forEach((department) => {
        qp.append("vertical[]", department);
      });
    }

    if (sources) {
      sources.forEach((source) => {
        qp.append("source[]", source);
      });
    }

    if (search) {
      qp.set("query", search);
    }

    if (before) {
      qp.set("before", before);
    }

    if (after) {
      qp.set("after", after);
    }

    if (trash) {
      qp.set("is_trash", trash.toString());
    }

    if (ivr?.length) {
      ivr.map((e) => qp.append("called_number[]", e));
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/enquiries";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<EnquiriesListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.enquiries.map((each) => {
      const {
          email,
          interested_in,
          //is_trash,
          location,
          message,
          mobile,
          name,
          slug,
          source,
          source_region,
          source_city,
          created_at,
          verified,
          lead_exists,
          repeat_user,
          vertical,
          is_trash,
          editable,
          call_log,
          brand_id,
          plain_country_code,
          plain_mobile,
        } = each,
        enquiry: EnquiryItem = {
          slug,
          email: email,
          name: name,
          phone_no: mobile,
          enquiry_date: created_at,
          location: location || "",
          message: message || "",
          reason: interested_in || "N.A",
          source: source || "",
          source_region: source_region || "",
          source_city: source_city || "",
          verified: verified || false,
          is_trash: is_trash || false,
          vertical: vertical,
          lead_exists: lead_exists || false,
          repeat_user: repeat_user || false,
          editable: editable || false,
          created_date: created_at || "",
          brand_id: brand_id || null,
          plain_mobile_number:
            `${plain_country_code} ${plain_mobile}`.trim() || "",
        };

      if (call_log) {
        const cl = callLogFromAny(call_log);

        enquiry.call_log = cl;
      }

      return enquiry;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async getEnquiry(slug: string): Promise<FetchResult<Enquiry>> {
    const url = `/api/v2/enquiries/${slug}`,
      { error, response } = await GET<EnquiryResponse>(url);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        created_at,
        email,
        interested_in,
        location,
        message,
        mobile,
        name,
        slug: _slug,
        source,
        source_region,
        source_city,
        vertical,
        verified,
        is_trash = false,
        editable,
        call_log,
        brand_id,
      } = response.enquiry,
      nameSplits = (name || "").split(" "),
      enquiry: Enquiry = {
        id,
        slug: _slug,
        first_name: nameSplits[0],
        last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
        email: email || "",
        mobile: mobile || "",
        interested_in: interested_in || "",
        source: source || "",
        source_city: source_city || "",
        source_region: source_region || "",
        location: location || "",
        message: message || "",
        vertical: vertical || "",
        enquiry_date: created_at || "",
        created_date: created_at || "",
        verified: verified || false,
        agent: "",
        is_trash: is_trash || false,
        editable: editable || false,
        brand_id: brand_id || null,
      };

    if (call_log) {
      const cl = callLogFromAny(call_log);
      enquiry.call_log = cl;
    }

    return {
      error: null,
      response: enquiry,
    };
  }

  async assignEnquiry(
    slug: string,
    staffId: string,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("staff_id", staffId);

    const url = `/api/v2/enquiries/${slug}/assign`,
      { error } = await POST<void>(url, formData);
    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async addEnquiry(
    first_name: string,
    last_name: string,
    email: string,
    country_code: string,
    phone_no: string,
    vertical: string,
    interested_in: string,
    interested_in_other: string,
    location: string,
    location_other: string,
    source_of_enquiry: string,
    source_of_enquiry_other: string,
    source_of_enquiry_region: string,
    source_of_enquiry_city: string,
    agent: string,
    message: string,
    brand_id: number,
    customer_id?: number,
  ): Promise<FetchResult<string>> {
    const formTemplate = [
        {
          key: "name",
          value: first_name + (last_name ? ` ${last_name}` : ""),
        },
        {
          key: "email",
          value: email,
        },
        {
          key: "interested_in",
          value:
            interested_in === "others" ? interested_in_other : interested_in,
        },
        {
          key: "location",
          value: location === "Others" ? location_other : location,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "source_region",
          value: source_of_enquiry_region,
        },
        {
          key: "source_city",
          value: source_of_enquiry_city,
        },
        {
          key: "vertical",
          value: vertical,
        },
        {
          key: "message",
          value: message,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "brand_id",
          value: brand_id,
        },
      ],
      url = "/api/v2/enquiries",
      formData: any = formTemplate.reduce((compiled, { key, value }) => {
        compiled[key] = value || "";

        return compiled;
      }, {} as any);

    if (customer_id !== undefined) {
      formData.contact_id = customer_id;
    }

    // const [code, phoneNo] = phone_no.split(" ");

    formData.country_code = country_code;

    formData.mobile = phone_no;

    const { response, error } = await POST<EnquiryResponse>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response.enquiry.slug,
    };
  }

  async updateEnquiry(
    enquiry_slug: string,
    first_name?: string,
    last_name?: string,
    email?: string,
    vertical?: string,
    interested_in?: string,
    interested_in_other?: string,
    location?: string,
    location_other?: string,
    source_of_enquiry?: string,
    source_of_enquiry_other?: string,
    source_of_enquiry_region?: string,
    source_of_enquiry_city?: string,
    agent?: string,
    message?: string,
    customer_id?: number,
    brand_id?: number,
  ): Promise<FetchResult<string>> {
    let name: string | undefined = undefined;
    if (first_name) {
      name = first_name;
    }
    if (last_name) {
      if (name) {
        name = `${name} ${last_name}`;
      } else {
        name = last_name;
      }
    }
    const formTemplate = [
        {
          key: "name",
          value: name,
        },
        {
          key: "email",
          value: email,
        },
        {
          key: "interested_in",
          value:
            interested_in === "others" ? interested_in_other : interested_in,
        },
        {
          key: "location",
          value: location === "Others" ? location_other : location,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "source_region",
          value: source_of_enquiry_region,
        },
        {
          key: "source_city",
          value: source_of_enquiry_city,
        },
        {
          key: "vertical",
          value: vertical,
        },
        {
          key: "message",
          value: message,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "type",
          value: "enquiry",
        },
        {
          key: "brand_id",
          value: brand_id,
        },
      ],
      formData = formTemplate.reduce((compiled, { key, value }) => {
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as any),
      url = `/api/v2/enquiries/${enquiry_slug}`;
    if (customer_id !== undefined) {
      formData.contact_id = customer_id;
    }

    const { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: enquiry_slug,
    };
  }

  async trashEnquiry(slug: string): Promise<FetchResult<void>> {
    const url = `/api/v2/enquiries/${slug}/trash`,
      { error } = await POST<void>(url, null);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async restoreEnquiry(slug: string): Promise<FetchResult<void>> {
    const url = `/api/v2/enquiries/${slug}/revive`,
      { error } = await POST<void>(url, null);
    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async getCallLogs(
    vertical: string,
    opportunity_slug: string,
  ): Promise<FetchResult<CallLog[]>> {
    const qp = new URLSearchParams();

    qp.set("vertical", vertical);

    qp.set("slug", opportunity_slug);

    const marshalled = qp.toString();

    let url = `/api/v2/call_logs`;

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<CallLogsResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }
    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const callLogs: CallLog[] = response.call_logs.map(callLogFromAny);

    return {
      error: null,
      response: callLogs,
    };
  }

  async updateCallLog(
    call_log_id: number,
    vertical: string,
    opportunity_slug: string,
    enquiry_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      vertical: vertical,
      slug: opportunity_slug,
    };
    if (enquiry_slug) {
      payload.enquiry_slug = enquiry_slug;
    }
    const url = `/api/v2/call_logs/${call_log_id}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async deleteCallLog(call_log_id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/call_logs/${call_log_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async callPhoneNo(
    sip_enabled: boolean,
    phone_no: string,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<void>> {
    const [code, phoneNo] = phone_no.split(" ");

    const payload: any = {
      sip_enabled: sip_enabled,
      code: code,
      mobile: phoneNo,
    };

    if (vertical && opportunity_slug) {
      payload.opportunity_type = vertical;

      payload.opportunity_slug = opportunity_slug;
    }

    const url = `/api/v2/contacts/make_call`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }

  async getEnquiryStats(): Promise<FetchResult<EnquiryStats>> {
    const url = "/api/v2/enquiries/stats",
      { error, response } = await GET<any>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("unknown error"),
        response: null,
      };
    }

    const {
        rental_enquiries,
        rental_calls,
        development_enquiries,
        development_calls,
        home_acqui_enquiries,
        home_acqui_calls,
        other_enquiries,
        concierge_calls,
        chapter_calls,
        chapter_enquiries,
      } = response,
      formatted: EnquiryStats = {
        rental_enquiries: rental_enquiries || 0,
        rental_call_enquiries: rental_calls || 0,
        development_enquiries: development_enquiries || 0,
        development_call_enquiries: development_calls || 0,
        home_acquisition_enquiries: home_acqui_enquiries || 0,
        home_acquisition_call_enquiries: home_acqui_calls || 0,
        other_enquiries: other_enquiries || 0,
        concierge_call_enquiries: concierge_calls || 0,
        chapter_enquiries: chapter_enquiries || 0,
        chapter_call_enquiries: chapter_calls || 0,
      };

    return {
      error: null,
      response: formatted,
    };
  }

  async checkEnquiryPhone(
    phone_no?: string,
    country_code?: string,
    page_size?: string,
  ): Promise<FetchResult<EnquiryMobileCheck[]>> {
    const qp = new URLSearchParams();

    if (country_code) {
      qp.set("country_code", country_code);
    }

    if (phone_no) {
      qp.set("mobile", phone_no);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    qp.set("show_contact", "true");

    const marshalled = qp.toString();

    let url = "/api/v2/contacts";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<CheckPhoneResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.contacts.map((each) => {
      const {
          id,
          name,
          primary_email_details,
          primary_mobile_details,
          customer_id,
        } = each,
        nameSplits = (name || "").split(" "),
        customer: EnquiryMobileCheck = {
          id,
          first_name: nameSplits[0],
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          email: primary_email_details,
          phone_no: primary_mobile_details,
          customer_id: customer_id,
        };
      return customer;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async updatePhoneDetails(
    customer_id: number,
    first_name: string,
    last_name: string,
    phone: string,
    phone_id?: number,
    country_code?: string,
    email?: string,
    email_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      name: `${first_name} ${last_name}`,
      primary_email_attributes: {
        id: email_id,
        email: email,
      },
      primary_mobile_attributes: {
        id: phone_id,
        country_code: country_code,
        mobile: phone,
      },
    };

    const url = `/api/v2/contacts/${customer_id}/update_contact`,
      { error, response } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async mergeLead(
    opportunity_slug: string,
    enquiry_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
        opportunity_slug: opportunity_slug,
      },
      url = `/api/v2/enquiries/${enquiry_slug}/merge`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: undefined,
    };
  }
}

export default EnquiryService;

import makeStyles from '@mui/styles/makeStyles';

const useDesktopHeaderStyles = makeStyles({
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  expandIcon: {
    fontSize: "18px",
    marginLeft: "8px",
  },
});

export default useDesktopHeaderStyles;

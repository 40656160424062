import {UA, WebSocketInterface} from "jssip";
import {
  ConnectedEvent,
  IncomingRTCSessionEvent,
  OutgoingRTCSessionEvent,
  RegisteredEvent,
  UAConnectingEvent,
  UnRegisteredEvent,
} from "jssip/lib/UA";
import {DisconnectEvent} from "jssip/lib/WebSocketInterface";

import {SipCredentials} from "../../types/staff";
import uuidv4 from "../../utils/uuidv4";
import wait from "../../utils/wait";
import createUa from "./create-ua";
import Queue from "./queue";
import SipCall from "./sip-call";

class Sip {
  private readonly socket: WebSocketInterface;
  private readonly ua: UA;
  readonly queue: Queue<SipCall>;
  private _sipStatus: SipStatus;

  private readonly _identity: string;

  private _call: SipCall | null = null;
  private eventHandler?: EventHandler;

  constructor(args: SipCredentials) {
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.handleCalls = this.handleCalls.bind(this);
    this.isRegistered = this.isRegistered.bind(this);
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);
    this.log = this.log.bind(this);
    this.setOnEventHandler = this.setOnEventHandler.bind(this);
    this.handleRTCSessions = this.handleRTCSessions.bind(this);
    this.handleConnecting = this.handleConnecting.bind(this);
    this.handleConnected = this.handleConnected.bind(this);
    this.handleDisconnected = this.handleDisconnected.bind(this);
    this.handleRegistered = this.handleRegistered.bind(this);
    this.handleUnRegistered = this.handleUnRegistered.bind(this);
    this.handleRegistrationFailed = this.handleRegistrationFailed.bind(this);

    this._identity = uuidv4();
    this._sipStatus = "disconnected";
    this.queue = new Queue<SipCall>();

    const wsUrl = new URL(`wss:///${args.domain}/ws`);
    this.socket = new WebSocketInterface(wsUrl.toString());
    this.ua = createUa({...args, socket: this.socket});

    this.ua.on("connecting", this.handleConnecting);
    this.ua.on("connected", this.handleConnected);
    this.ua.on("disconnected", this.handleDisconnected);
    this.ua.on("registered", this.handleRegistered);
    this.ua.on("unregistered", this.handleUnRegistered);
    this.ua.on("registrationFailed", this.handleRegistrationFailed);
    this.ua.on("newRTCSession", this.handleRTCSessions);
  }

  async start(autoRegister?: boolean): Promise<void> {

    if (autoRegister) {
      this.register();
    }

    this.ua.start();

    await wait(750);

    this.handleCalls();
  }

  async stop() {
    await this.queue.done();

    this.ua.stop();
  }

  async handleRTCSessions(
    data: IncomingRTCSessionEvent | OutgoingRTCSessionEvent,
  ) {
    this.log("ua:rtc_session", data);

    const {session, request} = data;

    if (session.direction !== "incoming") {
      return;
    }

    const incomingCall = new SipCall(session, request.from, request.to);

    this.queue.add(incomingCall);

    await incomingCall.done();

    this.queue.remove(incomingCall);
  }

  async handleCalls() {
    for await (const call of this.queue) {
      if (!call) {
        continue;
      }

      this._call = call;

      call.setOnEventHandler(this.log);

      await call.done();

      if (this.queue.isEmpty) {
        await wait(10_000);
      }

      this._call = null;

      this.log("sip:session:call_reset");
    }
  }

  setOnEventHandler(eventHandler: EventHandler) {
    this.eventHandler = eventHandler;
  }

  get call(): SipCall | null {
    return this._call;
  }

  get status(): string {
    return this._sipStatus;
  }

  isConnected() {
    return this.ua.isConnected();
  }

  isRegistered() {
    return this.ua.isRegistered();
  }

  register() {
    if (this.ua.isRegistered()) {
      return true;
    }

    this.ua.register();
  }

  unregister() {
    if (!this.ua.isRegistered()) {
      return;
    }

    this.ua.unregister({all: true});
  }

  get identity(): string {
    return this._identity;
  }

  handleConnecting(event: UAConnectingEvent) {
    this._sipStatus = "connecting";

    this.log(`ua:${this._sipStatus}`, event);
  }

  handleConnected(event: ConnectedEvent) {
    this._sipStatus = "connected";

    this.log(`ua:${this._sipStatus}`, event);
  }

  handleDisconnected(event: DisconnectEvent) {
    this._sipStatus = "disconnected";

    this.log(`ua:${this._sipStatus}`, event);
  }

  handleRegistered(event: RegisteredEvent) {
    this._sipStatus = "registered";

    this.log(`ua:${this._sipStatus}`, event);
  }

  handleUnRegistered(event: UnRegisteredEvent) {
    this._sipStatus = "unregistered";

    this.log(`ua:${this._sipStatus}`, event);
  }

  handleRegistrationFailed(event: UnRegisteredEvent) {
    this._sipStatus = "registrationFailed";

    this.log(`ua:${this._sipStatus}`, event);
  }

  private log(event: string, ...args: any[]) {
    const timestamp = new Date().toISOString();

    console.info(event, timestamp, args);

    this.eventHandler?.(event, this);
  }
}

export default Sip;

type EventHandler = (event: string, sip: Sip) => void;

type SipStatus =
  | "connecting"
  | "connected"
  | "disconnected"
  | "registered"
  | "unregistered"
  | "registrationFailed"
  | "sip:session";

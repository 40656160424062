import {
  ConciergeBillExpenses,
  ConciergeTicket,
  ConciergeTicketDocument,
  ConciergeTicketItem,
} from "../types/concierge";
import { Pagination } from "../types/pagination";
import { IConciergeService } from "../types/services/concierge-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

type TicketListingResponse = {
  tickets: any[];
  paginate: Pagination;
};

type TicketResponse = {
  ticket: any;
};

type TicketDocumentsResponse = {
  documents: any[];
};

type TicketBillExpenses = {
  expenses: any[];
};

class ConciergeService implements IConciergeService {
  async getTickets(
    assignee_id?: string,
    properties?: string[],
    department?: string[],
    service_type?: string,
    status?: string[],
    search?: string,
    page?: string,
    page_size?: string,
    sort?: string,
    cities?: string[],
    start_date?: string,
    end_date?: string,
    property_type?: string,
  ): Promise<FetchResult<ConciergeTicketItem[]>> {
    const qp = new URLSearchParams();

    if (assignee_id) {
      qp.set("assignee_id", assignee_id.toString());
    }

    if (properties?.length) {
      properties.forEach((each) => qp.append("property_id[]", each));
    }

    if (department?.length) {
      department.forEach((each) => qp.append("department[]", each));
    }

    if (service_type) {
      qp.set("service_type", service_type);
    }

    if (status?.length) {
      status.forEach((each) => qp.append("status[]", each));
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    if (search) {
      qp.set("query", search);
    }

    if (sort) {
      qp.append("sort_criteria", sort);
    }

    if (cities?.length) {
      cities.forEach((each) => qp.append("location_id[]", each));
    }

    if (start_date) {
      qp.set("due_start", start_date);
    }

    if (end_date) {
      qp.set("due_end", end_date);
    }

    if (property_type?.length) {
      qp.set("property_type", property_type);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/concierge";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<TicketListingResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.tickets.map((each) => {
      const {
          slug,
          title,
          due_at,
          assigned_to,
          property_name,
          priority,
          status,
          created_at,
          ticket_number,
          assigned_at,
        } = each,
        formatted: ConciergeTicketItem = {
          id: ticket_number || 0,
          slug: slug || "",
          status: status || "",
          priority: priority?.toLowerCase() || "p3",
          title: title || "",
          assignee: assigned_to || "",
          due_date: due_at || "",
          reported_date: created_at || "",
          property_name: property_name || "",
          assigned_date: assigned_at || "",
        };

      return formatted;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async getTicket(
    opportunitySlug: string,
  ): Promise<FetchResult<ConciergeTicket>> {
    const url = `/api/v2/concierge/${opportunitySlug}`,
      { error, response } = await GET<TicketResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        ticket_number,
        title,
        description,
        slug,
        property_id,
        ticket_type,
        due_at,
        assignee_id,
        author_id,
        priority,
        status,
        department,
        service_type,
        assigned_to,
        reported_by,
        reported_by_id,
        author,
        property_name,
        category_id,
        sub_category_id,
        created_at,
        expense_approval,
        property_type,
      } = response.ticket,
      formatted: ConciergeTicket = {
        id: id || 0,
        slug: slug || "",
        ticket_number: ticket_number || "",
        title: title || "",
        description: description || "",
        status: status || "",
        priority: priority?.toLowerCase() || "p3",
        department: department || "",
        service_type: service_type || "non_chargeable",
        property_id: property_id || 0,
        ticket_type: ticket_type || "",
        property_name: property_name || "",
        due_date: due_at || "",
        assignee_id: assignee_id,
        assignee: assigned_to || "",
        reported_by: reported_by || "",
        reported_by_id: reported_by_id || 0,
        author: author || "",
        author_id: author_id || 0,
        category: category_id || "",
        subcategory: sub_category_id || "",
        created_at: created_at || "",
        expense_id: expense_approval.id,
        budgeted_cost: expense_approval.budgeted_cost || 0,
        approved_cost: expense_approval.approved_cost || 0,
        property_type: property_type || "",
      };

    return {
      error: null,
      response: formatted,
    };
  }

  async addTicket(
    title: string,
    description: string,
    property_id: number,
    ticket_type: string,
    due_date: string,
    priority: string,
    status: string,
    service_type: string,
    assignee_id: number,
    reported_by_id: number,
    category: string,
    subcategory: string,
    vertical: string,
  ): Promise<FetchResult<string>> {
    const template = [
        {
          key: "title",
          value: title,
        },
        {
          key: "description",
          value: description,
        },
        {
          key: "service_type",
          value: service_type,
        },
        {
          key: "due_at",
          value: due_date,
        },
        {
          key: "status",
          value: status,
        },
        {
          key: "property_id",
          value: property_id,
        },
        {
          key: "ticket_type",
          value: ticket_type,
        },
        {
          key: "priority",
          value: priority,
        },
        {
          key: "reported_by_id",
          value: reported_by_id,
        },
        {
          key: "assignee_id",
          value: assignee_id,
        },
        {
          key: "category_id",
          value: category,
        },
        {
          key: "sub_category_id",
          value: subcategory,
        },
        {
          key: "property_type",
          value: vertical,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;

        if (value !== undefined) {
          compiled[key] = value;
        }

        return compiled;
      }, {} as { [k: string]: string | number | string[] }),
      url = `/api/v2/concierge`,
      { error, response } = await POST<any>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const slug = response.ticket?.slug || "";

    return {
      error: null,
      response: slug,
    };
  }

  async updateTicket(
    slug: string,
    priority?: string,
    property_id?: number,
    ticket_type?: string,
    status?: string,
    department?: string,
    service_type?: string,
    title?: string,
    description?: string,
    due_date?: string,
    reported_by_id?: string,
    hold_reason?: string,
    hold_reopen_date?: string,
    reopen_due_date?: string,
    category?: string,
    subcategory?: string,
    expense_id?: number,
    budgeted_cost?: number,
    approved_cost?: number,
    send_email?: boolean,
    property_type?: string,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "title",
          value: title,
        },
        {
          key: "description",
          value: description,
        },
        {
          key: "department",
          value: department,
        },
        {
          key: "service_type",
          value: service_type,
        },
        {
          key: "due_at",
          value: reopen_due_date || due_date,
        },
        {
          key: "status",
          value: status,
        },
        {
          key: "property_id",
          value: property_id,
        },
        {
          key: "ticket_type",
          value: ticket_type,
        },
        {
          key: "priority",
          value: priority,
        },
        {
          key: "reported_by_id",
          value: reported_by_id,
        },
        {
          key: "hold_reason",
          value: hold_reason,
        },
        {
          key: "hold_expiry",
          value: hold_reopen_date,
        },
        {
          key: "category_id",
          value: category,
        },
        {
          key: "sub_category_id",
          value: subcategory,
        },
        {
          key: "expense_approval_attributes",
          value: {
            id: Number(expense_id) || null,
            budgeted_cost:
              Number(budgeted_cost) > 0 ? Number(budgeted_cost) : null,
            approved_cost:
              Number(approved_cost) > 0 ? Number(approved_cost) : null,
          },
        },
        {
          key: "send_email",
          value: send_email,
        },
        {
          key: "property_type",
          value: property_type,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;

        if (value !== undefined) {
          compiled[key] = value;
        }

        return compiled;
      }, {} as { [k: string]: string | number | string[] | {} }),
      url = `/api/v2/concierge/${slug}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePOC(
    opportunity_slug: string,
    assignee_id?: number,
  ): Promise<FetchResult<void>> {
    const payload = { assignee_id: assignee_id },
      url = `/api/v2/concierge/${opportunity_slug}/update_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getTicketDocuments(
    opportunity_slug: string,
  ): Promise<FetchResult<ConciergeTicketDocument[]>> {
    const url = `/api/v2/concierge/${opportunity_slug}/documents`;

    const { error, response } = await GET<TicketDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.documents.map((each) => {
      const { id, link } = each,
        formatted: ConciergeTicketDocument = {
          id: id,
          file: link || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addTicketDocument(
    opportunity_slug: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    formData.set("file", file);

    const url = `/api/v2/concierge/${opportunity_slug}/documents`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteTicketDocument(
    opportunity_slug: string,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/concierge/${opportunity_slug}/documents/${document_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getBillingExpenses(
    opportunitySlug: string,
  ): Promise<FetchResult<ConciergeBillExpenses[]>> {
    const url = `/api/v2/concierge/${opportunitySlug}/expenses`,
      { error, response } = await GET<TicketBillExpenses>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.expenses.map((each) => {
      const {
          id,
          amount,
          attachment,
          base_amount,
          bill_date,
          bill_number,
          gst_percentage,
          name,
          expense_type_id,
          status,
          description,
        } = each,
        formatted: ConciergeBillExpenses = {
          id: id,
          amount: amount || 0,
          attachment: attachment
            ? {
                id: attachment.id,
                file: attachment.file,
              }
            : undefined,
          base_amount: base_amount || "",
          bill_date: bill_date || "",
          bill_number: bill_number || "",
          gst_percentage: gst_percentage || "",
          name: name || "",
          status: status || "",
          description: description || "",
          expense_type_id: expense_type_id,
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addBillingExpenses(
    ticket_slug: string,
    bill_number: number,
    nature_of_expense: string,
    base_amount?: string,
    status?: string,
    description?: string,
    billGeneratedDate?: string,
    gst_percentage?: number,
    attachment_file?: File | string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if (base_amount) {
      payload.set("base_amount", base_amount);
    }
    if (bill_number) {
      payload.set("bill_number", bill_number.toString());
    }
    if (status) {
      payload.set("status", status);
    }
    if (description) {
      payload.set("description", description);
    }
    if (gst_percentage) {
      payload.set("gst_percentage", gst_percentage.toString());
    }
    if (billGeneratedDate) {
      payload.set("bill_date", billGeneratedDate);
    }
    if (nature_of_expense) {
      payload.set("expense_type_id", nature_of_expense);
    }
    if (attachment_file instanceof File) {
      payload.set("attachment_attributes[file]", attachment_file);
    }

    const url = `/api/v2/concierge/${ticket_slug}/expenses`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateBillingExpense(
    ticket_slug: string,
    id: number,
    bill_number: number,
    nature_of_expense: string,
    base_amount?: string,
    billGeneratedDate?: string,
    gst_percentage?: number,
    status?: string,
    description?: string,
    attachment_file?: File | string,
    attachmentId?: number,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if (base_amount) {
      payload.set("base_amount", base_amount);
    }
    if (bill_number) {
      payload.set("bill_number", bill_number.toString());
    }
    if (status) {
      payload.set("status", status);
    }
    if (description) {
      payload.set("description", description);
    }
    if (gst_percentage) {
      payload.set("gst_percentage", gst_percentage.toString());
    }
    if (billGeneratedDate) {
      payload.set("bill_date", billGeneratedDate);
    }
    if (nature_of_expense) {
      payload.set("expense_type_id", nature_of_expense);
    }
    if (attachmentId) {
      payload.set("attachment_attributes[id]", attachmentId.toString());
    }
    if (attachment_file instanceof File) {
      payload.set("attachment_attributes[file]", attachment_file);
    }

    if (attachmentId !== undefined && attachment_file === null) {
      payload.set("attachment_attributes[_destroy]", "true");
    }

    const url = `/api/v2/concierge/${ticket_slug}/expenses/${id}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default ConciergeService;

import {Theme} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useCallReceiverStyles = makeStyles((theme: Theme) => {
	const {palette: {secondary}} = theme;

	return {
		fab: {
			userSelect: "none",
			background: secondary.main,
			color: secondary.contrastText,
			borderRadius: "50%",
			position: "fixed",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25))",
			cursor: "pointer",
			"@media (max-width: 768px)": {
				right: "8px",
				bottom: "16px",
				width: "40px",
				height: "40px",
			},
			"@media (min-width: 768px)": {
				transform: "translateX(100%)",
				right: "calc((100vw - var(--container-width)) / 2 - 24px)",
				bottom: "64px",
				width: "56px",
				height: "56px",
			},
		},
		floatingPanel: {
			borderRadius: "6px",
			position: "fixed",
			maxHeight: "500px",
			padding: "0 16px",
			background: "#1f1f1f",
			boxShadow: "0px 1px 4px rgba(200, 200, 200, 0.5)",
			color: "rgba(255,255,255,0.9)",
			display: "grid",
			gridTemplate: "auto/1fr",
			alignContent: "flex-start",
			gap: "16px",
			overflow: "auto",
			"@media (max-width: 768px)": {
				left: "50%",
				bottom: "60px",
				transform: "translateX(-50%)",
				width: "calc(100vh - 32px)",
			},
			"@media (min-width: 768px)": {
				right: "calc((100vw - var(--container-width)) / 2)",
				bottom: "64px",
				width: "400px",
			},
			"&[data-open='false']": {
				display: "none",
			},
		},
		phoneNo: {
			fontWeight: 500,
			color: "white",
			"@media (max-width: 768px)": {
				fontSize: "20px",
			},
			"@media (min-width: 768px)": {
				fontSize: "20px",
			},
		},
		incomingCall: {
			textAlign: "left",
			padding: "16px 0",
			lineHeight: "30px",
			whiteSpace: "pre-wrap",
			borderBottom: "2px solid rgba(255,255,255,0.9)",
		},
		ongoingCall: {
			display: "flex",
			alignItems: "flex-end",
			justifyContent: "space-between",
			lineHeight: "30px",
			whiteSpace: "pre-wrap",
			padding: "16px 0",
			position: "sticky",
			top: "0",
			background: "#1f1f1f",
			borderBottom: "2px solid rgba(255,255,255,0.9)",
			zIndex: 1,
		},
		callDuration: {
			fontSize: "14px",
			fontWeight: "normal",
		},
		enquiryTrip: {
			paddingBottom: "16px",
			borderBottom: "1px solid rgba(255,255,255,0.5)",
		},
		transfer: {
			background: "white",
		},
		transferContainer: {
			display: "grid",
			gridTemplate: "auto/1fr auto",
			gap: "16px",
			padding: "16px 0",
			marginBottom: "-16px",
			position: "sticky",
			bottom: "88px",
			background: "#1f1f1f",
		},
		actions: {
			display: "grid",
			gridTemplate: "auto/auto",
			gridAutoFlow: "column",
			gap: "32px",
			alignContent: "center",
			justifyContent: "center",
			padding: "16px 0",
			position: "sticky",
			bottom: "0",
			background: "#1f1f1f",
			minHeight: "88px",
		},
		cta: {
			cursor: "pointer",
			borderRadius: "50%",
			border: "2px solid white",
			background: "rgba(255, 255, 255, 0.1)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"@media (max-width: 768px)": {
				width: "40px",
				height: "40px",
			},
			"@media (min-width: 768px)": {
				width: "56px",
				height: "56px",
			},
			"&[data-action='positive']": {
				color: "#009688",
				borderColor: "#009688",
				background: "#e0f2f1",
			},
			"&[data-action='negative']": {
				color: "#b71c1c",
				borderColor: "#b71c1c",
				background: "#ffcdd2",
			},
		},
		newTrip: {
			display: "grid",
			gridTemplate: "auto/1fr auto",
			gap: "4px",
			fontWeight: 500,
			fontSize: "16px",
			color: "white",
		},
		newTripAction: {
			gridRow: "span 4",
			alignSelf: "center",
		},
		customerTag: {
			display: "inline-flex",
			alignItems: "center",
			justifyContent: "center",
			background: "#ffffff29",
			color: "#FFFFFF",
			fontSize: "12px",
			height: "30px",
			borderRadius: "4px",
			padding: "8px",
			"@media (min-width: 768px)": {
				margin: "0 8px",
			},
		},
		repeatTag: {
			background: "#ff868629",
		},
		referTag: {
			background: "#00968829",
		},
		newTag: {
			background: "#6587ff29",
		},
		customerTagsContainer: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
	};
});

export default useCallReceiverStyles;

import { Pagination } from "../types/pagination";
import { RentalPaymentApprovalItem } from "../types/rental-payment-approval";
import { IRentalPaymentApprovalService } from "../types/services/rental-payment-approval-service";
import { FetchResult, GET, PATCH } from "../utils/fetch";

class RentalPaymentApprovalService implements IRentalPaymentApprovalService {
  async getApprovalRequests(
    status?: string,
    payment_for?: string,
    from_date?: string,
    until_date?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalPaymentApprovalItem[]>> {
    const qp = new URLSearchParams();

    if (payment_for) {
      qp.set("payment_for", payment_for);
    }

    if (search) {
      qp.set("query", search);
    }

    if (from_date) {
      qp.set("start_date", from_date);
    }

    if (until_date) {
      qp.set("end_date", until_date);
    }

    if (status) {
      qp.set("approval_status", status);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/rental/payments";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<ApprovalsListResponse>(url);

    if (error) {
      return {
        error: null,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown Error"),
        response: null,
      };
    }

    const properties = response.payments.map(each => {
      const {
          id,
          guest_name,
          amount,
          payment_date,
          payment_for,
          approval_status,
          rental_opportunity_slug,
          propertie_names,
          poc_exec,
          reference_no,
          created_at,
          gateway,
        } = each,
        nameSplits = (guest_name || "").split(" "),
        property: RentalPaymentApprovalItem = {
          id: id,
          opportunity_slug: rental_opportunity_slug || "",
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          property_name: propertie_names || "",
          poc_name: poc_exec || "",
          amount: amount ?? 0,
          payment_for: payment_for ?? "",
          payment_method: gateway ?? "",
          reference_no: reference_no ?? "",
          requested_date: created_at || "",
          payment_date: payment_date || "",
          status: approval_status || "",
        };

      return property;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: properties,
    };
  }

  async approveRequest(
    opportunitySlug: string,
    id: number,
    reason: string,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunitySlug}/payments/${id}/approve`,
      { error } = await PATCH<ApprovalResponse>(url, { comments: reason });

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async questionRequest(
    opportunitySlug: string,
    id: number,
    reason: string,
    comments: string,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunitySlug}/payments/${id}/reject`,
      payload = { reason: reason, comments: comments },
      { error } = await PATCH<ApprovalResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default RentalPaymentApprovalService;

type ApprovalsListResponse = {
  payments: any[];
  paginate: Pagination;
};

type ApprovalResponse = {
  property: any;
};

import * as Yup from "yup";

import EMAIL_VALIDATION from "../../utils/validations/email-validation";
import { NAME_VALIDATION_ALPHANUMERIC } from "../../utils/validations/name-validation";

const SOURCE_ENQUIRY_OTHER_REASON = new Set([
  "reference",
  "word_of_mouth",
  "other",
]);

const phoneRegex = RegExp(/^[0-9\b]+$/);

const RENTAL_TRIP_ADD_SCHEMA = Yup.object({
  first_name: NAME_VALIDATION_ALPHANUMERIC.required("Required"),
  last_name: NAME_VALIDATION_ALPHANUMERIC,
  email: EMAIL_VALIDATION.test({
    name: "required",
    message: "either of email or phone no is required",
    test: (value: any, context: any) => {
      const { phone_no } = context.parent;

      if (!phone_no) {
        return value === undefined ? false : Boolean(value).valueOf();
      }

      return true;
    },
  }),
  phone_no: Yup.string().when("customer_id", {
    is: (customer_id: number) => customer_id === undefined,
    then: Yup.string()
      .matches(phoneRegex, "Only Numbers Allowed")
      .required("Phone is required"),
  }),
  location: Yup.string().trim().required("Required"),
  source_of_enquiry: Yup.string().trim().required("Required"),
  source_of_enquiry_other: Yup.string().when("source_of_enquiry", {
    is: (source_of_enquiry: string) =>
      SOURCE_ENQUIRY_OTHER_REASON.has(source_of_enquiry),
    then: Yup.string().trim().required("Required"),
  }),
  agent: Yup.string().when("source_of_enquiry", {
    is: (source_of_enquiry: string) => source_of_enquiry === "agent",
    then: Yup.string().trim().required("Required"),
  }),
  home_owner_id: Yup.string().when("source_of_enquiry", {
    is: (source_of_enquiry: string) => source_of_enquiry === "homeowner_ref",
    then: Yup.string().trim().required("Required"),
  }),
  message: Yup.string().trim(),
  poc_exec: Yup.string().when("role", {
    is: (role: string) => role !== "agent",
    then: Yup.string().trim().required("Required"),
  }),
  poc_head: Yup.string().trim(),
});

export default RENTAL_TRIP_ADD_SCHEMA;

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalMastersService } from "../types/services/rental-masters-service";

const RentalMastersServiceContext = createContext<IRentalMastersService>(
  undefined as any,
);

interface RentalMastersServiceProviderProps {
  service: IRentalMastersService;
  children: ReactNode;
}

function RentalMastersServiceProvider(
  props: RentalMastersServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalMastersServiceContext.Provider value={service}>
      {children}
    </RentalMastersServiceContext.Provider>
  );
}

function useRentalMastersService() {
  return useContext(RentalMastersServiceContext);
}

export { RentalMastersServiceProvider, useRentalMastersService };

import startOfDay from "date-fns/startOfDay";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useDashboardService } from "../../../contexts/dashboard-service-context";
import { useMe } from "../../../contexts/me-context";
// import usePolling from "../../../hooks/use-polling";
import useMetricsCounterStyles from "./MetricsCounterStyles";

function MetricsCounter() {
  const clxs = useMetricsCounterStyles();

  const [metric, setMetric] = useState<number>(0);

  // const { iteration } = usePolling(POLLING_PERIOD),
  const { me } = useMe(),
    { getTaskStats } = useDashboardService(),
    url = useMemo(
      () => {
        if (!me) {
          return "";
        }

        const start_date = startOfDay(new Date()).toISOString(),
          url = `/?due_until=${start_date}&staff=${me.id}`;

        return url;
      },
      [me],
    ),
    handleNotify = async () => {
      if (!("Notification" in window)) {
        return;
      }

      if (Notification.permission !== "denied") {
        const permission = await Notification.requestPermission();

        if (permission !== "granted") {
          return;
        }
      }

      new Notification(
        "Your tasks have been updated",
        { body: "Click to view them!" },
      );
    },
    handleGetMetrics = async () => {
      if (!me) {
        return;
      }

      const start_date = startOfDay(new Date()),
        { error, response } = await getTaskStats(
          start_date.toISOString(),
          me.id.toString(),
        );

      if (error || !response) {
        return;
      }

      const {
        future_reminders,
        past_due_reminders,
        todays_reminders,
      } = response,
        total = future_reminders + past_due_reminders + todays_reminders;

      setMetric(
        prevTotal => {
          if (total !== prevTotal) {
            handleNotify();
          }

          return total;
        },
      );
    };

  useEffect(
    () => {
      handleGetMetrics();
    },
    [me],
    // [me, iteration],
  );

  return (
    <Link
      className={clxs.container}
      to={url}
    >
      {metric}
    </Link>
  );
}

export default MetricsCounter;

// const POLLING_PERIOD = 5 * 60 * 100;

function uuidv4() {
  return TEMPLATE.replace(
    /[018]/g, (c) => {
      const seed = crypto.getRandomValues(new Uint8Array(1))[0];

      const n = Number.parseInt(c);

      return (n ^ seed & 15 >> n / 4).toString(16);
    },
  );
}

export default uuidv4;

const TEMPLATE = `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`;

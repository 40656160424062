import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { ComponentType, ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";

import { MastersProvider } from "../../contexts/masters-context";
import { MeProvider } from "../../contexts/me-context";
import { SecureStateProvider } from "../../contexts/secure-state-context";
import SipProvider from "../../contexts/sip-context/sip-context";
import SnackbarProvider from "../../contexts/snackbar-provider";
import CustomMaterialTheme from "../../utils/theme";
import { ContextMenuProvider } from "../ContextMenu";
import { ModalProvider } from "../Modal";

interface StateProviderProps {
  children: ReactNode;
  RouterComponent?: ComponentType<any>;
  routerProps?: any;
}

function StateProvider(props: StateProviderProps) {
  const { children, RouterComponent = BrowserRouter, routerProps = {} } = props;

  return (
    <RouterComponent {...routerProps}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomMaterialTheme>
          <SecureStateProvider>
            <MeProvider>
              <MastersProvider>
                <ContextMenuProvider>
                  <SnackbarProvider>
                    <ModalProvider>
                      <SipProvider>
                        {children}
                      </SipProvider>
                    </ModalProvider>
                  </SnackbarProvider>
                </ContextMenuProvider>
              </MastersProvider>
            </MeProvider>
          </SecureStateProvider>
        </CustomMaterialTheme>
      </LocalizationProvider>
    </RouterComponent>
  );
}

export default StateProvider;

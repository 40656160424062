class Queue<T> {
  private head: Link<T> | null;
  private tail: Link<T> | null
  private resolve?: (value: T | null) => void;

  constructor() {
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.clear = this.clear.bind(this);
    this.pop = this.pop.bind(this);
    this.done = this.done.bind(this);
    this[Symbol.asyncIterator] = this[Symbol.asyncIterator].bind(this);

    this.head = null;
    this.tail = null;
  }

  add(value: T) {
    if (this.resolve) {
      this.resolve(value);

      this.resolve = undefined;

      return;
    }

    const cursor = {value: value, next: null};

    if (!this.head) {
      this.head = cursor;
    }

    if (this.tail) {
      this.tail.next = cursor;
    }

    this.tail = cursor;
  }

  remove(value: T) {
    let preCursor = null;

    for (let cursor = this.head; cursor !== null; cursor = cursor?.next ?? null) {
      if (cursor.next?.value !== value) {
        continue;
      }

      preCursor = cursor;

      break;
    }

    if (!preCursor) {
      return false;
    }

    const cursor = preCursor.next;

    preCursor.next = cursor?.next ?? null;

    return true;
  }

  get isEmpty(): boolean {
    return this.head === null;
  }

  async clear() {
    for (let cursor = this.head; cursor !== null;) {
      const gcCursor = cursor;

      cursor = cursor?.next ?? null;

      gcCursor.next = null;
    }

    this.head = null;

    this.tail = null;
  }

  private tryPop(): T | null {
    const cursor = this.head;

    this.head = cursor?.next ?? null;

    if (this.tail === cursor) {
      this.tail = null;
    }

    const value = cursor?.value ?? null;

    return value;
  }

  pop(): Promise<T | null> {
    const value = this.tryPop();

    if (value) {
      return Promise.resolve(value);
    }

    if (this.resolve) {
      throw new Error("pop being already consumed");
    }

    return new Promise<T | null>(
      resolve => this.resolve = resolve,
    );
  }

  async done() {
    await this.clear();

    this.resolve?.(null);
  }

  async* [Symbol.asyncIterator]() {
    while (true) {
      const value = await this.pop();

      if (!value) {
        yield null;

        return;
      }

      yield value;
    }
  }
}

export default Queue;

export interface Link<T> {
  value: T;
  next: Link<T> | null;
}

import _EnquiryService from "../services/enquiry-service";
import { ICallService } from "../types/services/call-service";
import { IEnquiryService } from "../types/services/enquiry-service";

const instance = new _EnquiryService();

const EnquiryService: IEnquiryService = instance;

const CallService: ICallService = instance;

export { EnquiryService, CallService };

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalInvoiceApprovalService } from "../types/services/rental-invoice-approval-service";

const RentalInvoiceApprovalServiceContext = createContext<IRentalInvoiceApprovalService>(
  undefined as any,
);

interface RentalInvoiceApprovalServiceProviderProps {
  service: IRentalInvoiceApprovalService;
  children: ReactNode;
}

function RentalInvoiceApprovalServiceProvider (
  props: RentalInvoiceApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalInvoiceApprovalServiceContext.Provider value={service}>
      {children}
    </RentalInvoiceApprovalServiceContext.Provider>
  );
}

function useRentalInvoiceApprovalService () {
  return useContext(RentalInvoiceApprovalServiceContext);
}

export { RentalInvoiceApprovalServiceProvider, useRentalInvoiceApprovalService };

import React, {createContext, ReactNode, useCallback, useContext, useMemo, useState} from "react";

import {Sip} from "../../services/sip";
import {SipCredentials} from "../../types/staff";

interface SipProviderProps {
  children: ReactNode;
}

function SipProvider(props: SipProviderProps) {
  const {children} = props,
    [_, setBc] = useState<BroadcastChannel | null>(null),
    [sipState, setSipState] = useState<SipState>({}),
    handleConnect = useCallback(
      async (sipArgs: SipCredentials) => {
        const sip = new Sip(sipArgs);

        await sip.start(true);

        setSipState({sip: sip});

        sip.setOnEventHandler(
          (event: string) => {
            if (event === "disconnected") {
              return;
            }

            setSipState(prev => ({...prev}));
          },
        );

        const bc = new BroadcastChannel("sip-identity");

        bc.onmessage = async (e) => {
          const identity = e.data as string;

          if (sip.identity === identity) {
            return;
          }

          await sip.stop();

          setSipState({});

          bc.close();

          setBc(null);

          console.log("DISCONNECTING");
        };

        bc.postMessage(sip.identity);

        setBc(bc);

        return sip;
      },
      [setSipState],
    ),
    handleDisconnect = useCallback(
      async () => {
        const {sip} = sipState;

        await sip?.stop();

        setSipState({});

        setBc(
          bc => {
            bc?.close();

            return null;
          },
        );
      },
      [setBc, setSipState, sipState],
    ),
    sipReturn = useMemo(
      () => {
        const sipReturn: SipReturn = {
          sip: sipState?.sip,
          onSipConnect: handleConnect,
          onSipDisconnect: handleDisconnect,
        };

        return sipReturn;
      },
      [sipState, handleConnect, handleDisconnect],
    );

  void _;

  return (
    <SipContext.Provider value={sipReturn}>
      {children}
    </SipContext.Provider>
  );
}

export default SipProvider;

function useSip() {
  return useContext(SipContext);
}

export {
  useSip,
};

const noop = () => {
  throw new Error("not implemented");
};

const DEFAULT_SIP_RETURN: SipReturn = {
  onSipConnect: noop,
  onSipDisconnect: noop,
};

const SipContext = createContext<SipReturn>(DEFAULT_SIP_RETURN);

type SipReturn = {
  sip?: Sip;
  onSipConnect: (sipArgs: SipCredentials) => Promise<Sip>;
  onSipDisconnect: () => void;
};

type SipState = Partial<Omit<SipReturn, "onSipConnect" | "onSipDisconnect">>;

import React, { createContext, ReactNode, useContext } from "react";

import { IMastersService } from "../types/services/masters-service";

const MastersServiceContext = createContext<IMastersService>(undefined as any);

interface MastersServiceProviderProps {
  service: IMastersService;
  children: ReactNode;
}

function MasterServiceProvider(props: MastersServiceProviderProps) {
  const { service, children } = props;

  return (
    <MastersServiceContext.Provider value={service}>
      {children}
    </MastersServiceContext.Provider>
  );
}

function useMastersService() {
  return useContext(MastersServiceContext);
}

export { MasterServiceProvider, useMastersService };

function ignoreProps(props: any, IGNORE_PROPS: Set<string>) {
  return Object.entries(props).reduce((compiled, [key, value]) => {
    if (!IGNORE_PROPS.has(key)) {
      compiled[key] = value;
    }
    return compiled;
  }, {} as any);
}

export default ignoreProps;

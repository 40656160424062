import makeStyles from '@mui/styles/makeStyles';

const useNavigationStyles = makeStyles((theme: any) => {
  const {
    palette: {
      primary: { main, contrastText },
    },
  } = theme;
  return {
    "@keyframes fade-in": {
      from: {
        opacity: 0,
        transform: "translateX(-40px)",
      },
      to: {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
    appBar: {
      transition: "all 0.3s",
      "&[data-active='true']": {
        background: "lightseagreen",
      },
    },
    navigationAdjust: {
      height: "0",
      "@media (max-width: 600px)": {
        marginTop: "56px",
      },
      "@media (min-width: 600px)": {
        marginTop: "64px",
      },
    },
    brandName: {
      display: "flex",
      alignItems: "center",
      marginLeft: "-12px",
    },
    navigationStartActions: {
      flex: "1 1 0",
      display: "grid",
      gridTemplate: "auto/auto",
      gridAutoFlow: "column",
      gap: "16px",
      justifyContent: "flex-start",
    },
    navigationEndActions: {
      display: "grid",
      gridTemplate: "auto/auto",
      gridAutoFlow: "column",
      gap: "16px",
    },
    verticalSelector: {
      minWidth: "180px",
      "@media (max-width: 787px)": {
        width: "100%",
      },
    },
    iconActions: {
      color: contrastText,
      display: "flex",
      alignItems: "center",
      "& a": {
        textDecoration: "none",
        color: contrastText,
      },
      "& .material-icons": {
        fontSize: "32px",
      },
    },
    menu: {
      cursor: "pointer",
      color: contrastText,
    },
    list: {
      minWidth: "250px",
      color: main,
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "220px",
    },
    avatar: {
      "@media (max-width: 768px)": {
        width: "120px",
        height: "120px",
        fontSize: "32px",
      },
      "@media (min-width: 768px)": {
        width: "40px",
        height: "40px",
        fontSize: "14px",
      },
    },
    desktop: {
      textDecoration: "unset",
      "@media (max-width: 768px)": {
        display: "none",
      },
    },
    mobile: {
      textDecoration: "unset",
      "@media (min-width: 768px)": {
        display: "none",
      },
    },
  };
});

export default useNavigationStyles;

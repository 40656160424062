import React, { createContext, ReactNode, useContext } from "react";

import { IDevelopmentOpportunityGuestService } from "../types/services/development-opportunity-guest-service";


const DevelopmentOpportunityGuestServiceContext = createContext<IDevelopmentOpportunityGuestService>(
  undefined as any,
);

interface DevelopmentOpportunityGuestServiceProviderProps {
  service: IDevelopmentOpportunityGuestService;
  children: ReactNode;
}

function DevelopmentOpportunityGuestServiceProvider(
  props: DevelopmentOpportunityGuestServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <DevelopmentOpportunityGuestServiceContext.Provider value={service}>
      {children}
    </DevelopmentOpportunityGuestServiceContext.Provider>
  );
}

function useDevelopmentOpportunityGuestService() {
  return useContext(DevelopmentOpportunityGuestServiceContext);
}

export { DevelopmentOpportunityGuestServiceProvider, useDevelopmentOpportunityGuestService };

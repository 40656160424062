import React, { createContext, ReactNode, useContext } from "react";

import { IEnquiryService } from "../types/services/enquiry-service";

const EnquiryServiceContext = createContext<IEnquiryService>(undefined as any);

interface EnquiryServiceProviderProps {
  service: IEnquiryService;
  children: ReactNode;
}

function EnquiryServiceProvider(props: EnquiryServiceProviderProps) {
  const { service, children } = props;

  return (
    <EnquiryServiceContext.Provider value={service}>
      {children}
    </EnquiryServiceContext.Provider>
  );
}

function useEnquiryService() {
  return useContext(EnquiryServiceContext);
}

export { EnquiryServiceProvider, useEnquiryService };

import React, { createContext, ReactNode, useContext } from "react";

import { ILoginService } from "../types/services/login-service";

const LoginServiceContext = createContext<ILoginService>(undefined as any);

interface LoginServiceProviderProps {
  service: ILoginService;
  children: ReactNode;
}

function LoginServiceProvider(props: LoginServiceProviderProps) {
  const { service, children } = props;

  return (
    <LoginServiceContext.Provider value={service}>
      {children}
    </LoginServiceContext.Provider>
  );
}

function useLoginService() {
  return useContext(LoginServiceContext);
}

export { LoginServiceProvider, useLoginService };

import makeStyles from "@mui/styles/makeStyles";

const useEnquiryTripItemStyles = makeStyles({
	container: {
		display: "grid",
		gridTemplate: "auto/1fr auto",
		gap: "4px",
		fontSize: "13px",
	},
	name: {
		fontWeight: 500,
		fontSize: "16px",
		color: "white",
	},
	view: {
		gridRow: "span 4",
		alignSelf: "center",
	},
});

export default useEnquiryTripItemStyles;

import makeStyles from "@mui/styles/makeStyles";

const useMetricsCounterStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "orangered",
    color: "white",
    borderRadius: "50%",
    "@media (max-width: 768px)": {
      width: "32px",
      height: "32px",
      fontSize: "12px",
    },
    "@media (min-width: 768px)": {
      width: "40px",
      height: "40px",
      fontSize: "14px",
    },
  },
});

export default useMetricsCounterStyles;

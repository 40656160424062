import { useCallback, useEffect, useState } from "react";

import { Pagination } from "../types/pagination";
import { FetchResult } from "../utils/fetch";
import useLoading from "./use-loading";
import usePagination, { DEFAULT_PAGINATION } from "./use-pagination";
import useRefresh from "./use-refresh";

export type Arg = string | string[] | number | number[] | Date | null;

export type DataFetcher<A extends Arg[], R> = (
  ...args: A
) => Promise<FetchResult<R>>;

export type DataFetcherArgs<A extends Arg[], R> = DataFetcher<A, R> extends (
  ...args: infer T
) => any
  ? T
  : never;

export type DataAdapterState<R> = {
  loading: boolean;
  error: string;
  data: R;
  pagination: Pagination;
  onRefresh: () => void;
};

function getReaction(...args: Arg[]) {
  const reaction = args.map(a => {
    if (!a) {
      return a;
    }

    if (a instanceof Date) {
      return a.toISOString();
    }
    if (Array.isArray(a)) {
      return JSON.stringify(a);
    }

    const t = typeof a;
    if (t === "string") {
      return a;
    }
    if (t === "number") {
      return a;
    }

    throw new Error(`unsupported type ${t}`);
  });

  return reaction;
}

function useDataAdapter<A extends Arg[], R>(
  fetcher: DataFetcher<A, R>,
  initialValue: R | null,
  ...args: DataFetcherArgs<A, R>
): DataAdapterState<R | null> {
  const { loading, showLoading, hideLoading } = useLoading(),
    { pagination, updatePagination } = usePagination(),
    { refresh, onRefresh } = useRefresh(),
    reaction = [...getReaction(...args), refresh],
    [error, updateError] = useState<string>(""),
    [data, updateData] = useState<R | null>(initialValue),
    onFetchData = useCallback(async () => {
      showLoading();
      const { error, paginate, response } = await fetcher(...args);
      if (error) {
        updateError(error.message);
        updatePagination(DEFAULT_PAGINATION);
        updateData(initialValue);
      } else {
        updateError("");
        if (paginate) {
          updatePagination(paginate);
        }
        if (response) {
          updateData(response);
        }
      }
      hideLoading();
    }, reaction);

  useEffect(() => {
    onFetchData();
  }, reaction);

  return {
    loading: loading,
    error: error,
    data: data,
    pagination: pagination,
    onRefresh: onRefresh,
  };
}

export default useDataAdapter;

import { MasterOption } from "./masters";

export type SEO = {
  id?: number;
  title: string;
  description: string;
  keywords: string;
  canonical: string;
};

export const SAMPLE_SEO: SEO = {
  title: "",
  description: "",
  keywords: "",
  canonical: "",
};

export type Amenity = {
  id: number;
  name: string;
  active: true;
  image: WebImage;
};

export type NearbyPlace = {
  id?: number;
  name: string;
  category: string;
  distance_from_property: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
};

export type Mood = {
  id: number;
  name: string;
  image: WebImage;
  description: string;
  active: boolean;
  seo: SEO;
};

export type PropertyHighlight = {
  id: number;
  name: string;
  image: WebImage;
  description: string;
  active: boolean;
};

export type PropertyLocation = {
  address_line: string;
  city: string;
  state: string;
  country: string;
  google_location_url: string;
  latitude: string;
  longitude: string;
  //image_url: string;
};

export type PropertyLocationCoupon = {
  id: number;
  coupon_id: number;
  location_id: number;
  auto_apply: boolean;
  sequence: number;
};

export type PropertyLocationCategory = {
  id: number;
  name: string;
  image: WebImage;
  seo: SEO;
  country: string;
  country_id: number;
  state: string;
  city: string;
  active: boolean;
  upcoming: boolean;
  sequence: number;
  coupons: PropertyLocationCoupon[];
};

export type WebImage = {
  id?: number;
  name?: string;
  url: string | File;
  alt: string;
  caption: string;
  sequence?: number;
};

export type PropertyImage = WebImage & {
  aspect_ratio: string;
};

export type Sight = {
  id?: number;
  image: string | File;
  name: string;
  state: string;
};

export type Bedroom = {
  id?: number;
  name: string;
  bed_type: string;
};

export type Text = {
  id?: number;
  content: string;
};

export type RentalPropertyConfig = {
  id: number;
  bedroom_count: number;
  active: boolean;
};

export type RentalProperty = {
  id: number;
  slug: string;
  location_category: number;
  name: string;
  description: string;
  bedroom_count: number;
  adults_count: number;
  children_count: number;
  extra_adults_count: string;
  extra_children_count: string;
  pools_count: number;
  baths_count: number;
  extra_adults_fare_currency: string;
  extra_adults_fare: string;
  extra_child_fare_currency: string;
  extra_child_fare: string;
  publication_features: string[];
  pet_friendly: boolean;
  rentable: boolean;
  wheelchair_friendly: boolean;
  isprava_built: boolean;
  isprava_exclusive: boolean;
  trending_listed: boolean;
  active: boolean;
  channel_manager_id: string;
  upcoming: boolean;
  location: PropertyLocation;
  property_images: PropertyImage[];
  cancellation_policies: Text[];
  house_rules: Text[];
  amenities: Amenity[];
  nearby_places: NearbyPlace[];
  bedrooms: Bedroom[];
  moods: Mood[];
  things_to_do: Sight[];
  highlights: PropertyHighlight[];
  seo: SEO;
  homeowner_id?: number;
  homeowner_name: string;
  homeowner_email: string;
  homeowner_phone_no: string;
  contract_id?: number;
  contract_type: string;
  contract_rev_share_percentage: number;
  contract_free_nights: number;
  contract_effective_date: string;
  contract_expiry_date: string;
  security_deposit: number;
  maintenance_days_before_checkin: number;
  maintenance_days_after_checkout: number;
  rental_staff_id?: number;
  maintenance_staff_id?: number;
  guest_relation_staff_id?: number;
  configOptions: MasterOption[];
  brand_id: number;
  alias: string;
  youtube_video: {
    id?: number;
    value: string;
  };
};

export type RentalPropertyItem = {
  id: number;
  holding_image: string;
  name: string;
  address_line: string;
  active: boolean;
  total_adults: string;
  bedroom_count: number;
  slug: string;
};

export const NEW_SAMPLE_PROPERTY: RentalProperty = {
  id: -1,
  slug: "",
  location_category: -1,
  name: "",
  description: "",
  bedroom_count: 0,
  adults_count: 0,
  extra_adults_count: "",
  children_count: 0,
  extra_children_count: "",
  pools_count: 0,
  baths_count: 0,
  extra_adults_fare_currency: "INR",
  extra_adults_fare: "",
  extra_child_fare_currency: "INR",
  extra_child_fare: "",
  pet_friendly: false,
  rentable: false,
  publication_features: [],
  wheelchair_friendly: false,
  isprava_built: false,
  isprava_exclusive: false,
  trending_listed: false,
  active: false,
  upcoming: false,
  channel_manager_id: "",
  location: {
    address_line: "",
    city: "",
    state: "",
    country: "",
    google_location_url: "",
    latitude: "",
    longitude: "",
  },
  property_images: [],
  cancellation_policies: [],
  house_rules: [],
  amenities: [],
  nearby_places: [],
  bedrooms: [],
  moods: [],
  things_to_do: [],
  highlights: [],
  seo: {
    title: "",
    description: "",
    keywords: "",
    canonical: "",
  },
  homeowner_name: "",
  homeowner_email: "",
  homeowner_phone_no: "",
  contract_type: "",
  contract_rev_share_percentage: 0,
  contract_free_nights: 0,
  contract_effective_date: "",
  contract_expiry_date: "",
  security_deposit: 0,
  maintenance_days_before_checkin: 0,
  maintenance_days_after_checkout: 0,
  configOptions: [],
  brand_id: 0,
  alias: "",
  youtube_video: {
    value: "",
  },
};

import { useState } from "react";

function useLoading(initial: boolean = false) {
  const [loading, updateLoading] = useState<boolean>(initial),
    showLoading = updateLoading.bind(null, true),
    hideLoading = updateLoading.bind(null, false);

  return {
    loading,
    showLoading,
    hideLoading,
  };
}

export default useLoading;

import React, { createContext, ReactNode, useContext } from "react";

import { IStaffService } from "../types/services/staff-service";

const StaffServiceContext = createContext<IStaffService>(undefined as any);

interface StaffServiceProviderProps {
  service: IStaffService;
  children: ReactNode;
}

function StaffServiceProvider(props: StaffServiceProviderProps) {
  const { service, children } = props;

  return (
    <StaffServiceContext.Provider value={service}>
      {children}
    </StaffServiceContext.Provider>
  );
}

function useStaffService() {
  return useContext(StaffServiceContext);
}

export { StaffServiceProvider, useStaffService };

import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate, useSearchParams } from "react-router-dom";

import ISPRAVA_LOGO from "../../assets/isprava-white.svg";
import OI_BANNER from "../../assets/login-background.jpg";
import LOHONO_LOGO from "../../assets/lohono-white.svg";
import { useLoginService } from "../../contexts/login-service-context";
import { useMe } from "../../contexts/me-context";
import { useSecureState } from "../../contexts/secure-state-context";
import { useSnackbar } from "../../contexts/snackbar-provider";
import { useStaffService } from "../../contexts/staff-service-context";
import { Staff } from "../../types/staff";
import useLoginStyles from "./LoginStyle";

function Login() {
  const { isSecure, updateIsSecure } = useSecureState(),
    { updateMe } = useMe(),
    { getMe } = useStaffService(),
    { login } = useLoginService(),
    [qp] = useSearchParams(),
    push = useNavigate(),
    enqueueSnackbar = useSnackbar(),
    classNames = useLoginStyles(),
    [role, updateRole] = useState<string>(""),
    [googleAppClientId, updateGoogleAppClientId] = useState<string>(""),
    [showLoginButton, updateShowLoginButton] = useState<boolean>(false),
    onGetGoogleClientId = async () => {
      const response = await fetch("/google-app-client-id"),
        { status } = response;

      if (status !== 200) {
        return;
      }

      const data = await response.json();

      if (!data) {
        return;
      }

      updateGoogleAppClientId(data?.google_app_client_id || "");
    },
    onUpdateMe = (me: Staff) => {
      updateMe(me);

      updateIsSecure(true);

      /**
       * saving role in session storage for Agent Access
       */
      sessionStorage.setItem("loginRole", me.role_name ? me.role_name : "");
      sessionStorage.setItem("loginId", `${me.agent_id}`);
      if (me.role_name === "agent") {
        sessionStorage.setItem("agentNumber", `${me.phone_no}`);
      }

    },
    restoreSession = async () => {
      const { response: me, error } = await getMe();

      if (error || !me) {
        await onGetGoogleClientId();

        updateShowLoginButton(true);

        return;
      }

      onUpdateMe(me);
    },
    onLogin = async (googleSignIn: any) => {
      const {
        tokenId,
        profileObj: {
          //imageUrl,
          email,
          givenName,
          familyName,
        },
      } = googleSignIn,
        { response: me, error } = await login(
          tokenId,
          email,
          givenName,
          familyName,
        );

      if (error) {
        enqueueSnackbar(error.message, "error");

        return;
      }

      if (!me) {
        return;
      }

      onUpdateMe(me);
      updateRole(me.role);
    },
    onRedirect = () => {
      if (!isSecure) {
        return;
      }

      const intent = role === "Agent" ? "/rentals/trips" : qp.get("intent") ?? "/";

      push(intent, { replace: true });
    };

  useEffect(() => {
    onRedirect();
  }, [isSecure]);

  useEffect(() => {
    restoreSession();
  }, []);

  if (!showLoginButton) {
    return null;
  }

  return (
    <section className={classNames.login}>
      <div className={classNames.logos}>
        <img
          alt="lohono-logo"
          className={classNames.lohonoLogo}
          src={LOHONO_LOGO}
        />
        +
        <img
          alt="isprava-logo"
          className={classNames.ispravaLogo}
          src={ISPRAVA_LOGO}
        />
      </div>
      <div className={classNames.title}>Platform for Super Human</div>
      <Card className={classNames.loginCard}>
        <div className={classNames.loginContainer}>
          <div className={classNames.subtitle}>
            Login with your Google Account
          </div>
          <div className={classNames.loginButton}>
            {googleAppClientId && (
              <GoogleLogin
                buttonText="Login with Google"
                clientId={googleAppClientId}
                onFailure={console.log}
                onSuccess={onLogin}
              />
            )}
          </div>
        </div>
      </Card>
      <img
        alt="oi-banner"
        className={classNames.banner}
        src={OI_BANNER}
      />
    </section>
  );
}

export default Login;

import Button from "@mui/material/Button";
import clx from "classnames";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import BANNER_LOCK from "../../assets/isprava-lock.png";
import { useMe } from "../../contexts/me-context";
import { AccessControl } from "../../types/acl";
import useAccessControlGuardStyles from "./AccessControlGuardStyles";

interface AccessControlGuardProps {
  className?: string;
  ac: AccessControl | string;
  children: ReactNode;
  fallback?: ReactNode;
}

function AccessControlGuard(props: AccessControlGuardProps) {
  const { hasAccessTo } = useMe(),
    push = useNavigate(),
    handleBack = useCallback(
      () => push(-1),
      [push],
    ),
    classNames = useAccessControlGuardStyles(),
    { fallback, className, ac, children } = props,
    haveAccess = useMemo(() => hasAccessTo(ac), [hasAccessTo, ac]),
    containerClassName = clx(classNames.container, className);

  if (haveAccess) {
    return <>{children}</>;
  }

  if (fallback !== undefined) {
    return <>{fallback}</>;
  }

  return (
    <div className={containerClassName}>
      <img
        alt="not-found"
        className={classNames.banner}
        src={BANNER_LOCK}
      />
      <div className={classNames.title}>
        The star you are looking for is not in your galaxy
      </div>
      <div className={classNames.subtitle}>
        Please contact admin for further queries
      </div>
      <Button
        className={classNames.back}
        color="secondary"
        onClick={handleBack}
        variant="contained"
      >
        Back
      </Button>
    </div>
  );
}

export default AccessControlGuard;

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalTripPaymentService } from "../types/services/rental-trip-payment-service";

const RentalTripPaymentServiceContext = createContext<
  IRentalTripPaymentService
>(undefined as any);

interface RentalTripPaymentServiceProviderProps {
  service: IRentalTripPaymentService;
  children: ReactNode;
}

function RentalTripPaymentServiceProvider(
  props: RentalTripPaymentServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalTripPaymentServiceContext.Provider value={service}>
      {children}
    </RentalTripPaymentServiceContext.Provider>
  );
}

function useRentalTripPaymentService() {
  return useContext(RentalTripPaymentServiceContext);
}

export { RentalTripPaymentServiceProvider, useRentalTripPaymentService };

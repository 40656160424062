import React, { createContext, ReactNode, useContext } from "react";

import { IRentalForceBookApprovalService } from "../types/services/rental-force-book-approval-service";


const RentalForceBookApprovalServiceContext = createContext<
  IRentalForceBookApprovalService
>(undefined as any);

interface ForceBookApprovalServiceProviderProps {
  service: IRentalForceBookApprovalService;
  children: ReactNode;
}

function RentalForceBookApprovalServiceProvider(
  props: ForceBookApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalForceBookApprovalServiceContext.Provider value={service}>
      {children}
    </RentalForceBookApprovalServiceContext.Provider>
  );
}

function useRentalForceBookApprovalService() {
  return useContext(RentalForceBookApprovalServiceContext);
}

export { RentalForceBookApprovalServiceProvider, useRentalForceBookApprovalService };

import React, { createContext, ReactNode, useContext } from "react";

import { ITaskService } from "../types/services/task-service";

const TaskServiceContext = createContext<ITaskService>(undefined as any);

interface TaskServiceProviderProps {
  service: ITaskService;
  children: ReactNode;
}

function TaskServiceProvider(props: TaskServiceProviderProps) {
  const { service, children } = props;

  return (
    <TaskServiceContext.Provider value={service}>
      {children}
    </TaskServiceContext.Provider>
  );
}

function useTaskService() {
  return useContext(TaskServiceContext);
}

export { TaskServiceProvider, useTaskService };

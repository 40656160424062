import {useCallback, useEffect} from "react";

import {SipCall} from "../../services/sip";
import {useStaffService} from "../staff-service-context";

function useDisconnectOnUnload(call: SipCall) {
  const { updateSipStatus } = useStaffService();

  const handleUnload = useCallback(
    async () => {
      call.hangup();

      await updateSipStatus("logged_out");
    },
    [call],
  );

  useEffect(
    () => {
      window.addEventListener("beforeunload", handleUnload, {capture: true});

      return () => window.removeEventListener("beforeunload", handleUnload, {capture: true});
    },
    [handleUnload],
  );
}

export default useDisconnectOnUnload;

function parsePhoneNo(text: string) {
  const [first, second, third] = text.split(/(\+\d{2})\s?/g);

  const phoneCode = second || "+91";

  const phoneNo = third || first;

  const sanitizedPhoneNo = `${phoneCode} ${phoneNo}`;

  return {
    phoneCode: phoneCode,
    phoneNo: phoneNo,
    fullPhoneNo: sanitizedPhoneNo,
  };
}

export default parsePhoneNo;

import { ILoginService } from "../types/services/login-service";
import { Staff } from "../types/staff";
import { DELETE, FetchResult, POST } from "../utils/fetch";
import { staffFromAny } from "./staff-service";

type StaffResponse = {
  staff: any;
};

class LoginService implements ILoginService {
  async login(
    tokenId: string,
    staff_email: string,
    givenName: string,
    familyName: string,
  ): Promise<FetchResult<Staff>> {
    const loginPayload: any = {
        tokenId,
        email: staff_email,
        givenName,
        familyName,
      },
      url = "/api/v2/login",
      { response, error } = await POST<StaffResponse>(url, loginPayload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const staff = staffFromAny(response.staff);

    return {
      response: staff,
      error,
    };
  }

  async logout(): Promise<FetchResult<void>> {
    const url = "/api/v2/logout",
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: undefined,
      error: null,
    };
  }
}

export default LoginService;

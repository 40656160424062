import { Formik, FormikHelpers } from "formik";
import React from "react";

import { FormRender, OnFormSubmit, ValidateForm } from "./form-t";

const DEFAULT_FORM_SUBMIT: OnFormSubmit = () => {};

interface FormProps {
  defaultValue?: any;
  validateForm?: ValidateForm;
  onSubmit?: OnFormSubmit;
  className?: string;
  children: FormRender;
  id?: string;
  validationSchema?: any | (() => any);
}

function Form(props: FormProps) {
  const {
      id,
      defaultValue = {},
      validateForm,
      onSubmit = DEFAULT_FORM_SUBMIT,
      validationSchema,
      className,
      children,
    } = props,
    _onSubmit = async (values: any, form: FormikHelpers<any>) => {
      const formattedValues = validationSchema
        ? validationSchema.cast(values, { assert: false })
        : values;

      onSubmit(formattedValues, form);
    };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={defaultValue}
      onSubmit={_onSubmit}
      validate={validateForm}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, ...rest }) => (
        <form
          className={className}
          id={id}
          onSubmit={handleSubmit}
        >
          {children(rest)}
        </form>
      )}
    </Formik>
  );
}

export default Form;

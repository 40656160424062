import { FetchResult } from "../../utils/fetch";
import {
  RentalProperty,
  RentalPropertyConfig,
  RentalPropertyItem,
} from "../rental-property";

export type NewRentalProperty = {
  name: string;
  description?: string;
  location_category: number;
  bedroom_count?: number;
  adults_count?: number;
  extra_adults_count?: number;
  children_count?: number;
  pool_count?: number;
  baths_count?: number;
  extra_adults_fare_currency?: string;
  extra_adults_fare?: string;
  extra_child_fare_currency?: string;
  extra_child_fare?: string;
  pet_friendly?: boolean;
  rentable?: boolean;
  wheelchair_friendly?: boolean;
  isprava_built?: boolean;
  isprava_exclusive?: boolean;
  trending_listed?: boolean;
  active?: boolean;
  channel_manager_id?: string;
  publication_features?: string[];
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_canonical?: string;
  homeowner_name: string;
  homeowner_email: string;
  homeowner_phone_no: string;
  contract_type: string;
  contract_rev_share_percentage: number;
  contract_free_nights: number;
  contract_effective_date: string;
  contract_expiry_date: string;
  security_deposit: number;
  maintenance_days_before_checkin: number;
  maintenance_days_after_checkout: number;
  rental_staff_id: number;
  maintenance_staff_id: number;
  guest_relation_staff_id: number;
  brand_id: number;
  alias: string;
  youtube_video: {
    id?: number;
    value: string;
  };
};

export const NEW_PROPERTY_ADD_KEYS = [
  "name",
  "description",
  "location_category",
  "bedroom_count",
  "adults_count",
  "extra_adults_count",
  "children_count",
  "extra_children_count",
  "pools_count",
  "baths_count",
  "extra_adults_fare_currency",
  "extra_adults_fare",
  "extra_child_fare",
  "pet_friendly",
  "rentable",
  "wheelchair_friendly",
  "isprava_built",
  "isprava_exclusive",
  "trending_listed",
  "active",
  "channel_manager_id",
  "address_line",
  "city",
  "state",
  "google_location_url",
  "latitude",
  "longitude",
  "upcoming",
  "security_deposit",
  "maintenance_days_before_checkin",
  "maintenance_days_after_checkout",
  "youtube_video_id",
];

export interface IRentalPropertyService {
  listProperties(
    location: string,
    rooms: string,
    guest_capacity: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalPropertyItem[]>>;

  addNewProperty(property: NewRentalProperty): Promise<FetchResult<string>>;

  getProperty(property_slug: string): Promise<FetchResult<RentalProperty>>;

  updateProperty(
    property_slug: string,
    property: Partial<RentalProperty>,
  ): Promise<FetchResult<void>>;

  listConfigs(
    property_slug: string,
  ): Promise<FetchResult<RentalPropertyConfig[]>>;

  updateConfig(
    property_slug: string,
    config_id: number,
    bedroom_count: number,
    active: boolean,
  ): Promise<FetchResult<void>>;

  addConfig(
    property_slug: string,
    bedroom_count: number,
    active: boolean,
  ): Promise<FetchResult<void>>;
}

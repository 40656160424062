import clx from "classnames";
import React, {useCallback} from "react";

import {useMe} from "../../../contexts/me-context";
import {useSip} from "../../../contexts/sip-context/sip-context";
import {useSnackbar} from "../../../contexts/snackbar-provider";
import {useStaffService} from "../../../contexts/staff-service-context";
import promptMicPermissions from "../../../utils/prompt-mic-permissions";
import useSipConnectStyles from "./SipConnectStyles";

function SipConnect() {
  const enqueueSnackbar = useSnackbar();

  const {me} = useMe();

  const { updateSipStatus } = useStaffService();

  const {sip, onSipConnect, onSipDisconnect} = useSip();

  const handleConnect = useCallback(
    async () => {
      if (!me?.sipCredentials) {
        enqueueSnackbar("Sip credential doesn't exist\nContact admin!", "error");

        return;
      }

      if (sip) {
        return;
      }

      try {
        const mediaStream = await promptMicPermissions();

        if (!mediaStream) {
          enqueueSnackbar("mic permission not provided", "error");

          return;
        }

        const {sipCredentials} = me;

        if (!sipCredentials) {
          return;
        }

        const sip = await onSipConnect(sipCredentials);

        if (!sip.isRegistered()) {
          await sip.stop();

          sip.unregister();

          enqueueSnackbar("unable to connect via sip", "error");

          return;
        }

        const { error } = await updateSipStatus("available");

        if (error) {
          await sip.stop();

          sip.unregister();

          enqueueSnackbar(error.message, "error");

          return;
        }

        enqueueSnackbar("sip connected", "success");
      } catch (e: any) {
        enqueueSnackbar(e.message, "error");

        return;
      }
    },
    [onSipConnect, enqueueSnackbar, updateSipStatus, me],
  );

  const handleDisconnected = useCallback(
    async () => {
      if (!me?.sipCredentials) {
        enqueueSnackbar("Sip credential doesn't exist\nContact admin!", "error");

        return;
      }

      if (!sip) {
        return;
      }

      try {
        await sip.stop();

        await onSipDisconnect();

        enqueueSnackbar("sip disconnected", "success");

        const { error } = await updateSipStatus("logged_out");

        if (error) {
          enqueueSnackbar(error.message, "error");

          return;
        }
      } catch (e: any) {
        enqueueSnackbar(e.message, "error");

        return;
      }
    },
    [onSipDisconnect, enqueueSnackbar, updateSipStatus, me],
  );

  const isConnected = sip?.isRegistered() ?? false;

  const clxs = useSipConnectStyles();

  const ccx = clx("material-icons", clxs.container);

  if (!me?.sipCredentials) {
    return null;
  }

  return (
    <div
      className={ccx}
      onClick={isConnected ? handleDisconnected : handleConnect}
      title={isConnected ? "Go Offline" : "Go Online"}
    >
      {isConnected ? "headset_mic" : "headset_off"}
    </div>
  );
}

export default SipConnect;

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalPaymentApprovalService } from "../types/services/rental-payment-approval-service";

const RentalPaymentApprovalServiceContext = createContext<IRentalPaymentApprovalService>(
  undefined as any,
);

interface RentalPaymentApprovalServiceProviderProps {
  service: IRentalPaymentApprovalService;
  children: ReactNode;
}

function RentalPaymentApprovalServiceProvider (
  props: RentalPaymentApprovalServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalPaymentApprovalServiceContext.Provider value={service}>
      {children}
    </RentalPaymentApprovalServiceContext.Provider>
  );
}

function useRentalPaymentApprovalService () {
  return useContext(RentalPaymentApprovalServiceContext);
}

export { RentalPaymentApprovalServiceProvider, useRentalPaymentApprovalService };

import { FormRenderProps } from "./form-t";

function getError(
  formProps: FormRenderProps,
  key: string,
  checkTouch: boolean = true,
) {
  const {
    errors: { [key]: error },
    touched: { [key]: touched },
  } = formProps;

  if (checkTouch && !touched) {
    return null;
  }

  return error || null;
}

export default getError;

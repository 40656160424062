import React, { createContext, ReactNode, useContext } from "react";

import { IOutboundOpportunityService } from "../types/services/outbound-opportunity-service";

const OutboundOpportunityServiceContext = createContext<
  IOutboundOpportunityService
>(undefined as any);

interface OutboundOpportunityServiceProviderProps {
  service: IOutboundOpportunityService;
  children: ReactNode;
}

function OutboundOpportunityServiceProvider(
  props: OutboundOpportunityServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <OutboundOpportunityServiceContext.Provider value={service}>
      {children}
    </OutboundOpportunityServiceContext.Provider>
  );
}

function useOutboundOpportunityService() {
  return useContext(OutboundOpportunityServiceContext);
}

export { OutboundOpportunityServiceProvider, useOutboundOpportunityService };

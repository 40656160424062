import { createTheme, StyledEngineProvider, Theme,ThemeProvider } from "@mui/material";
import { blueGrey, teal } from "@mui/material/colors";
import React, { ReactNode } from "react";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const customTheme = createTheme({
  palette: {
    primary: {
      ...blueGrey,
      main: blueGrey["900"],
    },
    secondary: {
      ...teal,
      main: teal["500"],
    },
  },
});

interface CustomMaterialThemeProps {
  children: ReactNode;
}

function CustomMaterialTheme(props: CustomMaterialThemeProps) {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={customTheme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CustomMaterialTheme;

import Card from "@mui/material/Card";
import NativeModal from "@mui/material/Modal";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  ManagedModalProps,
  ModalContextState,
  ModalState,
  Resolve,
} from "./modal-t";
import useModalStyles from "./ModalStyles";

const DEFAULT_MMP: ManagedModalProps = { children: null };

const DEFAULT_MCS: ModalContextState = {
  id: "",
  data: undefined,
  updateModalProps: () => null,
  onShowModal: () => null,
  onDismissModal: () => null,
};

const ModalContext = createContext<ModalContextState>(DEFAULT_MCS);

interface ModalProviderProps {
  children: ReactNode;
}

function ModalProvider(props: ModalProviderProps) {
  const { children } = props,
    [modalProps, updateModalProps] = useState<ManagedModalProps>(DEFAULT_MMP),
    [modalState, updateModalState] = useState<ModalState>({ id: "" }),
    { children: modalChildren, ...rest } = modalProps,
    { id, resolve, data } = modalState,
    classNames = useModalStyles(),
    onShowModal = (modalId: string, resolve?: Resolve, data?: any) => {
      updateModalState({
        id: modalId,
        resolve: resolve,
        data: data,
      });
    },
    onDismissModal = (data: any) => {
      updateModalState({ id: "", resolve: undefined });
      updateModalProps(DEFAULT_MMP);
      if (resolve) {
        resolve(data);
      }
    },
    value: ModalContextState = {
      id: id,
      data: data,
      updateModalProps: updateModalProps,
      onShowModal: onShowModal,
      onDismissModal: onDismissModal,
    };

  return (
    <ModalContext.Provider value={value}>
      <>
        {children}
        <NativeModal
          {...rest}
          onClose={onDismissModal.bind(null, null)}
          open={Boolean(id).valueOf()}
        >
          <Card className={classNames.modalCard}>{modalChildren}</Card>
        </NativeModal>
      </>
    </ModalContext.Provider>
  );
}

export interface ModalProps extends Omit<ManagedModalProps, "children"> {
  id: string;
  children: (onClose: Resolve, data?: any) => ReactNode;
}


function Modal(props: ModalProps) {
  const { id, children, ...modalProps } = props,
    { id: activeId, data, updateModalProps, onDismissModal } = useContext(
      ModalContext,
    );

  useEffect(() => {
    if (id !== activeId) {
      return;
    }
    updateModalProps({
      ...modalProps,
      children: children(onDismissModal, data),
    });
  }, [id, activeId]);

  return null;
}

function useModal() {
  const { id, onShowModal } = useContext(ModalContext),
    showModal = async (modalId: string, data?: any) => {
      if (id) {
        return null;
      }
      return new Promise<any>(resolve => onShowModal(modalId, resolve, data));
    };

  return { showModal };
}

export default Modal;

export { useModal, ModalProvider };

import makeStyles from "@mui/styles/makeStyles";

const useRentalTripAddStyles = makeStyles({
  divider: {
    margin: "0 8px",
  },
  contentWide: {
    "@media (min-width: 768px)": {
      minWidth: "800px",
    },
  },
  container: {
    display: "grid",
    "@media (max-width: 768px)": {
      gridTemplate: "auto/1fr",
      gridGap: "16px",
      maxHeight: "600px",
      overflow: "auto",
      paddingTop: "8px",
    },
    "@media (min-width: 768px)": {
      gridTemplate: "auto/1fr 280px",
    },
  },
  fields: {
    display: "grid",
    gridGap: "16px",
    "@media (max-width: 768px)": {
      gridTemplate: "auto/1fr",
    },
    "@media (min-width: 768px)": {
      gridTemplate: "auto/1fr 1fr",
      alignContent: "flex-start",
    },
  },
  decorated: {
    "@media (min-width: 768px)": {
      paddingRight: "16px",
      borderRight: "1px solid #eeeeee",
    },
  },
  message: {
    "@media (min-width: 768px)": {
      gridColumn: "span 2",
    },
  },
  suggestionContainer: {
    display: "grid",
    gridTemplate: "auto auto 1fr/1fr",
    gridGap: "16px",
    margin: "-4px",
    "@media (max-width: 768px)": {
      padding: "0 4px",
    },
    "@media (min-width: 768px)": {
      paddingLeft: "16px",
    },
  },
  subtitle: {
    color: "#b0bec5",
  },
  spacer: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  rules: {
    background: "#fff8e1",
    padding: "28px 24px 32px 42px",
    marginBottom: "-8px",
  },
  save: {
    display: "block",
    margin: "0 8px 8px auto",
  },
  saveInner: {
    "@media (min-width: 768px)": {
      gridColumn: "2",
    },
  },
  phoneCode: {
    border: "none",
    background: "none",
    color: "currentColor",
    borderRight: "1px solid lightgray",
    width: "70px",
    height: "1.1876em",
    padding: "0 16px 0 0",
    margin: "0 16px 0 0",
    fontSize: "1em",
    textAlign: "right",
    "&:focus": {
      outline: "none",
    },
  },
});

export default useRentalTripAddStyles;

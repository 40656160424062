import React, { createContext, ReactNode, useContext } from "react";

import { ICallService } from "../types/services/call-service";

const CallServiceContext = createContext<ICallService>(undefined as any);

interface CallServiceProviderProps {
  service: ICallService;
  children: ReactNode;
}

function CallServiceProvider(props: CallServiceProviderProps) {
  const { service, children } = props;

  return (
    <CallServiceContext.Provider value={service}>
      {children}
    </CallServiceContext.Provider>
  );
}

function useCallService() {
  return useContext(CallServiceContext);
}

export { CallServiceProvider, useCallService };

import { Pagination } from "../types/pagination";
import { RentalInvoiceApprovalItem } from "../types/rental-invoice";
import { IRentalInvoiceApprovalService } from "../types/services/rental-invoice-approval-service";
import { FetchResult, GET, POST } from "../utils/fetch";

class RentalInvoiceApprovalService implements IRentalInvoiceApprovalService {
  async getInvoiceApprovals (
    invoice_type?: string,
    invoice_status?: string,
    start_date?: string,
    end_date?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalInvoiceApprovalItem[]>> {
    const qp = new URLSearchParams();

    if (invoice_type) {
      qp.set("invoice_type", invoice_type);
    }

    if (search) {
      qp.set("query", search);
    }

    if (start_date) {
      qp.set("start_date", start_date);
    }

    if (end_date) {
      qp.set("end_date", end_date);
    }

    if (invoice_status) {
      qp.set("status", invoice_status);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/invoices";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const {error, response} = await GET<InvoiceListResponse>(url);

    if (error) {
      return {
        error: null,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown Error"),
        response: null,
      };
    }

    const properties = response.invoices.map(each => {
      const {
          id,
          number,
          generatable,
          regeneratable,
          cancellable,
          resolvable,
          questionable,
          updated_at,
          invoice_type,
          invoice_for,
          reason,
          comments,
          amount,
          unpaid_amount,
          status,
          opportunity_slug,
          reservation_slug,
          check_in,
          check_out,
          billing_name,
          property_name,
          // name,
          poc_exec,
          currency_symbol,
          errors,
        } = each,
        property: RentalInvoiceApprovalItem = {
          id: id ?? -1,
          billing_amount: amount ?? 0,
          billing_name: billing_name ?? "",
          can_cancel: cancellable ?? false,
          can_generate: generatable ?? false,
          can_raise_question: questionable ?? false,
          can_resolve: resolvable ?? false,
          can_regenerate: regeneratable ?? false,
          checkin_date: check_in ?? "",
          checkout_date: check_out ?? "",
          created_at: updated_at ?? "",
          currency_symbol: currency_symbol ?? "",
          errors: errors ?? [],
          invoice_id: number ?? "",
          invoice_status: status ?? "",
          invoice_type: invoice_type ?? "",
          opportunity_slug: opportunity_slug ?? "",
          unpaid_amount: unpaid_amount ?? 0,
          poc_name: poc_exec ?? "",
          property_name: property_name ?? "",
          reservation_slug: reservation_slug ?? "",
          invoice_for: invoice_for ?? "",
          reason: reason ?? "",
          comments: comments ?? "",
        };

      return property;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: properties,
    };
  }

  async cancelInvoice (
    id: number,
    reason: string,
    comments: string,
  ): Promise<FetchResult<void>> {
    const url = "/api/v2/invoices/cancel",
      payload = {
        invoice_id: id,
        reason: reason,
        remarks: comments,
      },
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async generateInvoice (id: number): Promise<FetchResult<void>> {
    const url = "/api/v2/invoices/generate",
      payload = { invoice_id: id },
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async questionInvoice (
    id: number,
    reason: string,
    comments: string,
  ): Promise<FetchResult<void>> {
    const url = "/api/v2/invoices/question",
      payload = { invoice_id: id, reason: reason, comments: comments },
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async resolveInvoice (id: number): Promise<FetchResult<void>> {
    const url = "/api/v2/invoices/resolve",
      payload = { invoice_id: id },
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async refreshInvoice (id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/invoices/${id}/refresh`,
      { error } = await POST<void>(url,'');

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

}

export default RentalInvoiceApprovalService;

type InvoiceListResponse = {
  invoices: any[];
  paginate: Pagination;
};

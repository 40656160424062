import clx from "classnames";
import React, { useCallback, useMemo } from "react";
import { useLocation,useNavigate } from "react-router-dom";

import LOHONO_LOGO_WHITE from "../../../assets/lohono-white.svg";
import useStartActionStyles from "./StartActionStyles";

function StartAction() {
  const location = useLocation(),
    push = useNavigate(),
    goBack = useCallback(
      () => push(-1),
      [push],
    ),
    classNames = useStartActionStyles(),
    isRoot = useMemo(
      () => {
        const pathname = location.pathname;

        return pathname === "/";
      },
      [location.pathname],
    ),
    goBackClassName = clx("material-icons", classNames.navigationIcon);

  if (isRoot) {
    return (
      <img
        alt="lohono"
        className={classNames.brandLogo}
        src={LOHONO_LOGO_WHITE}
      />
    );
  }

  return (
    <span
      className={goBackClassName}
      onClick={goBack}
    >
      chevron_left
    </span>
  );
}

export default StartAction;

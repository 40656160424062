import React, { Suspense, useMemo } from "react";
import { Route, Routes as NativeRoutes } from "react-router-dom";

import { Page, PageTemplate } from "../../types/page-template";
import LoadingScreen from "../LoadingScreen";
import SecureRoute from "./SecureRoute";

interface RoutesProps {
  pages: PageTemplate[];
  intent?: string;
}

function Routes (props: RoutesProps) {
  const { pages, intent } = props,
    routes = useMemo(
      () =>
        pages.reduce((compiled, each) => {
          if ("pages" in each) {
            return [...compiled, ...each.pages];
          }
          return [...compiled, each];
        }, [] as Page[]),
      [pages],
    );

  return (
    <Suspense fallback={<LoadingScreen/>}>
      <NativeRoutes>
        {routes.map(each => {
          const { path, secure = false, redirect, Component } = each;

          return (
            <Route
              element={(
                <SecureRoute
                  intent={intent}
                  isPrivate={secure}
                  redirect={redirect}
                >
                  <Component/>
                </SecureRoute>
              )}
              key={path}
              path={path}
            />
          );
        })}
      </NativeRoutes>
    </Suspense>
  );
}

export default Routes;

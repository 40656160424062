import { Pagination } from "../types/pagination";
import { ForceBookApprovalRequestItem } from "../types/rental-force-book-approval";
import { IRentalForceBookApprovalService } from "../types/services/rental-force-book-approval-service";
import { FetchResult, GET, POST } from "../utils/fetch";

type ApprovalsListResponse = {
  approvals: any[];
  paginate: Pagination;
};

type ApprovalResponse = {
  property: any;
};

class RentalForceBookApprovalService implements IRentalForceBookApprovalService {
  async getApprovalRequests(
    staff?: string,
    property?: string[],
    location?: string,
    status?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<ForceBookApprovalRequestItem[]>> {
    const qp = new URLSearchParams();
    qp.set("approval_type", "booking_confirmation");
    
    if (staff) {
      qp.set("staff_id", staff);
    }

    if (property?.length) {
      property.forEach((each) => qp.append("rental_property_ids[]", each));
    }

    if (search) {
      qp.set("query", search);
    }

    if (location) {
      qp.set("location_id", location);
    }

    if (status) {
      qp.set("status", status);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = "/api/v2/rental/approvals";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<ApprovalsListResponse>(url);

    if (error) {
      return {
        error: null,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown Error"),
        response: null,
      };
    }

    const properties = response.approvals.map(each => {
      const {
          id,
          opportunity,
          amount_details,
          approver_id,
          approver_name,
          poc_exec_name,
          check_in,
          check_out,
          property_name,
          guest_name,
          created_at,
          status,
        } = each,
        nameSplits = (guest_name || "").split(" "),
        property: ForceBookApprovalRequestItem = {
          id: id,
          opportunity_slug: opportunity.slug || "",
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          property_name: property_name || "",
          checkin_date: check_in || "",
          checkout_date: check_out || "",
          total_amount_paid: amount_details.total_amount_paid || 0,
          total_booking_amount: amount_details.total_booking_amount || 0,
          poc_name: poc_exec_name || "",
          requested_date: created_at || "",
          authorizer_id: approver_id || -1,
          authorizer_name: approver_name || "",
          status: status || "",
        };
      return property;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: properties,
    };
  }

  async approveRequest(
    id: number,
    approvalType: string,
    ): Promise<FetchResult<void>> {
    const payload = {
			approval_type: approvalType,
			},
       url = `/api/v2/rental/approvals/${id}/accept`,
      { error } = await POST<ApprovalResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async rejectRequest(
    id: number,
    approvalType: string,
    ): Promise<FetchResult<void>> {
      const payload = {
        approval_type: approvalType,
        },
         url = `/api/v2/rental/approvals/${id}/reject`,
        { error } = await POST<ApprovalResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default RentalForceBookApprovalService;

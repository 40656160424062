import React, { createContext, ReactNode, useContext } from "react";

import { ICustomerService } from "../types/services/customer-service";

const CustomerServiceContext = createContext<ICustomerService>(
  undefined as any,
);

interface CustomerServiceProviderProps {
  service: ICustomerService;
  children: ReactNode;
}

function CustomerServiceProvider(props: CustomerServiceProviderProps) {
  const { service, children } = props;
  return (
    <CustomerServiceContext.Provider value={service}>
      {children}
    </CustomerServiceContext.Provider>
  );
}

function useCustomerService() {
  return useContext(CustomerServiceContext);
}

export { CustomerServiceProvider, useCustomerService };

import { TextFieldProps } from "@mui/material/TextField";
import React from "react";

import ignoreProps from "../../utils/ignore-props";
import Autocomplete, { Option } from "../Autocomplete";
import useStaff from "./useStaff";

interface StaffSelectProps {
  value?: string;
  verticals?: string[];
	optionsHook?: OptionsHook;
}

function StaffSelect(props: StaffSelectProps & NativeTextFieldProps) {
  const {
      id,
      name,
      onChange,
      value,
      disabled,
      className,
      optionsHook: useOptionsHook = useStaff,
      ...rest
    } = props,
    textFieldProps = ignoreProps(rest, IGNORE_PROPS),
    { getOptionById, getOptions } = useOptionsHook(props);

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      getOptionForValue={getOptionById}
      getOptions={getOptions}
      id={id}
      name={name}
      onChange={onChange}
      textFieldProps={textFieldProps}
      value={value}
    />
  );
}

export default StaffSelect;

const IGNORE_PROPS = new Set([
  "getStaffById",
  "updateStaff",
  "getStaff",
  "getAgentOptions",
  "getAgentOptionById",
]);

type NativeTextFieldProps = Omit<TextFieldProps, "value">;

type OptionsHook = (props?: any) => OptionHooksReturn;

type OptionHooksReturn = {
	getOptionById: (value: string) => Promise<Option>;
	getOptions: (value: string) => Promise<Option[]>;
};

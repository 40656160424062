import { useState } from "react";

function useCollapsed(defaultCollapsed: boolean = true) {
  const [collapsed, toggleCollapse] = useState(!defaultCollapsed);

  return {
    collapsed,
    toggleCollapse,
  };
}

export default useCollapsed;

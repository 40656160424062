import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

import useLoadingScreenStyles from "./LoadingScreenStyles";

function LoadingScreen() {
  const loadingScreenClasses = useLoadingScreenStyles();

  return (
    <div className={loadingScreenClasses.loading}>
      <CircularProgress
        color="secondary"
        size="40px"
      />
    </div>
  );
}

export default LoadingScreen;

import React from "react";

import {useSip} from "../../contexts/sip-context/sip-context";
import CallReceiver from "../CallReceiver";

function CallManager() {
  const {sip} = useSip();

  if (!sip?.call) {
    return null;
  }

  return <CallReceiver call={sip?.call}/>;
}

export default CallManager;

import { TextFieldProps } from "@mui/material";
import React from "react";

import { useDropdownOptionsService } from "../../contexts/dropdown-options-service-context";
import ignoreProps from "../../utils/ignore-props";
import Autocomplete, { Option } from "../Autocomplete";

const IGNORE_PROPS = new Set(["getStaffOptions"]);

type NativeTextFieldProps = Omit<TextFieldProps, "value">;

interface HOReferralSelectProps {
  value?: string;
  verticals?: string[];
}


function HOReferralSelect(props: HOReferralSelectProps & NativeTextFieldProps) {
  const { getHomeownerOptionById, getHomeownerOptions } = useDropdownOptionsService(),
    { verticals, id, name, onChange, value, disabled, ...rest } = props,
    textFieldProps = ignoreProps(rest, IGNORE_PROPS),
    onGetHomeownerById = async (value: number) => {
      const { error, response: home_owners } = await
        getHomeownerOptionById(
          value.toString(),
        );
      if (error || !home_owners) {
        return null;
      }

      const { name, value: id } = home_owners,
        option: Option = {
          value: id.toString(),
          label: name,
        };

      return option;
    },
    onGetHomeownerOptions = async (value: string) => {
      const { error, response: HOReferral } = await getHomeownerOptions(
        verticals,
        value,
      );
      if (error || !HOReferral) {
        return [] as Option[];
      }
      const formatted: Option[] = HOReferral.map(each => {
        const { name, value } = each;
        const formatted: Option = {
          value: value,
          label: name,
        };

        return formatted;
      });

      return formatted;
    };

  return (
    <Autocomplete
      disabled={disabled}
      getOptionForValue={onGetHomeownerById}
      getOptions={onGetHomeownerOptions}
      id={id}
      name={name}
      onChange={onChange}
      textFieldProps={textFieldProps}
      value={value}
    />
  );
}

export default HOReferralSelect;
import {
  Customer,
  CustomerCreditHistory,
  CustomerCreditItem,
  CustomerDocument,
  CustomerDuplicate,
  CustomerItem,
  CustomerLLPHistory,
  CustomerOpportunity,
  CustomerRelationship,
  IspravaBillingDetails,
} from "../types/customer";
import { EnquiryItem } from "../types/enquiry";
import { IndexedValue } from "../types/indexed-value";
import { Pagination } from "../types/pagination";
import { ICustomerRelationService } from "../types/services/customer-relation-service";
import { ICustomerService } from "../types/services/customer-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";
import parseIndexedEmails from "../utils/parse-indexed-emails";
import parseIndexedPhoneNos from "../utils/parse-indexed-phone-nos";

class CustomerService implements ICustomerService, ICustomerRelationService {
  async getCustomers(
    verified?: boolean,
    search?: string,
    name?: string,
    email?: string,
    phone_no?: string,
    country_code?: string,
    page?: string,
    page_size?: string,
    isRental?: boolean,
  ): Promise<FetchResult<CustomerItem[]>> {
    const qp = new URLSearchParams();

    if (verified !== undefined) {
      qp.set("verified", verified.toString());
    }

    if (search) {
      qp.set("query", search);
    }

    if (name) {
      qp.set("name", name);
    }

    if (email) {
      qp.set("email", email);
    }

    if (phone_no) {
      qp.set("mobile", phone_no);
    }

    if (country_code) {
      qp.set("country_code", country_code);
    }

    if (page) {
      qp.set("page", page);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    if (isRental) {
      qp.set("rental", "true");
    }

    const marshalled = qp.toString();

    let url = "/api/v2/contacts";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<CustomerListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.contacts.map((each) => {
      const {
          id,
          name,
          emails,
          mobiles,
          verified,
          gender,
          loyalty_points_available,
          referred_by_user_id,
          referred_by_tier_name,
          tier_name,
          repeat_user,
        } = each,
        nameSplits = (name || "").split(" "),
        customer: CustomerItem = {
          id,
          first_name: nameSplits[0],
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          emails: parseIndexedEmails(emails),
          phone_nos: parseIndexedPhoneNos(mobiles),
          verified: verified || false,
          gender: gender || "",
          points: loyalty_points_available,
          referred_by_id: referred_by_user_id,
          referred_by_tier: referred_by_tier_name,
          repeat_user: repeat_user,
          tier: tier_name,
        };
      return customer;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async getCustomer(
    customerId: number,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<Customer>> {
    const url = new URL(
      `/api/v2/contacts/${customerId}`,
      window.location.origin,
    );

    if (vertical) {
      url.searchParams.set("vertical", vertical);
    }

    if (opportunity_slug) {
      url.searchParams.set("opportunity_slug", opportunity_slug);
    }

    const { error, response } = await GET<CustomerResponse>(url.toString());

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        address,
        children_count,
        children_names,
        city,
        company,
        country,
        designation,
        dislikes,
        dob,
        other_emails,
        gender,
        id,
        likes,
        marital_status,
        other_mobiles,
        name,
        other_family_details,
        other_professional_details,
        profession,
        sector,
        social_media_profiles,
        state,
        verified,
        repeat_user,
        wife_name,
        loyalty_points_available,
        tier_name,
        anniversary_date,
        similar_contacts_count,
        reservation_billing_details = {} as any,
        add_on_services_billing_details = {} as any,
        credits_available = 0,
        primary_email_details,
        primary_mobile_details,
        referred_by_tier_name,
      } = response.contact,
      nameSplits = (name || "").split(" "),
      wifeNameSplits = (wife_name || "").split(" "),
      sanitizedEmails = parseIndexedEmails(other_emails),
      sanitizedPhoneNos = parseIndexedPhoneNos(other_mobiles),
      primaryEmail: IndexedValue = {
        id: primary_email_details?.id ?? -1,
        value: primary_email_details?.email,
      },
      primaryPhoneNo: IndexedValue = {
        id: primary_mobile_details?.id ?? -1,
        value: `${primary_mobile_details?.country_code ?? ""} ${primary_mobile_details?.mobile ?? ""}`,
      },
      customer: Customer = {
        id: id,
        first_name: nameSplits[0],
        last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
        emails: sanitizedEmails,
        primary_email: primaryEmail,
        phone_nos: sanitizedPhoneNos,
        primary_phone_no: primaryPhoneNo,
        gender: gender || "",
        date_of_birth: dob || "",
        loyalty_points_available: loyalty_points_available || "",
        tier_name: tier_name || "",
        profession: profession || "",
        sector: sector || "",
        company_name: company || "",
        designation: designation || "",
        profession_note: other_professional_details || "",
        marital_status: marital_status || "",
        anniversary_date: anniversary_date || "",
        spouse_first_name: wifeNameSplits[0] || "",
        spouse_last_name:
          wifeNameSplits.length > 1 ? wifeNameSplits.slice(1).join(" ") : "",
        children_count: children_count,
        children_names: children_names,
        family_note: other_family_details,
        likes: likes,
        dislikes: dislikes,
        verified: verified || false,
        repeat_user: repeat_user || false,
        referred_by_tier: referred_by_tier_name,
        address: address || "",
        city: city || "",
        state: state || "",
        country: country || "",
        social_media_profiles: social_media_profiles || [],
        duplicates: similar_contacts_count || 0,
        reservation_billing_id: reservation_billing_details.id || -1,
        reservation_billing_name: reservation_billing_details.name || "",
        reservation_billing_gst_in: reservation_billing_details.gstin || "",
        reservation_billing_pan: reservation_billing_details.pan || "",
        reservation_billing_address: reservation_billing_details.address || "",
        reservation_billing_city: reservation_billing_details.city || "",
        reservation_billing_state: reservation_billing_details.state || "",
        reservation_billing_country: reservation_billing_details.country || "",
        reservation_billing_pincode: reservation_billing_details.pincode || "",
        reservation_billing_bank_account_details: reservation_billing_details.bank_account_details || "",
        reservation_billing_gst_certificate: reservation_billing_details?.gstin_certificate?.link || "",
        reservation_billing_gst_certificate_id: reservation_billing_details?.gstin_certificate?.id,
        add_on_services_billing_id: add_on_services_billing_details.id || -1,
        add_on_services_billing_name: add_on_services_billing_details.name || "",
        add_on_services_billing_gst_in: add_on_services_billing_details.gstin || "",
        add_on_services_billing_pan: add_on_services_billing_details.pan || "",
        add_on_services_billing_address: add_on_services_billing_details.address || "",
        add_on_services_billing_city: add_on_services_billing_details.city || "",
        add_on_services_billing_state: add_on_services_billing_details.state || "",
        add_on_services_billing_country: add_on_services_billing_details.country || "",
        add_on_services_billing_pincode: add_on_services_billing_details.pincode || "",
        add_on_services_billing_bank_account_details: add_on_services_billing_details.bank_account_details || "",
        add_on_services_billing_gst_certificate: add_on_services_billing_details?.gstin_certificate?.link || "",
        add_on_services_billing_gst_certificate_id: add_on_services_billing_details?.gstin_certificate?.id,
        credit_balance: credits_available,
      };

    return {
      error: null,
      response: customer,
    };
  }

  async updateCustomer(
    customer_id: number,
    customer_data: Partial<Customer>,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if ("first_name" in customer_data || "last_name" in customer_data) {
      let name = customer_data.first_name || "";

      if (customer_data.last_name) {
        name = `${name} ${customer_data.last_name}`;
      }

      payload.set("name", name);
    }

    if (
      "spouse_first_name" in customer_data ||
      "spouse_last_name" in customer_data
    ) {
      let wifeName = customer_data.spouse_first_name || "";

      if (customer_data.spouse_last_name) {
        wifeName = `${wifeName} ${customer_data.spouse_last_name}`;
      }

      payload.set("wife_name", wifeName);
    }

    [
      {
        fromKey: "gender",
        toKey: "gender",
      },
      {
        fromKey: "date_of_birth",
        toKey: "dob",
      },
      {
        fromKey: "age",
        toKey: "age",
      },
      {
        fromKey: "profession",
        toKey: "profession",
      },
      {
        fromKey: "sector",
        toKey: "sector",
      },
      {
        fromKey: "company_name",
        toKey: "company",
      },
      {
        fromKey: "designation",
        toKey: "designation",
      },
      {
        fromKey: "profession_note",
        toKey: "other_professional_details",
      },
      {
        fromKey: "gstin",
        toKey: "gstin",
      },
      {
        fromKey: "marital_status",
        toKey: "marital_status",
      },
      {
        fromKey: "anniversary_date",
        toKey: "anniversary_date",
      },
      {
        fromKey: "children_count",
        toKey: "children_count",
      },
      {
        fromKey: "children_names",
        toKey: "children_names",
      },
      {
        fromKey: "family_note",
        toKey: "other_family_details",
      },
      {
        fromKey: "likes",
        toKey: "likes",
      },
      {
        fromKey: "dislikes",
        toKey: "dislikes",
      },
      {
        fromKey: "verified",
        toKey: "verified",
      },
      {
        fromKey: "address",
        toKey: "address",
      },
      {
        fromKey: "city",
        toKey: "city",
      },
      {
        fromKey: "state",
        toKey: "state",
      },
      {
        fromKey: "country",
        toKey: "country",
      },
      {
        fromKey: "social_media_profiles",
        toKey: "social_media_profiles",
      },
    ].forEach((each) => {
      const { fromKey, toKey } = each;

      const value = (customer_data as any)[fromKey];

      if (value === undefined) {
        return;
      }

      payload.set(toKey, value);
    });

    if (customer_data.emails) {
      customer_data.emails.forEach((each, i) => {
        const { id, value } = each;

        payload.set(`email_attrs[${i}][email]`, value);

        if (id !== undefined) {
          payload.set(`email_attrs[${i}][id]`, id.toString());
        }
      });
    }

    if (customer_data.primary_email?.value) {
      const id = customer_data.primary_email?.id;

      payload.set(
        "primary_email_attributes[email]",
        customer_data.primary_email.value,
      );

      if (id && id > 0) {
        payload.set("primary_email_attributes[id]", id.toString());
      }
    }

    if (customer_data.phone_nos) {
      customer_data.phone_nos.forEach((each, i) => {
        const { id, value } = each,
          [countryCode, phoneNo] = value.split(" ");

        payload.set(`mobiles_attrs[${i}][mobile]`, phoneNo);

        payload.set(`mobiles_attrs[${i}][country_code]`, countryCode);

        if (id !== undefined) {
          payload.set(`mobiles_attrs[${i}][id]`, id.toString());
        }
      });
    }

    if (customer_data.primary_phone_no?.value) {
      const [countryCode, phoneNo] = customer_data.primary_phone_no.value.split(" ");

      const id = customer_data.primary_phone_no?.id;

      payload.set("primary_mobile_attributes[country_code]", countryCode);

      payload.set("primary_mobile_attributes[mobile]", phoneNo);

      if (id && id > 0) {
        payload.set("primary_mobile_attributes[id]", id.toString());
      }
    }

    if (opportunity_slug) {
      payload.set("opportunity_slug", opportunity_slug);
    }

    if (vertical) {
      payload.set("vertical", vertical);
    }

    const {
      reservation_billing_name,
      reservation_billing_gst_in,
      reservation_billing_pan,
      reservation_billing_address,
      reservation_billing_city,
      reservation_billing_state,
      reservation_billing_country,
      reservation_billing_pincode,
      reservation_billing_bank_account_details,
      reservation_billing_gst_certificate,
      reservation_billing_gst_certificate_id,
      add_on_services_billing_name,
      add_on_services_billing_gst_in,
      add_on_services_billing_pan,
      add_on_services_billing_address,
      add_on_services_billing_city,
      add_on_services_billing_state,
      add_on_services_billing_country,
      add_on_services_billing_pincode,
      add_on_services_billing_bank_account_details,
      add_on_services_billing_gst_certificate,
      add_on_services_billing_gst_certificate_id,
    } = customer_data;

    if (reservation_billing_name) {
      payload.set(
        "reservation_billing_details[name]",
        reservation_billing_name,
      );

      payload.set(
        "reservation_billing_detail_attributes[name]",
        reservation_billing_name,
      );
    }

    if (reservation_billing_gst_in) {
      payload.set(
        "reservation_billing_details[gstin]",
        reservation_billing_gst_in,
      );

      payload.set(
        "reservation_billing_detail_attributes[gstin]",
        reservation_billing_gst_in,
      );
    }

    if (reservation_billing_pan) {
      payload.set("reservation_billing_details[pan]", reservation_billing_pan);

      payload.set(
        "reservation_billing_detail_attributes[pan]",
        reservation_billing_pan,
      );
    }

    if (reservation_billing_address) {
      payload.set(
        "reservation_billing_details[address]",
        reservation_billing_address,
      );

      payload.set(
        "reservation_billing_detail_attributes[address]",
        reservation_billing_address,
      );
    }

    if (reservation_billing_city) {
      payload.set(
        "reservation_billing_details[city]",
        reservation_billing_city,
      );

      payload.set(
        "reservation_billing_detail_attributes[city]",
        reservation_billing_city,
      );
    }

    if (reservation_billing_state) {
      payload.set(
        "reservation_billing_details[state]",
        reservation_billing_state,
      );

      payload.set(
        "reservation_billing_detail_attributes[state]",
        reservation_billing_state,
      );
    }

    if (reservation_billing_country) {
      payload.set(
        "reservation_billing_details[country]",
        reservation_billing_country,
      );

      payload.set(
        "reservation_billing_detail_attributes[country]",
        reservation_billing_country,
      );
    }

    if (reservation_billing_bank_account_details) {
      payload.set(
        "reservation_billing_details[bank_account_details]",
        reservation_billing_bank_account_details,
      );

      payload.set(
        "reservation_billing_detail_attributes[bank_account_details]",
        reservation_billing_bank_account_details,
      );
    }

    if (reservation_billing_pincode) {
      payload.set(
        "reservation_billing_details[pincode]",
        reservation_billing_pincode,
      );

      payload.set(
        "reservation_billing_detail_attributes[pincode]",
        reservation_billing_pincode,
      );
    }

    if (customer_data.reservation_billing_id) {
      payload.set(
        "reservation_billing_details[id]",
        customer_data.reservation_billing_id.toString(),
      );

      payload.set(
        "reservation_billing_detail_attributes[id]",
        customer_data.reservation_billing_id.toString(),
      );
    }

    if (reservation_billing_gst_certificate instanceof File) {
      payload.set(
        "reservation_billing_details[gstin_certificate_attributes][file]",
        reservation_billing_gst_certificate,
      );

      payload.set(
        "reservation_billing_detail_attributes[gstin_certificate_attributes][file]",
        reservation_billing_gst_certificate,
      );
    }

    if (
      reservation_billing_gst_certificate instanceof File &&
      reservation_billing_gst_certificate_id !== undefined
    ) {
      payload.set(
        "reservation_billing_details[gstin_certificate_attributes][id]",
        reservation_billing_gst_certificate_id.toString(),
      );

      payload.set(
        "reservation_billing_detail_attributes[gstin_certificate_attributes][id]",
        reservation_billing_gst_certificate_id.toString(),
      );
    }

    if (add_on_services_billing_name) {
      payload.set(
        "add_on_services_billing_details[name]",
        add_on_services_billing_name,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[name]",
        add_on_services_billing_name,
      );
    }

    if (add_on_services_billing_gst_in) {
      payload.set(
        "add_on_services_billing_details[gstin]",
        add_on_services_billing_gst_in,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[gstin]",
        add_on_services_billing_gst_in,
      );
    }

    if (add_on_services_billing_pan) {
      payload.set(
        "add_on_services_billing_details[pan]",
        add_on_services_billing_pan,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[pan]",
        add_on_services_billing_pan,
      );
    }

    if (add_on_services_billing_address) {
      payload.set(
        "add_on_services_billing_details[address]",
        add_on_services_billing_address,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[address]",
        add_on_services_billing_address,
      );
    }

    if (add_on_services_billing_city) {
      payload.set(
        "add_on_services_billing_details[city]",
        add_on_services_billing_city,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[city]",
        add_on_services_billing_city,
      );
    }

    if (add_on_services_billing_state) {
      payload.set(
        "add_on_services_billing_details[state]",
        add_on_services_billing_state,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[state]",
        add_on_services_billing_state,
      );
    }

    if (add_on_services_billing_country) {
      payload.set(
        "add_on_services_billing_details[country]",
        add_on_services_billing_country,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[country]",
        add_on_services_billing_country,
      );
    }

    if (add_on_services_billing_bank_account_details) {
      payload.set(
        "add_on_services_billing_details[bank_account_details]",
        add_on_services_billing_bank_account_details,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[bank_account_details]",
        add_on_services_billing_bank_account_details,
      );
    }

    if (add_on_services_billing_pincode) {
      payload.set(
        "add_on_services_billing_details[pincode]",
        add_on_services_billing_pincode,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[pincode]",
        add_on_services_billing_pincode,
      );
    }

    if (customer_data.add_on_services_billing_id) {
      payload.set(
        "add_on_services_billing_details[id]",
        customer_data.add_on_services_billing_id.toString(),
      );

      payload.set(
        "add_on_services_billing_detail_attributes[id]",
        customer_data.add_on_services_billing_id.toString(),
      );
    }

    if (add_on_services_billing_gst_certificate instanceof File) {
      payload.set(
        "add_on_services_billing_details[gstin_certificate_attributes][file]",
        add_on_services_billing_gst_certificate,
      );

      payload.set(
        "add_on_services_billing_detail_attributes[gstin_certificate_attributes][file]",
        add_on_services_billing_gst_certificate,
      );
    }

    if (
      add_on_services_billing_gst_certificate instanceof File &&
      add_on_services_billing_gst_certificate_id !== undefined
    ) {
      payload.set(
        "add_on_services_billing_details[gstin_certificate_attributes][id]",
        add_on_services_billing_gst_certificate_id.toString(),
      );

      payload.set(
        "add_on_services_billing_detail_attributes[gstin_certificate_attributes][id]",
        add_on_services_billing_gst_certificate_id.toString(),
      );
    }

    const url = new URL(
      `/api/v2/contacts/${customer_id}`,
      window.location.origin,
    );

    if (vertical) {
      url.searchParams.set("vertical", vertical);
    }

    if (opportunity_slug) {
      url.searchParams.set("opportunity_slug", opportunity_slug);
    }

    const { error } = await PATCH<void>(url.toString(), payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async verifyCustomer(
    customer_id: number,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {};
    if (vertical !== undefined) {
      payload.vertical = vertical;
    }
    if (opportunity_slug !== undefined) {
      payload.opportunity_slug = opportunity_slug;
    }

    const url = `/api/v2/contacts/${customer_id}/verify`,
      { error } = await POST<void>(url, payload);
    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getCustomerEnquiries(
    emails?: string[],
    phone_nos?: string[],
    customer_id?: number,
  ): Promise<FetchResult<EnquiryItem[]>> {
    const qp = new URLSearchParams();

    if (emails) {
      emails.forEach((email) => qp.set("emails[]", email));
    }

    if (phone_nos) {
      phone_nos.forEach((phone_no) => qp.set("mobiles[]", phone_no));
    }

    const url = `/api/v2/contacts/${customer_id}/enquiries?${qp}`,
      { error, response } = await GET<CustomerEnquiriesResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.enquiries.map((each) => {
      const {
          email,
          interested_in,
          location,
          message,
          mobile,
          name,
          slug,
          source,
          source_region,
          source_city,
          created_at,
          verified,
          vertical,
          lead_exists,
          repeat_user,
          is_trash,
          editable,
          country_code = "",
        } = each,
        phoneNo = `${country_code} ${mobile}`.trim(),
        enquiry: EnquiryItem = {
          slug,
          email: email,
          name: name,
          phone_no: phoneNo,
          enquiry_date: created_at,
          location: location || "",
          message: message || "",
          reason: interested_in || "N.A",
          source: source || "",
          source_region: source_region || "",
          source_city: source_city || "",
          verified: verified || false,
          is_trash: is_trash || false,
          vertical: vertical,
          lead_exists: lead_exists || false,
          repeat_user: repeat_user || false,
          editable: editable,
          created_date: created_at || "",
        };
      return enquiry;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async getCustomerTrips(
    customer_id: number,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<CustomerOpportunity[]>> {
    const qp = new URLSearchParams();
    if (page) {
      qp.set("page", page);
    }
    if (page_size) {
      qp.set("page_size", page_size);
    }
    const marshalled = qp.toString();

    let url = `/api/v2/contacts/${customer_id}/rental_opportunities`;

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<CustomerTripsResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.rental_opportunities.map((each) => {
      const {
          enquired_at,
          poc_exec,
          slug,
          source,
          status,
          updated_at,
          check_in,
          check_out,
          interested_location,
          grm_note: { note },
          villa_name,
        } = each as any,
        opportunity: CustomerOpportunity = {
          slug: slug || "",
          interested_location: interested_location,
          enquiry_date: enquired_at || "",
          last_modified: updated_at || "",
          source: source || "",
          status: status || "",
          poc_executive: poc_exec || "",
          checkin_date: check_in || "",
          checkout_date: check_out || "",
          grm_note: note || "",
          villa_name: villa_name || "",
        };

      return opportunity;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  async getCustomerOpportunities(
    customer_id: number,
  ): Promise<FetchResult<CustomerOpportunity[]>> {
    const url = `/api/v2/contacts/${customer_id}/development_opportunities`,
      { error, response } = await GET<CustomerOpportunitiesResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.development_opportunities.map((each) => {
      const {
          enquired_at,
          interested_location,
          poc_exec,
          slug,
          source,
          status,
          updated_at,
        } = each,
        opportunity: CustomerOpportunity = {
          slug: slug || "",
          interested_location: interested_location || "",
          enquiry_date: enquired_at || "",
          last_modified: updated_at || "",
          source: source || "",
          status: status || "",
          poc_executive: poc_exec || "",
          checkin_date: "",
          checkout_date: "",
        };

      return opportunity;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async getCustomerChapterOpportunities(
    customer_id: number,
  ): Promise<FetchResult<CustomerOpportunity[]>> {
    const url = `/api/v2/contacts/${customer_id}/chapter_opportunities`,
      { error, response } = await GET<CustomerChapterOpportunitiesResponse>(
        url,
      );

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.chapter_opportunities.map((each) => {
      const {
          enquired_at,
          interested_location,
          poc_exec,
          slug,
          source,
          status,
          updated_at,
        } = each,
        opportunity: CustomerOpportunity = {
          slug: slug || "",
          interested_location: interested_location || "",
          enquiry_date: enquired_at || "",
          last_modified: updated_at || "",
          source: source || "",
          status: status || "",
          poc_executive: poc_exec || "",
          checkin_date: "",
          checkout_date: "",
        };

      return opportunity;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async getCustomerDuplicates(
    customer_id: number,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<CustomerDuplicate[]>> {
    const url = new URL(
      `/api/v2/contacts/${customer_id}/similar`,
      window.location.origin,
    );

    if (vertical) {
      url.searchParams.set("vertical", vertical);
    }

    if (opportunity_slug) {
      url.searchParams.set("opportunity_slug", opportunity_slug);
    }

    const { error, response } = await GET<CustomerDuplicatesResponse>(
      url.toString(),
    );

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.contacts.map((each: any) => {
      const { id, name, emails, mobiles, mergeable } = each,
        formatted: CustomerDuplicate = {
          id: id,
          name: name || "",
          emails: parseIndexedEmails(emails),
          phone_nos: parseIndexedPhoneNos(mobiles),
          can_merge: mergeable || false,
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async mergeCustomer(
    customer_id: number,
    target_customer_id: number,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<void>> {
    const url = new URL(
      `/api/v2/contacts/${customer_id}/merge`,
      window.location.origin,
    );

    if (vertical) {
      url.searchParams.set("vertical", vertical);
    }

    if (opportunity_slug) {
      url.searchParams.set("opportunity_slug", opportunity_slug);
    }

    const payload = { target_contact_id: target_customer_id },
      { error } = await POST<void>(url.toString(), payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getCustomerDocuments(
    customer_id: number,
  ): Promise<FetchResult<CustomerDocument[]>> {
    const url = `/api/v2/contacts/${customer_id}/documents`,
      { error, response } = await GET<CustomerDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: CustomerDocument[] = response.documents.map((each) => {
      const { id, link, document_identifier, document_type } = each,
        formatted: CustomerDocument = {
          id: id,
          type: document_type || "",
          value: document_identifier || "",
          file: link || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addCustomerDocument(
    customer_id: number,
    document_type: string,
    document_value: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("document_type", document_type);
    formData.set("document_identifier", document_value);
    formData.set("file", file);

    const url = `/api/v2/contacts/${customer_id}/documents`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateCustomerDocument(
    customer_id: number,
    document_id: number,
    document_type: string,
    document_value: string,
    file: File | string,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("document_type", document_type);
    formData.set("document_identifier", document_value);
    if (file instanceof File) {
      formData.set("file", file);
    }

    const url = `/api/v2/contacts/${customer_id}/documents/${document_id}`,
      { error } = await PATCH<void>(url, formData);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteCustomerDocument(
    customer_id: number,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/contacts/${customer_id}/documents/${document_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getRelationships(
    customer_id: number,
  ): Promise<FetchResult<CustomerRelationship[]>> {
    const url = `/api/v2/contacts/${customer_id}/relationships`,
      { error, response } = await GET<CustomerRelationshipsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const relationships: CustomerRelationship[] = response.relatives.map(
      (each) => {
        const {
            id,
            name,
            primary_email_details,
            primary_mobile_details,
            relationship,
            dob,
            gender,
          } = each,
          nameSplits = (name || "").split(" "),
          r: CustomerRelationship = {
            id: id,
            first_name: nameSplits[0] || "",
            last_name:
              nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
            date_of_birth: dob || "",
            gender: gender || "",
            email: primary_email_details
              ? parseIndexedEmails([primary_email_details])[0]
              : undefined,
            phone_no: primary_mobile_details
              ? parseIndexedPhoneNos([primary_mobile_details])[0]
              : undefined,
            relationship: relationship
              ? {
                  id: relationship.id,
                  secondary_contact_id: relationship.relative_contact_id,
                  value: relationship.relation,
                }
              : undefined,
          };

        return r;
      },
    );

    return {
      error: null,
      response: relationships,
    };
  }

  async addRelationship(
    customer_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    secondary_customer_id?: number,
    relationship_value?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {};

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    payload.name = name;

    payload.gender = gender;

    if (date_of_birth !== undefined) {
      payload.dob = date_of_birth;
    }

    if (email !== undefined) {
      const email_attrs: any = { email: email.value };

      if (email.id !== undefined) {
        email_attrs.id = email.id;
      }

      payload.primary_email_attributes = [email_attrs];
    }

    if (phone_no !== undefined) {
      const [countryCode, phoneNo] = phone_no.value.split(" "),
        mobile_attrs: any = { mobile: phoneNo, country_code: countryCode };

      if (phone_no.id !== undefined) {
        mobile_attrs.id = phone_no.id;
      }

      payload.primary_phone_no = [mobile_attrs];
    }

    if (secondary_customer_id !== undefined) {
      payload.relative_contact_id = secondary_customer_id;
    }

    if (relationship_value !== undefined) {
      const relationship: any = {
        relation: relationship_value,
      };
      if (secondary_customer_id !== undefined) {
        relationship.relative_contact_id = secondary_customer_id;
      }
      payload.relationships_attributes = [relationship];
    }

    const url = `/api/v2/contacts/${customer_id}/relationships`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateRelationship(
    customer_id: number,
    p_secondary_customer_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    relationship_id?: number,
    secondary_customer_id?: number,
    relationship_value?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {};

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    payload.name = name;

    payload.gender = gender;

    if (date_of_birth !== undefined) {
      payload.dob = date_of_birth;
    }

    if (email !== undefined) {
      const email_attrs: any = { email: email.value };
      if (email.id !== undefined) {
        email_attrs.id = email.id;
      }
      payload.primary_email_attributes = [email_attrs];
    }

    if (phone_no !== undefined) {
      const [countryCode, phoneNo] = phone_no.value.split(" "),
        mobile_attrs: any = { mobile: phoneNo, country_code: countryCode };

      if (phone_no.id !== undefined) {
        mobile_attrs.id = phone_no.id;
      }

      payload.primary_mobile_attributes = [mobile_attrs];
    }

    if (relationship_value !== undefined) {
      const relationship: any = {
        relation: relationship_value,
      };
      if (relationship_id !== undefined) {
        relationship.id = relationship_id;
      }
      if (secondary_customer_id !== undefined) {
        relationship.relative_contact_id = secondary_customer_id;
      }
      payload.relationships_attributes = [relationship];
    }

    const url = `/api/v2/contacts/${customer_id}/relationships/${p_secondary_customer_id}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteRelationship(
    customer_id: number,
    relationship_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/contacts/${customer_id}/relationships/${relationship_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async resendFeedback(
    customer_id: number,
    opportunity_slug: string,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/contacts/${customer_id}/resend_feedback`,
      { error } = await POST<void>(url, { opportunity_slug: opportunity_slug });

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getCustomerCreditHistory(
    customer_id: number,
  ): Promise<FetchResult<CustomerCreditHistory>> {
    const url = `/api/v2/contacts/${customer_id}/credit_histories`,
      { error, response } = await GET<CustomerCreditHistoryResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const history = response.transactions.map(each => {
      const {
        amount,
        transaction_type,
        expiry,
        created_at,
        opportunity_slug,
      } = each,
        formatted: CustomerCreditItem = {
          amount: amount || 0,
          created_at: created_at || "",
          expires_at: expiry || undefined,
          opportunity_slug: opportunity_slug || "",
          transaction_type: transaction_type || "",
        };

      return formatted;
    });

    const creditBalance = response.total || 0;

    const formatted: CustomerCreditHistory = {
      credit_balance: creditBalance,
      history: history,
    };

    return {
      response: formatted,
      error: null,
    };
  }

  async listLLPHistory(
    customer_id: number,
  ): Promise<FetchResult<CustomerLLPHistory[]>> {
    const url = `/api/v2/contacts/${customer_id}/loyalty_point_histories`,
      { error, response } = await GET<CustomerLLPHistoryResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: CustomerLLPHistory[] = response.transactions.map(
      (each) => {
        const {
            id,
            amount,
            transaction_type,
            comment,
            property_name,
            nights,
            location,
            check_in,
            check_out,
            created_at,
          } = each,
          formatted: CustomerLLPHistory = {
            id,
            amount: amount || 0,
            transaction_type: transaction_type || "",
            comment: comment || "",
            property_name: property_name || "",
            nights: nights || 0,
            location: location || "",
            check_in: check_in || undefined,
            check_out: check_out || undefined,
            created_at: created_at || undefined,
          };

        return formatted;
      },
    );

    return {
      response: formatted,
      error: null,
    };
  }

  async getIspravaBillingDetails(
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<IspravaBillingDetails>> {
    const url = `/api/v2/contacts/${vertical}/billing_details?opportunity_slug=${opportunity_slug}&vertical=${vertical}`,
      { error, response } = await GET<IspravaCustomerBillingDetailsResponse>(
        url.toString(),
      );

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { billing_detail = {} as any } = response,
      customer: IspravaBillingDetails = {
        billing_id: billing_detail.id || -1,
        billing_name: billing_detail.name || "",
        billing_gst_in: billing_detail.gstin || "",
        billing_pan: billing_detail.pan || "",
        billing_address: billing_detail.address || "",
        billing_city: billing_detail.city || "",
        billing_state: billing_detail.state || "",
        billing_country: billing_detail.country || "",
        billing_pincode: billing_detail.pincode || "",
        billing_villa_name: billing_detail.villa_name || "",
        billing_villa_address: billing_detail.villa_address || "",
        billing_bank_account_details: billing_detail.bank_account_details || "",
        billing_gst_certificate: billing_detail?.gstin_certificate?.link || "",
        billing_gst_certificate_id: billing_detail?.gstin_certificate?.id,
      };

    return {
      error: null,
      response: customer,
    };
  }

  async updateIsprvaCustomerBillingDetails(
    customer_data: Partial<IspravaBillingDetails>,
    vertical?: string,
    opportunity_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload = new FormData();

    if (opportunity_slug) {
      payload.set("opportunity_slug", opportunity_slug);
    }

    if (vertical) {
      payload.set("vertical", vertical);
    }

    const {
      billing_name,
      billing_gst_in,
      billing_pan,
      billing_address,
      billing_city,
      billing_state,
      billing_country,
      billing_pincode,
      billing_villa_name,
      billing_villa_address,
      billing_bank_account_details,
      billing_gst_certificate,
      billing_gst_certificate_id,
    } = customer_data;

    if (billing_name) {
      payload.set("billing_details[name]", billing_name);
    }

    if (billing_gst_in) {
      payload.set("billing_details[gstin]", billing_gst_in);
    }

    if (billing_pan) {
      payload.set("billing_details[pan]", billing_pan);
    }

    if (billing_address) {
      payload.set("billing_details[address]", billing_address);
    }

    if (billing_city) {
      payload.set("billing_details[city]", billing_city);
    }

    if (billing_state) {
      payload.set("billing_details[state]", billing_state);
    }

    if (billing_country) {
      payload.set("billing_details[country]", billing_country);
    }

    if (billing_villa_name) {
      payload.set("billing_details[villa_name]", billing_villa_name);
    }

    if (billing_villa_address) {
      payload.set("billing_details[villa_address]", billing_villa_address);
    }

    if (billing_bank_account_details) {
      payload.set(
        "billing_details[bank_account_details]",
        billing_bank_account_details,
      );
    }

    if (billing_pincode) {
      payload.set("billing_details[pincode]", billing_pincode);
    }

    if (customer_data.billing_id) {
      payload.set("billing_details[id]", customer_data.billing_id.toString());
    }

    if (billing_gst_certificate instanceof File) {
      payload.set(
        "billing_details[gstin_certificate_attributes][file]",
        billing_gst_certificate,
      );
    }

    if (
      billing_gst_certificate instanceof File &&
      billing_gst_certificate_id !== undefined
    ) {
      payload.set(
        "billing_details[gstin_certificate_attributes][id]",
        billing_gst_certificate_id.toString(),
      );
    }

    const url = `/api/v2/contacts/${vertical}/billing_details`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default CustomerService;

type CustomerResponse = {
  contact: any;
};

type CustomerListResponse = {
  contacts: any[];
  paginate: Pagination;
};

type CustomerEnquiriesResponse = {
  enquiries: any[];
};

type CustomerTripsResponse = {
  rental_opportunities: any[];
  paginate: Pagination;
};

type CustomerDuplicatesResponse = {
  contacts: any;
};

type CustomerDocumentsResponse = {
  documents: any[];
};

type CustomerOpportunitiesResponse = {
  development_opportunities: any[];
};

type CustomerChapterOpportunitiesResponse = {
  chapter_opportunities: any[];
};

type CustomerRelationshipsResponse = {
  relatives: any[];
};

type CustomerCreditHistoryResponse = {
  transactions: [];
  total: number;
};

type CustomerLLPHistoryResponse = {
  transactions: any[];
};

type IspravaCustomerBillingDetailsResponse = {
  billing_detail: any;
};

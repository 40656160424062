import { DashboardTask, TaskStats } from "../types/dashboard";
import { EnquiryItem } from "../types/enquiry";
import { Pagination } from "../types/pagination";
import { IDashboardService } from "../types/services/dashboard-service";
import { FetchResult, GET } from "../utils/fetch";

type MyTasksResponse = {
  tasks: any[];
  paginate: Pagination;
};

type TaskStatsResponse = {
  metrics: any;
};

type DashboardEnquiriesResponse = {
  enquiries: any[];
};

function getMyTasksQp(
  statuses?: string[],
  resolution?: string,
  due_from?: string,
  due_until?: string,
  staff_id?: string,
  departments?: string[],
  search?: string,
  page?: string,
  page_size?: string,
) {
  const qp = new URLSearchParams();

  if (statuses) {
    statuses.forEach((status) => {
      qp.append("opportunity_status[]", status);
    });
  }

  if (resolution !== undefined) {
    qp.set("type", resolution === "true" ? "completed" : "pending");
  }

  if (due_from) {
    qp.set("start_date", due_from);
  }

  if (due_until) {
    qp.set("end_date", due_until);
  }

  if (staff_id) {
    qp.set("staff_id", staff_id);
  }

  if (departments?.length) {
    departments.forEach((department) => qp.append("departments[]", department));
  }

  if (search) {
    qp.set("query", search);
  }

  if (page) {
    qp.set("page", page);
  }

  if (page_size) {
    qp.set("per_page", page_size);
  }

  return qp;
}

class DashboardService implements IDashboardService {
  async getMyTasks(
    statuses?: string[],
    resolution?: string,
    due_from?: string,
    due_until?: string,
    staff_id?: string,
    departments?: string[],
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<DashboardTask[]>> {
    const qp = getMyTasksQp(
      statuses,
      resolution,
      due_from,
      due_until,
      staff_id,
      departments,
      search,
      page,
      page_size,
    );

    const marshaled = qp.toString();

    let url = `/api/v2/dashboard`;

    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<MyTasksResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.tasks.map((each) => {
      const {
          vertical,
          opportunity_status,
          name,
          mobile,
          location,
          medium,
          checkin_date,
          checkout_date,
          due_at,
          created_at,
          resolved,
          opportunity_slug,
          // done_at,
          description,
          title,
          property_name,
          reported_by,
          priority,
          zammad_ticket_number,
        } = each,
        _vertical = (vertical || "").toLowerCase(),
        isConcierge = _vertical === "concierge",
        _agenda = isConcierge ? title : description,
        formatted: DashboardTask = {
          name: name || "",
          phone_no: mobile || "",
          medium: medium || "",
          location: location || "",
          status: opportunity_status || "",
          created_date: created_at || "",
          resolved: resolved || false,
          vertical: _vertical,
          opportunity_slug: opportunity_slug,
          agenda: _agenda,
          zammad_ticket_id: zammad_ticket_number || undefined,
        };

      if (checkin_date) {
        formatted.checkin_date = checkin_date;
      }

      if (checkout_date) {
        formatted.checkout_date = checkout_date;
      }

      if (due_at) {
        formatted.due_date = due_at;
      }

      if (isConcierge) {
        formatted.property_name = property_name || "";
        formatted.reported_by = reported_by || "";
        formatted.priority = priority || "p3";
      }

      return formatted;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  getMyTasksCSV(
    statuses?: string[],
    resolution?: string,
    due_from?: string,
    due_until?: string,
    staff_id?: string,
    departments?: string[],
  ): string {
    const qp = getMyTasksQp(
      statuses,
      resolution,
      due_from,
      due_until,
      staff_id,
      departments,
    );

    const marshaled = qp.toString();
    let url = `/api/v2/dashboard.csv`;

    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    return url;
  }

  async getTaskStats(
    start_date: string,
    staff_id: string,
  ): Promise<FetchResult<TaskStats>> {
    const qp = new URLSearchParams();
    qp.set("start_date", start_date);
    qp.set("staff_id", staff_id);

    const url = `/api/v2/dashboard/metrics?${qp}`,
      { response, error } = await GET<TaskStatsResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const {
        todays_reminders,
        future_reminders,
        todays_completed_tasks,
        past_due_reminders,
      } = response.metrics,
      formatted: TaskStats = {
        todays_reminders: todays_reminders || 0,
        future_reminders: future_reminders || 0,
        todays_completed_tasks: todays_completed_tasks || 0,
        past_due_reminders: past_due_reminders || 0,
      };

    return {
      response: formatted,
      error: null,
    };
  }

  async getEnquiries(
    departments?: string[],
    page_size?: string,
  ): Promise<FetchResult<EnquiryItem[]>> {
    const qp = new URLSearchParams();
    if (departments) {
      departments.forEach((department) => {
        qp.append("vertical[]", department);
      });
    }
    if (page_size) {
      qp.set("per_page", page_size);
    }

    const marshalled = qp.toString();

    let url = `/api/v2/enquiries/list`;

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<DashboardEnquiriesResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.enquiries.map((each) => {
      const {
          email,
          interested_in,
          //is_trash,
          location,
          message,
          mobile,
          name,
          slug,
          source,
          source_region,
          source_city,
          created_at,
          verified,
          lead_exists,
          repeat_user,
          vertical,
          is_trash,
          editable,
        } = each,
        enquiry: EnquiryItem = {
          slug,
          email: email,
          name: name,
          phone_no: mobile,
          enquiry_date: created_at,
          location: location || "",
          message: message || "",
          reason: interested_in || "N.A",
          source: source || "",
          source_region: source_region || "",
          source_city: source_city || "",
          verified: verified || false,
          is_trash: is_trash || false,
          vertical,
          lead_exists: lead_exists || false,
          repeat_user: repeat_user || false,
          editable: editable || false,
          created_date: created_at || "",
        };
      return enquiry;
    });

    return {
      error: null,
      response: formatted,
      paginate: {
        start_offset: 0,
        end_offset: 10,
        total_records: 10,
        total_pages: 1,
        current_page: 1,
      },
    };
  }
}

export default DashboardService;

import makeStyles from "@mui/styles/makeStyles";

const useSipConnectStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    color: "black",
    borderRadius: "50%",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      width: "32px",
      height: "32px",
      fontSize: "16px",
    },
    "@media (min-width: 768px)": {
      width: "40px",
      height: "40px",
      fontSize: "18px",
    },
  },
});

export default useSipConnectStyles;

import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';

import ALIEN_IMG from "../../assets/alien.jpg";

const useLoginStyles = makeStyles((theme: Theme) => {
  const {
    palette: { secondary },
  } = theme;

  return {
    login: {
      overflow: "hidden",
      position: "relative",
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    banner: {
      position: "absolute",
      left: "-5vw",
      top: "-5vh",
      width: "110vw",
      height: "110vh",
      zIndex: -1,
      objectFit: "cover",
    },
    logos: {
      marginBottom: "32px",
      fontSize: "24px",
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    lohonoLogo: {
      width: "44px",
      objectFit: "contain",
      transform: "translateY(-4px)",
      margin: "0 16px",
    },
    ispravaLogo: {
      width: "40px",
      objectFit: "contain",
      margin: "0 16px",
    },
    title: {
      fontSize: "32px",
      marginBottom: "32px",
      color: "white",
    },
    loginCard: {
      minHeight: "400px",
      display: "flex",
      padding: "1rem",
      backgroundColor: "white",
      background: `url(${ALIEN_IMG})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      "&:hover ~ $banner": {
        filter: "blur(15px)",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        margin: "0 1rem",
        backgroundPosition: "0 110px",
      },
      "@media (min-width: 600px)": {
        width: "600px",
        backgroundPosition: "-40px 110px",
      },
    },
    loginContainer: {
      flex: "1 1 0",
      padding: "16px",
      borderRadius: "12px",
      display: "grid",
      gridTemplate: "auto 1fr/1fr",
      gridGap: "24px",
    },
    subtitle: {
      color: secondary.main,
      justifySelf: "center",
      fontSize: "20px",
      fontWeight: 400,
    },
    loginButton: {
      textAlign: "center",
    },
  };
});

export default useLoginStyles;

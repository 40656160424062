import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import clx from "classnames";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";

import { useMe } from "../../contexts/me-context";
import { useSecureState } from "../../contexts/secure-state-context";
import { useSip } from "../../contexts/sip-context/sip-context";
import useCollapsed from "../../hooks/use-collapsed";
import { Page, PageTemplate } from "../../types/page-template";
import getAvatarName from "../../utils/get-avatar-name";
import DesktopHeader from "./DesktopHeader";
import MetricsCounter from "./MetricsCounter";
import MobileHeader from "./MobileHeader";
import useNavigationStyles from "./NavigationStyles";
import SipConnect from "./SipConnect";
import StartAction from "./StartAction";

interface NavigationProps {
  pages: PageTemplate[];
}

function Navigation(props: NavigationProps) {
  const { isSecure } = useSecureState(),
    { me, hasAccessTo } = useMe(),
    trigger = useScrollTrigger(),
    classNames = useNavigationStyles(),
    { collapsed, toggleCollapse } = useCollapsed(true),
    { pages } = props,
    { sip } = useSip(),
    loginRole = sessionStorage.getItem("loginRole"),
    avatarName = useMemo(() => {
      if (!me) {
        return "";
      }

      const name = [me.first_name, me.last_name]
        .filter(n => (n || "").trim())
        .join(" "),
        avatarName = getAvatarName(name);

      return avatarName;
    }, [me]),
    dismissDrawer = toggleCollapse.bind(null, false),
    menuClassName = clx("material-icons", classNames.mobile, classNames.menu),
    desktopAvatarClassName = clx(classNames.iconActions, classNames.desktop);

  if (!isSecure || !me) {
    return null;
  }

  const avatarNode = (
    <Link
      onClick={toggleCollapse.bind(null, false)}
      to="/profile"
    >
      <Avatar
        alt={avatarName}
        className={classNames.avatar}
      >
        {avatarName}
      </Avatar>
    </Link>
  );

  return (
    <>
      <Slide
        appear={false}
        direction="down"
        in={!trigger}
      >
        <AppBar
          className={classNames.appBar}
          data-active={sip?.isRegistered() ?? false}
        >
          <Toolbar>
            <div className={classNames.navigationStartActions}>
              <StartAction />
              <Link
                className={classNames.brandName}
                to={loginRole === "agent" ? "/rentals/trips" : "/"}
              >
                <Typography
                  noWrap
                  variant="h6"
                >
                  Lohono OI
                </Typography>
              </Link>
              {pages.reduce((compiled, each, key) => {
                if ("pages" in each) {
                  const { name, pages } = each;

                  return [
                    ...compiled,
                    <DesktopHeader
                      className={classNames.desktop}
                      isDropdown={true}
                      key={key}
                      name={name}
                      routes={pages}
                    />,
                  ];
                }

                const { name, path, acl } = each as Page,
                  haveAccess = acl ? hasAccessTo(acl) : true;

                if (!name || !haveAccess) {
                  return compiled;
                }

                return [
                  ...compiled,
                  <DesktopHeader
                    className={classNames.desktop}
                    isDropdown={false}
                    key={path}
                    name={name}
                    path={path}
                    routes={[]}
                  />,
                ];
              }, [] as ReactNode[])}
            </div>
            <div className={classNames.navigationEndActions}>
              {loginRole !== "agent" &&
                <>
                  <SipConnect />
                  <MetricsCounter />
                </>
              }
              <div className={desktopAvatarClassName}>{avatarNode}</div>
              <span
                className={menuClassName}
                onClick={toggleCollapse.bind(null, true)}
              >
                menu
              </span>
            </div>
          </Toolbar>
        </AppBar>
      </Slide>
      <div className={classNames.navigationAdjust}>&nbsp;</div>
      <Drawer
        anchor="right"
        onClose={dismissDrawer}
        onOpen={toggleCollapse.bind(null, true)}
        open={collapsed}
      >
        <div className={classNames.avatarContainer}>{avatarNode}</div>
        <List className={classNames.list}>
          {pages.reduce((compiled, each, key) => {
            if ("pages" in each) {
              const { name, pages } = each;
              return [
                ...compiled,
                <Fragment key={key}>
                  <Divider />
                  <MobileHeader
                    className={classNames.mobile}
                    isDropdown={true}
                    name={name}
                    onClick={dismissDrawer}
                    routes={pages}
                  />
                </Fragment>,
              ];
            }

            const { name, path, acl } = each as Page,
              haveAccess = acl ? hasAccessTo(acl) : true;

            if (!name || !haveAccess) {
              return compiled;
            }

            return [
              ...compiled,
              <Fragment key={key}>
                <Divider />
                <MobileHeader
                  className={classNames.mobile}
                  isDropdown={false}
                  name={name}
                  onClick={dismissDrawer}
                  path={path}
                  routes={[]}
                />
              </Fragment>,
            ];
          }, [] as ReactNode[])}
        </List>
      </Drawer>
    </>
  );
}

export default Navigation;

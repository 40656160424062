import Link, { LinkProps } from "@mui/material/Link";
import React, { forwardRef, Ref } from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouterCore(props: LinkRouterProps, ref: Ref<any>) {
  return <Link
    {...props}
    component={RouterLink as any}
    ref={ref}
         />;
}

const LinkRouter = forwardRef(LinkRouterCore);

export default LinkRouter;

import React, { createContext, ReactNode, useContext } from "react";

import { IRentalPropertyService } from "../types/services/rental-property-service";

const RentalPropertyServiceContext = createContext<IRentalPropertyService>(
  undefined as any,
);

interface RentalPropertyServiceProviderProps {
  service: IRentalPropertyService;
  children: ReactNode;
}

function RentalPropertyServiceProvider(
  props: RentalPropertyServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <RentalPropertyServiceContext.Provider value={service}>
      {children}
    </RentalPropertyServiceContext.Provider>
  );
}

function useRentalPropertyService() {
  return useContext(RentalPropertyServiceContext);
}

export { RentalPropertyServiceProvider, useRentalPropertyService };

import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  Masters,
  MastersAgent,
  MastersChapter,
  MastersConcierge,
  MastersDevelopment,
  MastersHomeAcquisition,
  MastersRental,
} from "../types/masters";

export type MastersState = {
  masters: Masters;
  updateMasters: (masters: Masters) => any;
  mastersRental: MastersRental;
  updateMastersRental: (mastersRental: MastersRental) => any;
  mastersDevelopment: MastersDevelopment;
  updateMastersDevelopment: (mastersDevelopment: MastersDevelopment) => any;
  mastersChapter: MastersChapter;
  updateMastersChapter: (mastersChapter: MastersChapter) => any;
  mastersHA: MastersHomeAcquisition;
  updateMastersHA: (mastersHA: MastersHomeAcquisition) => any;
  mastersAgent: MastersAgent;
  updateMastersAgent: (mastersAgent: MastersAgent) => any;
  mastersConcierge: MastersConcierge;
  updateMastersConcierge: (mastersConcierge: MastersConcierge) => any;
};

const DEFAULT_MASTERS: Masters = {
  contact_genders: [],
  contact_gendersMap: {},
  contact_marital_status: [],
  contact_marital_statusMap: {},
  enquiry_interested_in: [],
  enquiry_interested_inMap: {},
  enquiry_verticals: [],
  enquiry_verticalsMap: {},
  staff_roles: [],
  staff_rolesMap: {},
  staff_verticals: [],
  staff_verticalsMap: {},
  opportunity_status: [],
  opportunity_statusMap: {},
  locations: [],
  locationsMap: {},
  sources: {},
  source_regions: [],
  source_regionsMap: {},
  document_types: [],
  document_typesMap: {},
  relations: [],
  relationsMap: {},
  agent_types: [],
  agent_typesMap: {},
  calling_numbers: [],
  calling_numbersMap: {},
  india_states: [],
  india_statesMap: {},
  brands: [],
  brandsMap: {},
  departments: [],
  departmentsMap: {},
  service_types: [],
  service_typesMap: {},
  site_visit_statuses: [],
  site_visit_statusesMap: {},
  site_visit_cancellation_reasons: [],
  site_visit_cancellation_reasonsMap: {},
  site_visit_plot_verticals: [],
  site_visit_plot_verticalsMap: {},
  domestic_properties: [],
  domestic_propertiesMap: {},
},
  DEFAULT_MASTERS_RENTAL: MastersRental = {
    visit_purpose: [],
    visit_purposeMap: {},
    cleartax_cancellation_reasons: [],
    cleartax_cancellation_reasonsMap: {},
    invoice_rejection_reasons: [],
    invoice_rejection_reasonsMap: {},
    invoice_status: [],
    invoice_statusMap: {},
    invoice_types: [],
    invoice_typesMap: {},
    opportunity_status: [],
    opportunity_statusMap: {},
    locations: [],
    locationsMap: {},
    countries: [],
    countriesMap: {},
    coupon_types: [],
    coupon_typesMap: {},
    property_types: [],
    property_typesMap: {},
    medium: [],
    mediumMap: {},
    closed_reason: [],
    closed_reasonMap: {},
    lead_closed_reason: [],
    lead_closed_reasonMap: {},
    prospect_closed_reason: [],
    prospect_closed_reasonMap: {},
    stages: [],
    stagesMap: {},
    properties: [],
    propertiesMap: {},
    prebooking_durations: [],
    prebooking_durationsMap: {},
    offer_types: [],
    offer_typesMap: {},
    attachment_types: [],
    attachment_typesMap: {},
    charge_types: [],
    payment_modes: [],
    payment_modesMap: {},
    payment_types: [],
    payment_typesMap: {},
    charge_typesMap: {},
    domestic_locations: [],
    domestic_locationsMap: {},
    contract_licence_types: [],
    contract_licence_typesMap: {},
    queries: [],
    queriesMap: {},
    bad_stay_accounts: [],
    bad_stay_accountsMap: {},
    bad_stay_reasons: [],
    bad_stay_reasonsMap: {},
    payment_rejection_reasons: [],
    payment_rejection_reasonsMap: {},
    currencies: [],
    currenciesMap: {},
    payment_status: [],
    payment_statusMap: {},
    deposit_pending_reasons: [],
    deposit_pending_reasonsMap: {},
    deposit_status: [],
    deposit_statusMap: {},
    categories: [],
    categoriesMap: {},
    resort_properties: [],
    resort_propertiesMap: {},
    international_payment_types: [],
    international_payment_typesMap: {},
    agent_for_options: [],
    agent_for_optionsMap: {},
    payment_for_options: [],
    payment_for_optionsMap: {},
    international_payment_for_options: [],
    international_payment_for_optionsMap: {},
    charge_statuses: [],
    charge_statusesMap: {},
    cancellation_reasons: [],
    cancellation_reasonsMap: {},
    property_highlights: [],
    property_highlightsMap: {},
    agent_payment_types: [],
    agent_payment_typesMap: {},
    calendar_years: [],
    calendar_yearsMap: {},
    sales_channels: [],
    sales_channelsMap: {},
    reason_for_allocation: [],
    reason_for_allocationMap: {},
    calendar_status: [],
    calendar_statusMap: {},
  },
  DEFAULT_MASTERS_DEVELOPMENT: MastersDevelopment = {
    opportunity_status: [],
    opportunity_statusMap: {},
    medium: [],
    mediumMap: {},
    closed_reason: [],
    closed_reasonMap: {},
    stages: [],
    stagesMap: {},
    locations: [],
    delivery_timeline_status: [],
    poc_list: [],
    properties: [],
    agreement_types: [],
    collection_entity: [],
    account_type: [],
    bank_accounts: [],
    bank_accountsMap: {},
    document_types: [],
    document_typesMap: {},
    attachment_types: [],
    attachment_typesMap: {},
    gst_value: [],
    gst_valueMap: {},
    tds_value: [],
    tds_valueMap: {},
    viewing_types: [],
    viewing_typesMap: {},
  },
  DEFAULT_MASTERS_CHAPTER: MastersChapter = {
    opportunity_status: [],
    opportunity_statusMap: {},
    home_layouts: [],
    home_layoutsMap: {},
    stages: [],
    stagesMap: {},
    medium: [],
    mediumMap: {},
    closed_reason: [],
    closed_reasonMap: {},
    plots: [],
    plotsMap: {},
    bank_accounts: [],
    bank_accountsMap: {},
    gst_value: [],
    gst_valueMap: {},
    tds_value: [],
    tds_valueMap: {},
    agreement_types: [],
    agreement_typesMap: {},
    attachment_types: [],
    attachment_typesMap: {},
    locations: [],
    locationsMap: {},
    delivery_timeline_status: [],
    delivery_timeline_statusMap: {},
    document_types: [],
    document_typesMap: {},
    viewing_types: [],
    viewing_typesMap: {},
  },
  DEFAULT_MASTERS_HA: MastersHomeAcquisition = {
    opportunity_status: [],
    opportunity_statusMap: {},
    locations: [],
    locationsMap: {},
    medium: [],
    mediumMap: {},
    closed_reason: [],
    closed_reasonMap: {},
    lead_closed_reason: [],
    lead_closed_reasonMap: {},
    prospect_closed_reason: [],
    prospect_closed_reasonMap: {},
    stages: [],
    stagesMap: {},
  },
  DEFAULT_MASTERS_AGENT: MastersAgent = {
    opportunity_status: [],
    opportunity_statusMap: {},
    closed_reason: [],
    closed_reasonMap: {},
    attachment_types: [],
    attachment_typesnMap: {},
    medium: [],
    mediumMap: {},
    sources: [],
    sourcesMap: {},
  },
  DEFAULT_MASTERS_CONCIERGE = {
    ticket_status: [],
    ticket_statusMap: {},
    priorities: [],
    prioritiesMap: {},
    departments: [],
    departmentsMap: {},
    ticket_sort_options: [],
    ticket_sort_optionsMap: {},
    service_types: [],
    service_typesMap: {},
    expense_statuses: [],
    expense_statusesMap: {},
    expense_types: [],
    expense_typesMap: {},
    verticals: [],
    ticket_types: [],
    ticket_typesMap: {},
    gst_percentage: [],
    gst_percentageMap: {},
  },
  DEFAULT_MASTERS_STATE = {
    masters: DEFAULT_MASTERS,
    updateMasters: () => null,
    mastersRental: DEFAULT_MASTERS_RENTAL,
    updateMastersRental: () => null,
    mastersDevelopment: DEFAULT_MASTERS_DEVELOPMENT,
    updateMastersDevelopment: () => null,
    mastersChapter: DEFAULT_MASTERS_CHAPTER,
    updateMastersChapter: () => null,
    mastersHA: DEFAULT_MASTERS_HA,
    updateMastersHA: () => null,
    mastersAgent: DEFAULT_MASTERS_AGENT,
    updateMastersAgent: () => null,
    mastersConcierge: DEFAULT_MASTERS_CONCIERGE,
    updateMastersConcierge: () => null,
  };

const MastersContext = createContext<MastersState>(DEFAULT_MASTERS_STATE);

interface MastersProviderProps {
  children: ReactNode;
}

function MastersProvider(props: MastersProviderProps) {
  const { children } = props,
    [masters, updateMasters] = useState<Masters>(DEFAULT_MASTERS),
    [mastersRental, updateMastersRental] = useState<MastersRental>(
      DEFAULT_MASTERS_RENTAL,
    ),
    [mastersDevelopment, updateMastersDevelopment] = useState<
      MastersDevelopment
    >(DEFAULT_MASTERS_DEVELOPMENT),
    [mastersChapter, updateMastersChapter] = useState<
      MastersChapter
    >(DEFAULT_MASTERS_CHAPTER),
    [mastersHA, updateMastersHA] = useState<MastersHomeAcquisition>(
      DEFAULT_MASTERS_HA,
    ),
    [mastersAgent, updateMastersAgent] = useState<MastersAgent>(
      DEFAULT_MASTERS_AGENT,
    ),
    [mastersConcierge, updateMastersConcierge] = useState<MastersConcierge>(
      DEFAULT_MASTERS_CONCIERGE,
    ),
    onUpdateMasters = (masters: Masters) => {
      updateMasters(masters);
    },
    onUpdateMastersRental = (mastersRental: MastersRental) => {
      updateMastersRental(mastersRental);
    },
    onUpdateMastersDevelopment = (mastersDevelopment: MastersDevelopment) => {
      updateMastersDevelopment(mastersDevelopment);
    },
    onUpdateMastersChapter = (mastersChapter: MastersChapter) => {
      updateMastersChapter(mastersChapter);
    },
    onUpdateMastersHA = (mastersHA: MastersHomeAcquisition) => {
      updateMastersHA(mastersHA);
    },
    onUpdateMastersAgent = (mastersAgent: MastersAgent) => {
      updateMastersAgent(mastersAgent);
    },
    onUpdateMastersConcierge = (mastersConcierge: MastersConcierge) => {
      updateMastersConcierge(mastersConcierge);
    },
    value = useMemo(
      () => ({
        masters: masters,
        updateMasters: onUpdateMasters,
        mastersRental: mastersRental,
        updateMastersRental: onUpdateMastersRental,
        mastersDevelopment: mastersDevelopment,
        updateMastersDevelopment: onUpdateMastersDevelopment,
        mastersChapter: mastersChapter,
        updateMastersChapter: onUpdateMastersChapter,
        mastersHA: mastersHA,
        updateMastersHA: onUpdateMastersHA,
        mastersAgent: mastersAgent,
        updateMastersAgent: onUpdateMastersAgent,
        mastersConcierge: mastersConcierge,
        updateMastersConcierge: onUpdateMastersConcierge,
      }),
      [masters, mastersRental, mastersDevelopment, mastersChapter, mastersHA, mastersConcierge],
    );

  return (
    <MastersContext.Provider value={value}>{children}</MastersContext.Provider>
  );
}

function useMasters() {
  return useContext(MastersContext);
}

export { MastersProvider, useMasters };

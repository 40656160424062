import { Coupon } from "../types/rental-payment";
import {
  Amenity,
  Mood,
  PropertyHighlight,
  PropertyLocationCategory,
  PropertyLocationCoupon,
  SAMPLE_SEO,
} from "../types/rental-property";
import { IRentalMastersService } from "../types/services/rental-masters-service";
import { FetchResult, GET, PATCH, POST } from "../utils/fetch";

type MoodsListResponse = {
  moods: Mood[];
};

type HighlightsListResponse = {
  highlights: PropertyHighlight[];
};

type LocationCategoryListResponse = {
  locations: any[];
};

type AmenitiesListResponse = {
  amenities: Amenity[];
};

type CouponsListResponse = {
  coupons: any[];
};

function constructGetCouponsQP(
  query?: string,
  locations?: string[],
  properties?: string[],
  property_slugs?: string[],
  applicable_from_date?: string,
  applicable_until_date?: string,
  check_in_date?: string,
  check_out_date?: string,
  active?: boolean,
  visible_on_website?: boolean,
  strict?: boolean,
  amount?: number,
  page?: string,
  page_size?: string,
) {
  const qp = new URLSearchParams();
  if (query) {
    qp.set("code", query);
  }
  if (locations?.length) {
    locations.forEach(l => qp.append("location_ids[]", l));
  }
  if (properties?.length) {
    properties.forEach(p => qp.append("property_ids[]", p));
  }
  if (property_slugs?.length) {
    property_slugs.forEach(p => qp.append("property_slugs[]", p));
  }
  if (applicable_from_date) {
    qp.set("applicable_from", applicable_from_date);
  }
  if (applicable_until_date) {
    qp.set("applicable_to", applicable_until_date);
  }
  if (check_in_date) {
    qp.set("check_in_date", check_in_date);
  }
  if (check_out_date) {
    qp.set("check_out_date", check_out_date);
  }
  if (active !== undefined) {
    qp.set("active", active.toString());
  }
  if (visible_on_website !== undefined) {
    qp.set("visible", visible_on_website.toString());
  }
  if (strict !== undefined) {
    qp.set("validate", strict.toString());
  }
  if (amount !== undefined) {
    qp.set("amount", amount.toString());
  }

  if (page) {
    qp.set("page", page);
  }
  if (page_size) {
    qp.set("per_page", page_size);
  }
  const marshaled = qp.toString();

  return marshaled;
}

class RentalMastersService implements IRentalMastersService {
  async addMood(
    name: string,
    description: string,
    active: boolean,
    image: File,
    seoTitle: string,
    seoDescription: string,
    seoKeywords: string,
    seoCanonical: string,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("description", description);
    formData.set("active", active.toString());
    formData.set("image_attributes[file]", image);
    formData.set("seo_attributes[title]", seoTitle);
    formData.set("seo_attributes[description]", seoDescription);
    formData.set("seo_attributes[keywords]", seoKeywords);
    formData.set("seo_attributes[canonical]", seoCanonical);

    const url = "/api/v2/rental/moods",
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updateMood(
    id: number,
    name?: string,
    description?: string,
    active?: boolean,
    imageId?: number,
    image?: File,
    seoId?: number,
    seoTitle?: string,
    seoDescription?: string,
    seoKeywords?: string,
    seoCanonical?: string,
  ): Promise<FetchResult<void>> {
    const formData = new FormData(),
      updateImage = image instanceof Image;
    if (name !== undefined) {
      formData.set("name", name);
    }
    if (description !== undefined) {
      formData.set("description", description);
    }
    if (active !== undefined) {
      formData.set("active", active.toString());
    }
    if (updateImage && imageId) {
      formData.set("image_attributes[id]", imageId.toString());
    }
    if (updateImage && image) {
      formData.set("image_attributes[file]", image);
    }
    if (seoId !== undefined) {
      formData.set("seo_attributes[id]", seoId.toString());
    }
    if (seoTitle !== undefined) {
      formData.set("seo_attributes[title]", seoTitle);
    }
    if (seoDescription !== undefined) {
      formData.set("seo_attributes[description]", seoDescription);
    }
    if (seoKeywords !== undefined) {
      formData.set("seo_attributes[keywords]", seoKeywords);
    }
    if (seoCanonical !== undefined) {
      formData.set("seo_attributes[canonical]", seoCanonical);
    }

    const url = `/api/v2/rental/moods/${id}`,
      { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getMoods(): Promise<FetchResult<Mood[]>> {
    const url = "/api/v2/rental/moods?per_page=100",
      { response, error } = await GET<MoodsListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }
    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: Mood[] = response.moods.map(each => {
      const mood: Mood = {
        ...each,
        seo: {
          ...SAMPLE_SEO,
          ...(each.seo || {}),
        },
      };

      return mood;
    });

    return {
      response: formatted,
      error: null,
    };
  }

  async addHighlight(
    name: string,
    description: string,
    active: boolean,
    image: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("description", description);
    formData.set("active", active.toString());
    formData.set("image_attributes[file]", image);

    const url = "/api/v2/rental/highlights?per_page=100",
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updateHighlight(
    id: number,
    name?: string,
    description?: string,
    active?: boolean,
    imageId?: number,
    image?: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData(),
      updateImage = image instanceof Image;
    if (name !== undefined) {
      formData.set("name", name);
    }
    if (description !== undefined) {
      formData.set("description", description);
    }
    if (active !== undefined) {
      formData.set("active", active.toString());
    }
    if (updateImage && imageId) {
      formData.set("image_attributes[id]", imageId.toString());
    }
    if (updateImage && image) {
      formData.set("image_attributes[file]", image);
    }

    const url = `/api/v2/rental/highlights/${id}`,
      { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getHighlights(): Promise<FetchResult<PropertyHighlight[]>> {
    const url = "/api/v2/rental/highlights?per_page=100",
      { response, error } = await GET<HighlightsListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }
    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      response: response.highlights,
      error: null,
    };
  }

  async addPropertyLocation(
    name: string,
    active: boolean,
    upcoming: boolean,
    city: string,
    country_id: number,
    state: string,
    seoTitle: string,
    seoDescription: string,
    seoKeywords: string,
    seoCanonical: string,
    image: File,
    sequence: number,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("city", city);
    formData.set("country_id", country_id.toString());
    formData.set("state", state);
    formData.set("seo_attributes[title]", seoTitle);
    formData.set("seo_attributes[description]", seoDescription);
    formData.set("seo_attributes[keywords]", seoKeywords);
    formData.set("seo_attributes[canonical]", seoCanonical);
    formData.set("active", active.toString());
    formData.set("upcoming", upcoming.toString());
    formData.set("image_attributes[file]", image);
    formData.set("sequence", sequence.toString());

    const url = "/api/v2/rental/locations",
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updatePropertyLocation(
    id: number,
    name?: string,
    active?: boolean,
    upcoming?: boolean,
    city?: string,
    country_id?: number,
    state?: string,
    seoId?: number,
    seoTitle?: string,
    seoDescription?: string,
    seoKeywords?: string,
    seoCanonical?: string,
    imageId?: number,
    image?: File,
    sequence?: number,
    coupons?: PropertyLocationCoupon[],
  ): Promise<FetchResult<void>> {
    const formData = new FormData(),
      updateImage = image instanceof File;

    if (name !== undefined) {
      formData.set("name", name);
    }
    if (city !== undefined) {
      formData.set("city", city);
    }
    if (country_id !== undefined) {
      formData.set("country_id", country_id.toString());
    }
    if (state !== undefined) {
      formData.set("state", state);
    }
    if (seoId !== undefined) {
      formData.set("seo_attributes[id]", seoId.toString());
    }
    if (seoTitle !== undefined) {
      formData.set("seo_attributes[title]", seoTitle);
    }
    if (seoDescription !== undefined) {
      formData.set("seo_attributes[description]", seoDescription);
    }
    if (seoKeywords !== undefined) {
      formData.set("seo_attributes[keywords]", seoKeywords);
    }
    if (seoCanonical !== undefined) {
      formData.set("seo_attributes[canonical]", seoCanonical);
    }
    if (active !== undefined) {
      formData.set("active", active.toString());
    }
    if (upcoming !== undefined) {
      formData.set("upcoming", upcoming.toString());
    }
    if (updateImage && imageId !== undefined) {
      formData.set("image_attributes[id]", imageId.toString());
    }
    if (updateImage && image) {
      formData.set("image_attributes[file]", image);
    }
    if (sequence !== undefined) {
      formData.set("sequence", sequence.toString());
    }
    if (coupons !== undefined) {
      coupons.forEach((c, i) => {
        const { id, auto_apply, sequence, location_id, coupon_id } = c;
        formData.append(`coupon_locations_attrs[${i}][id]`, id.toString());
        formData.append(
          `coupon_locations_attrs[${i}][rental_coupon_id]`,
          coupon_id.toString(),
        );
        formData.append(
          `coupon_locations_attrs[${i}][rental_location_id]`,
          location_id.toString(),
        );
        formData.append(
          `coupon_locations_attrs[${i}][auto_apply]`,
          auto_apply.toString(),
        );
        formData.append(
          `coupon_locations_attrs[${i}][sequence]`,
          sequence.toString(),
        );
      });
    }

    const url = `/api/v2/rental/locations/${id}`,
      { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getPropertyLocations(): Promise<
    FetchResult<PropertyLocationCategory[]>
  > {
    const url = "/api/v2/rental/locations?per_page=100",
      { response, error } = await GET<LocationCategoryListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: PropertyLocationCategory[] = response.locations.map(
      each => {
        const {
            active,
            sequence,
            state,
            city,
            country_id,
            id,
            image = {},
            seo,
            coupon_locations,
            name,
            upcoming,
          } = each,
          location: PropertyLocationCategory = {
            id,
            active: active || false,
            sequence,
            state: state || "",
            city: city || "",
            country_id: country_id,
            image: {
              id: image?.id,
              name: image?.name,
              url: image?.url ?? "",
              alt: image?.alt ?? "",
              caption: image?.caption ?? "",
              sequence: image?.sequence,
            },
            name: name || "",
            country: country_id,
            upcoming: upcoming || false,
            seo: {
              ...SAMPLE_SEO,
              ...(seo || {}),
            },
            coupons: (coupon_locations || []).map((each: any) => {
              const {
                  id,
                  rental_location_id,
                  rental_coupon_id,
                  auto_apply,
                  sequence,
                } = each,
                coupon: PropertyLocationCoupon = {
                  id: id,
                  coupon_id: rental_coupon_id,
                  location_id: rental_location_id,
                  auto_apply: auto_apply || false,
                  sequence: sequence || 0,
                };

              return coupon;
            }),
          };

        return location;
      },
    );

    return {
      response: formatted,
      error: null,
    };
  }

  async addAmenity(
    name: string,
    active: boolean,
    image: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("active", active.toString());
    formData.set("image_attributes[file]", image);

    const url = "/api/v2/rental/amenities",
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updateAmenity(
    id: number,
    name?: string,
    active?: boolean,
    imageId?: number,
    image?: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData(),
      updateImage = image instanceof Image;
    if (name !== undefined) {
      formData.set("name", name);
    }
    if (active !== undefined) {
      formData.set("active", active.toString());
    }
    if (updateImage && imageId) {
      formData.set("image_attributes[id]", imageId.toString());
    }
    if (updateImage && image) {
      formData.set("image_attributes[file]", image);
    }

    const url = `/api/v2/rental/amenities/${id}`,
      { response, error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        response: null,
        error,
      };
    }
    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getAmenities(): Promise<FetchResult<Amenity[]>> {
    const url = "/api/v2/rental/amenities?per_page=100",
      { response, error } = await GET<AmenitiesListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }
    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      response: response.amenities,
      error: null,
    };
  }

  async addCoupon(
    code: string,
    name: string,
    type: string,
    value: number,
    applicable_from_date?: string,
    applicable_until_date?: string,
    check_in_date?: string,
    check_out_date?: string,
    locations?: number[],
    properties?: string[],
    max_discount_amount?: number,
    min_order_amount?: number,
    min_booked_nights?: number,
    early_bird_duration_unit?: string,
    early_bird_duration_value?: number,
    fire_sale_duration_unit?: string,
    fire_sale_duration_value?: number,
    active?: boolean,
    visible_on_website?: boolean,
    tax_on_gross_amount?: boolean,
    auto_applicable_on_website?: boolean,
    description?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      name: name,
      code: code,
      coupon_offers_attributes: [
        {
          offer_type: type,
          value: value,
        },
      ],
    };
    if (applicable_from_date && applicable_until_date) {
      payload.applicable_from = applicable_from_date;
      payload.applicable_to = applicable_until_date;
    }
    if (check_in_date && check_out_date) {
      payload.check_in_date = check_in_date;
      payload.check_out_date = check_out_date;
    }
    if (locations) {
      payload.location_ids = locations;
    }
    if (properties) {
      payload.property_ids = properties;
    }
    if (max_discount_amount) {
      payload.max_discount = max_discount_amount;
    }
    if (min_order_amount) {
      payload.min_amount = min_order_amount;
    }
    if (min_booked_nights) {
      payload.min_nights = min_booked_nights;
    }
    if (active !== undefined) {
      payload.active = active;
    }
    if (early_bird_duration_unit && early_bird_duration_value) {
      payload.early_bird_duration = early_bird_duration_unit;
      payload.early_bird_value = early_bird_duration_value;
    }
    if (fire_sale_duration_unit && fire_sale_duration_value) {
      payload.firesale_duration = fire_sale_duration_unit;
      payload.firesale_value = fire_sale_duration_value;
    }
    if (visible_on_website !== undefined) {
      payload.visible = visible_on_website;
    }
    if (tax_on_gross_amount !== undefined) {
      payload.tax_on_gross_amount = tax_on_gross_amount;
    }
    if (auto_applicable_on_website !== undefined) {
      payload.auto_apply = auto_applicable_on_website;
    }
    if (description !== undefined) {
      payload.description = description;
    }

    const url = `/api/v2/rental/coupons`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async updateCoupon(
    id: number,
    name: string,
    offer_id?: number,
    code?: string,
    type?: string,
    value?: number,
    applicable_from_date?: string,
    applicable_until_date?: string,
    check_in_date?: string,
    check_out_date?: string,
    locations?: number[],
    properties?: string[],
    max_discount_amount?: number,
    min_order_amount?: number,
    min_booked_nights?: number,
    early_bird_duration_unit?: string,
    early_bird_duration_value?: number,
    fire_sale_duration_unit?: string,
    fire_sale_duration_value?: number,
    active?: boolean,
    visible_on_website?: boolean,
    tax_on_gross_amount?: boolean,
    auto_applicable_on_website?: boolean,
    description?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      name: name,
      id: id,
      code: code,
      coupon_offers_attributes: [
        {
          id: offer_id,
          offer_type: type,
          value: value,
        },
      ],
    };
    if (applicable_from_date && applicable_until_date) {
      payload.applicable_from = applicable_from_date;
      payload.applicable_to = applicable_until_date;
    }
    if (check_in_date && check_out_date) {
      payload.check_in_date = check_in_date;
      payload.check_out_date = check_out_date;
    }
    if (locations) {
      payload.location_ids = locations;
    }
    if (properties) {
      payload.property_ids = properties;
    }
    if (max_discount_amount) {
      payload.max_discount = max_discount_amount;
    }
    if (min_order_amount) {
      payload.min_amount = min_order_amount;
    }
    if (min_booked_nights) {
      payload.min_nights = min_booked_nights;
    }
    if (active !== undefined) {
      payload.active = active;
    }
    if (early_bird_duration_unit && early_bird_duration_value) {
      payload.early_bird_duration = early_bird_duration_unit;
      payload.early_bird_value = early_bird_duration_value;
    }
    if (fire_sale_duration_unit && fire_sale_duration_value) {
      payload.firesale_duration = fire_sale_duration_unit;
      payload.firesale_value = fire_sale_duration_value;
    }
    if (visible_on_website !== undefined) {
      payload.visible = visible_on_website;
    }
    if (tax_on_gross_amount !== undefined) {
      payload.tax_on_gross_amount = tax_on_gross_amount;
    }
    if (auto_applicable_on_website !== undefined) {
      payload.auto_apply = auto_applicable_on_website;
    }
    if (description !== undefined) {
      payload.description = description;
    }

    const url = `/api/v2/rental/coupons/${id}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    return {
      response: null,
      error: null,
    };
  }

  async getCoupons(
    query?: string,
    locations?: string[],
    properties?: string[],
    property_slugs?: string[],
    applicable_from_date?: string,
    applicable_until_date?: string,
    check_in_date?: string,
    check_out_date?: string,
    active?: boolean,
    visible_on_website?: boolean,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<Coupon[]>> {
    const marshaled = constructGetCouponsQP(
      query,
      locations,
      properties,
      property_slugs,
      applicable_from_date,
      applicable_until_date,
      check_in_date,
      check_out_date,
      active,
      visible_on_website,
      undefined,
      undefined,
      page,
      page_size,
    );

    let url = "/api/v2/rental/coupons";
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<CouponsListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: Coupon[] = response.coupons.map(each => {
      const {
          id,
          code,
          active,
          applicable_to,
          applicable_from,
          // current_redemption,
          // max_redemption,
          visible,
          tax_on_gross_amount,
          check_in_date,
          check_out_date,
          max_discount,
          min_amount,
          auto_apply,
          early_bird_duration,
          early_bird_value,
          firesale_duration,
          firesale_value,
          location_ids,
          property_ids,
          coupon_offers,
          min_nights,
          description,
          name,
        } = each,
        firstOffer = coupon_offers.length ? coupon_offers[0] : null,
        coupon: Coupon = {
          id: id,
          offer_id: firstOffer?.id || undefined,
          type: firstOffer?.offer_type || "",
          value: firstOffer?.value || 0,
          name: name,
          code: code,
          applicable_from_date: applicable_from || "",
          applicable_until_date: applicable_to || "",
          check_in_date: check_in_date,
          check_out_date: check_out_date,
          locations: location_ids || [],
          properties: property_ids || [],
          max_discount_amount: max_discount || 0,
          min_order_amount: min_amount || 0,
          min_booked_nights: min_nights || 0,
          early_bird_duration_unit: early_bird_duration || "",
          early_bird_duration_value: early_bird_value || 0,
          fire_sale_duration_unit: firesale_duration || "",
          fire_sale_duration_value: firesale_value || 0,
          active: active || false,
          visible_on_website: visible || false,
          tax_on_gross_amount: tax_on_gross_amount || false,
          auto_applicable_on_website: auto_apply || false,
          description: description || "",
        };

      return coupon;
    });

    return {
      error: null,
      response: formatted,
      paginate: {
        total_pages: 1,
        start_offset: 1,
        end_offset: formatted.length,
        current_page: 1,
        total_records: formatted.length,
      },
    };
  }

  async getCouponSuggestions(
    locations?: string[],
    property_slugs?: string[],
    applicable_from_date?: string,
    applicable_until_date?: string,
    check_in_date?: string,
    check_out_date?: string,
    amount?: number,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<Coupon[]>> {
    const marshaled = constructGetCouponsQP(
      undefined,
      locations,
      undefined,
      property_slugs,
      applicable_from_date,
      applicable_until_date,
      check_in_date,
      check_out_date,
      undefined,
      undefined,
      true,
      amount,
      page,
      page_size,
    );

    let url = "/api/v2/rental/coupons";
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<CouponsListResponse>(url);

    if (error) {
      return {
        error: error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: Coupon[] = response.coupons.map(each => {
      const {
          id,
          code,
          active,
          applicable_to,
          applicable_from,
          // current_redemption,
          // max_redemption,
          visible,
          tax_on_gross_amount,
          check_in_date,
          check_out_date,
          max_discount,
          min_amount,
          auto_apply,
          early_bird_duration,
          early_bird_value,
          firesale_duration,
          firesale_value,
          location_ids,
          property_ids,
          coupon_offers,
          min_nights,
          description,
          name,
        } = each,
        firstOffer = coupon_offers.length ? coupon_offers[0] : null,
        coupon: Coupon = {
          id: id,
          offer_id: firstOffer?.id || undefined,
          type: firstOffer?.offer_type || "",
          value: firstOffer?.value || 0,
          name: name,
          code: code,
          applicable_from_date: applicable_from || "",
          applicable_until_date: applicable_to || "",
          check_in_date: check_in_date,
          check_out_date: check_out_date,
          locations: location_ids || [],
          properties: property_ids || [],
          max_discount_amount: max_discount || 0,
          min_order_amount: min_amount || 0,
          min_booked_nights: min_nights || 0,
          early_bird_duration_unit: early_bird_duration || "",
          early_bird_duration_value: early_bird_value || 0,
          fire_sale_duration_unit: firesale_duration || "",
          fire_sale_duration_value: firesale_value || 0,
          active: active || false,
          visible_on_website: visible || false,
          tax_on_gross_amount: tax_on_gross_amount || false,
          auto_applicable_on_website: auto_apply || false,
          description: description || "",
        };

      return coupon;
    });

    return {
      error: null,
      response: formatted,
      paginate: {
        total_pages: 1,
        start_offset: 1,
        end_offset: formatted.length,
        current_page: 1,
        total_records: formatted.length,
      },
    };
  }
}

export default RentalMastersService;

import { useCallback, useState } from "react";

function useRefresh() {
  const [refresh, updateRefresh] = useState<number>(0),
    onRefresh = useCallback(() => updateRefresh(refresh => refresh + 1), [
      updateRefresh,
    ]);

  return {
    refresh,
    onRefresh,
  };
}

export default useRefresh;

import React, { ComponentType, ReactNode } from "react";

import { AgentServiceProvider } from "../../contexts/agent-service-context";
import { CallServiceProvider } from "../../contexts/call-service-context";
import { ChapterServiceProvider } from "../../contexts/chapter-service-context";
import { ConciergeServiceProvider } from "../../contexts/concierge-service-context";
import { CustomerRelationServiceProvider } from "../../contexts/customer-relationship-service-context";
import { CustomerServiceProvider } from "../../contexts/customer-service-context";
import { DashboardServiceProvider } from "../../contexts/dashboard-service-context";
import { DevelopmentOpportunityGuestServiceProvider } from "../../contexts/development-opportunity-guest-service-context";
import { DevelopmentOpportunityServiceProvider } from "../../contexts/development-opportunity-service-context";
import { DropdownOptionsServiceProvider } from "../../contexts/dropdown-options-service-context";
import { EnquiryServiceProvider } from "../../contexts/enquiry-service-context";
import { HomeAcquisitionServiceProvider } from "../../contexts/home-acquisition-service-context";
import { LoginServiceProvider } from "../../contexts/login-service-context";
import { MasterServiceProvider } from "../../contexts/masters-service-context";
import { OutboundOpportunityServiceProvider } from "../../contexts/outbound-opportunity-service-context";
import { RentalCalendarServiceProvider } from "../../contexts/rental-calendar-service-context";
import { RentalCommissionApprovalServiceProvider } from "../../contexts/rental-commission-approval-service-context";
import { RentalDepositApprovalServiceProvider } from "../../contexts/rental-deposit-approval-service-context";
import { RentalForceBookApprovalServiceProvider } from "../../contexts/rental-force-book-approval-service-context";
import { RentalInvoiceApprovalServiceProvider } from "../../contexts/rental-invoice-approval-service-context";
import { RentalMastersServiceProvider } from "../../contexts/rental-masters-service";
import { RentalPaymentApprovalServiceProvider } from "../../contexts/rental-payment-approval-service-context";
import { RentalPropertyServiceProvider } from "../../contexts/rental-property-service-context";
import { RentalTripApprovalServiceProvider } from "../../contexts/rental-trip-approval-service-context";
import { RentalTripGuestServiceProvider } from "../../contexts/rental-trip-guest-service-context";
import { RentalTripPaymentServiceProvider } from "../../contexts/rental-trip-payment-service-context";
import { RentalTripServiceProvider } from "../../contexts/rental-trip-service-context";
import { StaffServiceProvider } from "../../contexts/staff-service-context";
import { TaskServiceProvider } from "../../contexts/task-service-context";
import { IAgentService } from "../../types/services/agents-service";
import { ICallService } from "../../types/services/call-service";
import { IChapterService } from "../../types/services/chapter-service";
import { IConciergeService } from "../../types/services/concierge-service";
import { ICustomerRelationService } from "../../types/services/customer-relation-service";
import { ICustomerService } from "../../types/services/customer-service";
import { IDashboardService } from "../../types/services/dashboard-service";
import { IDevelopmentOpportunityGuestService } from "../../types/services/development-opportunity-guest-service";
import { IDevelopmentOpportunityService } from "../../types/services/development-opportunity-service";
import { IDropdownOptionsService } from "../../types/services/dropdown-options-service";
import { IEnquiryService } from "../../types/services/enquiry-service";
import { IHomeAcquisitionService } from "../../types/services/home-acquisition-service";
import { ILoginService } from "../../types/services/login-service";
import { IMastersService } from "../../types/services/masters-service";
import { IOutboundOpportunityService } from "../../types/services/outbound-opportunity-service";
import { IRentalCalendarService } from "../../types/services/rental-calendar-service";
import { IRentalCommissionApprovalService } from "../../types/services/rental-commission-approval-service";
import { IRentalDepositApprovalService } from "../../types/services/rental-deposit-approval-service";
import { IRentalForceBookApprovalService } from "../../types/services/rental-force-book-approval-service";
import { IRentalInvoiceApprovalService } from "../../types/services/rental-invoice-approval-service";
import { IRentalMastersService } from "../../types/services/rental-masters-service";
import { IRentalPaymentApprovalService } from "../../types/services/rental-payment-approval-service";
import { IRentalPropertyService } from "../../types/services/rental-property-service";
import { IRentalTripApprovalService } from "../../types/services/rental-trip-approval-service";
import { IRentalTripGuestService } from "../../types/services/rental-trip-guest-service";
import { IRentalTripPaymentService } from "../../types/services/rental-trip-payment-service";
import { IRentalTripService } from "../../types/services/rental-trip-service";
import { IStaffService } from "../../types/services/staff-service";
import { ITaskService } from "../../types/services/task-service";

type Provider<T = any> = [
  ComponentType<{ service: T; children: ReactNode }>,
  T,
];

export interface ServiceProviderProps {
  agentService: IAgentService;
  callService: ICallService;
  customerService: ICustomerService;
  customerRelationService: ICustomerRelationService;
  dashboardService: IDashboardService;
  developmentOpportunityService: IDevelopmentOpportunityService;
  developmentOpportunityGuestService: IDevelopmentOpportunityGuestService;
  chapterService: IChapterService;
  dropdownOptionsService: IDropdownOptionsService;
  enquiryService: IEnquiryService;
  loginService: ILoginService;
  masterService: IMastersService;
  rentalCalendarService: IRentalCalendarService;
  rentalMastersService: IRentalMastersService;
  rentalPropertyService: IRentalPropertyService;
  rentalTripService: IRentalTripService;
  rentalTripGuestService: IRentalTripGuestService;
  rentalTripPaymentService: IRentalTripPaymentService;
  staffService: IStaffService;
  taskService: ITaskService;
  homeAcquisitionService: IHomeAcquisitionService;
  conciergeService: IConciergeService;
  outboundOpportunityService: IOutboundOpportunityService;
  rentalTripApprovalService: IRentalTripApprovalService;
  rentalCommissionApprovalService: IRentalCommissionApprovalService;
  rentalPaymentApprovalService: IRentalPaymentApprovalService;
  rentalDepositApprovalService: IRentalDepositApprovalService;
  rentalInvoiceService: IRentalInvoiceApprovalService;
  rentalForceBookApprovalService: IRentalForceBookApprovalService;
  children: ReactNode;
}

function ServiceProvider(props: ServiceProviderProps) {
  const {
    agentService,
    callService,
    customerService,
    customerRelationService,
    dashboardService,
    developmentOpportunityGuestService,
    developmentOpportunityService,
    chapterService,
    dropdownOptionsService,
    enquiryService,
    loginService,
    masterService,
    rentalCalendarService,
    rentalMastersService,
    rentalPropertyService,
    rentalTripService,
    rentalTripGuestService,
    rentalTripPaymentService,
    staffService,
    taskService,
    homeAcquisitionService,
    conciergeService,
    outboundOpportunityService,
    rentalTripApprovalService,
    rentalPaymentApprovalService,
    rentalDepositApprovalService,
    rentalInvoiceService,
    rentalForceBookApprovalService,
    rentalCommissionApprovalService,
    children,
  } = props,
    providerTemplate: Provider[] = [
      [AgentServiceProvider, agentService],
      [CallServiceProvider, callService],
      [CustomerServiceProvider, customerService],
      [CustomerRelationServiceProvider, customerRelationService],
      [DashboardServiceProvider, dashboardService],
      [DevelopmentOpportunityServiceProvider, developmentOpportunityService],
      [DevelopmentOpportunityGuestServiceProvider, developmentOpportunityGuestService],
      [ChapterServiceProvider, chapterService],
      [DropdownOptionsServiceProvider, dropdownOptionsService],
      [EnquiryServiceProvider, enquiryService],
      [LoginServiceProvider, loginService],
      [MasterServiceProvider, masterService],
      [RentalCalendarServiceProvider, rentalCalendarService],
      [RentalMastersServiceProvider, rentalMastersService],
      [RentalPropertyServiceProvider, rentalPropertyService],
      [RentalTripServiceProvider, rentalTripService],
      [RentalTripPaymentServiceProvider, rentalTripPaymentService],
      [StaffServiceProvider, staffService],
      [TaskServiceProvider, taskService],
      [RentalTripGuestServiceProvider, rentalTripGuestService],
      [HomeAcquisitionServiceProvider, homeAcquisitionService],
      [ConciergeServiceProvider, conciergeService],
      [OutboundOpportunityServiceProvider, outboundOpportunityService],
      [RentalTripApprovalServiceProvider, rentalTripApprovalService],
      [RentalPaymentApprovalServiceProvider, rentalPaymentApprovalService],
      [RentalDepositApprovalServiceProvider, rentalDepositApprovalService],
      [RentalInvoiceApprovalServiceProvider, rentalInvoiceService],
      [RentalForceBookApprovalServiceProvider, rentalForceBookApprovalService],
      [RentalCommissionApprovalServiceProvider, rentalCommissionApprovalService],
    ];

  return (
    <>
      {providerTemplate.reduce((children, [Provider, service]) => {
        return <Provider service={service}>{children}</Provider>;
      }, children as ReactNode)}
    </>
  );
}

export default ServiceProvider;

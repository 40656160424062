import React from "react";

import Modal from "../Modal";
import RentalTripAdd from "./index";

function RentalTripAddModal() {
  return (
    <Modal id={RENTAL_TRIP_ADD_MODAL_ID}>
      {(resolve, meta) => (
        <RentalTripAdd
          callDialer={meta?.callDialer}
          customerId={meta?.customerId}
          defaultValue={meta?.defaultValue}
          onUpdate={resolve}
        />
      )}
    </Modal>
  );
}

export default RentalTripAddModal;

export const RENTAL_TRIP_ADD_MODAL_ID = "rental-trip-add-modal";

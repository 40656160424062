import Button from "@mui/material/Button";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import BANNER_404 from "../../assets/isprava-404.png";
import useNotFoundStyles from "./NotFoundStyles";

function NotFound() {
  const push = useNavigate(),
    goBack = useCallback(
      () => push(-1),
      [push],
    ),
    classNames = useNotFoundStyles();

  return (
    <div className={classNames.notFoundContainer}>
      <img
        alt="not-found"
        className={classNames.notFoundImg}
        src={BANNER_404}
      />
      <div className={classNames.title}>
        The star you are looking for is not in your galaxy
      </div>
      <div className={classNames.subtitle}>
        Please contact admin for further queries
      </div>
      <Button
        className={classNames.back}
        color="secondary"
        onClick={goBack}
        variant="contained"
      >
        Back
      </Button>
    </div>
  );
}

export default NotFound;

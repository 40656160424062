import { useState } from "react";

import { Pagination } from "../types/pagination";

export const DEFAULT_PAGINATION: Pagination = {
  total_pages: 1,
  current_page: 1,
  total_records: 1,
  start_offset: 0,
  end_offset: 0,
};

function usePagination() {
  const [pagination, updatePagination] = useState<Pagination>(
    DEFAULT_PAGINATION,
  );

  return {
    pagination: pagination,
    updatePagination: updatePagination,
  };
}

export default usePagination;

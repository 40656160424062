import makeStyles from '@mui/styles/makeStyles';

const useMobileHeaderStyles = makeStyles({
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
  nestedLinkItem: {
    "& a": {
      marginLeft: "1rem",
    },
  },
});

export default useMobileHeaderStyles;

import {Button} from "@mui/material";
import clx from "classnames";
import format from "date-fns/format";
import React, {useMemo} from "react";

import useEnquiryTripItemStyles from "./EnquiryTripItemStyles";

interface EnquiryTripItemProps {
  customerName: string;
  checkinDate?: string,
  checkoutDate?: string;
  pocName?: string;
  createdAt: string;
  opportunitySlug: string;
  className?: string;
}

function EnquiryTripItem(props: EnquiryTripItemProps) {
  const clxs = useEnquiryTripItemStyles(),
    {
      customerName,
      checkinDate,
      checkoutDate,
      pocName,
      createdAt,
      opportunitySlug,
      className,
    } = props,
    {href, stayFriendly, createdAtFriendly} = useMemo(
      () => {
        const checkinFriendly = checkinDate ? format(new Date(checkinDate), "do MMM, yyyy") : "-",
          checkoutFriendly = checkoutDate ? format(new Date(checkoutDate), "do MMM, yyyy") : "-",
          createdAtFriendly = createdAt ? format(new Date(createdAt), "do MMM, yyyy @ hh:mm a") : "-";

        return {
          href: `/rentals/trips/${opportunitySlug}`,
          stayFriendly: `${checkinFriendly} | ${checkoutFriendly}`,
          createdAtFriendly: createdAtFriendly,
        };
      },
      [
        opportunitySlug,
        checkinDate,
        checkoutDate,
        createdAt,
      ],
    ),
    ccx = clx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.name}>
        {customerName || "-"}
      </div>
      <Button
        className={clxs.view}
        color="secondary"
        component="a"
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        variant="contained"
      >
        View
      </Button>
      <div>{`Stay: ${stayFriendly}`}</div>
      <div>{`Requested: ${createdAtFriendly}`}</div>
      <div>{`POC: ${pocName}`}</div>
    </div>
  );
}

export default EnquiryTripItem;

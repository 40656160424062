import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, ReactNode, useCallback, useContext, useState } from "react";

interface SnackbarProviderProps {
  children: ReactNode;
}

function SnackbarProvider (props: SnackbarProviderProps) {
  const {children} = props,
    [state, setState] = useState<SnackbarState>(DEFAULT_SNACKBAR_STATE),
    handleDismiss = useCallback(
      () => setState(prev => ({
        ...prev,
        open: false,
      })),
      [setState],
    ),
    enqueueSnackbar = useCallback(
      (
        message: string,
        severity: AlertColor,
      ) => {
        setState({
          message: message,
          severity: severity,
          open: true,
        });
      },
      [setState],
    );

  return (
    <SnackbarContext.Provider value={enqueueSnackbar}>
      <>
        {children}
        <Snackbar
          autoHideDuration={7_500}
          onClose={handleDismiss}
          open={state.open}
        >
          <Alert
            onClose={handleDismiss}
            severity={state.severity}
            variant="filled"
          >
            {state.message}
          </Alert>
        </Snackbar>
      </>
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;

function useSnackbar () {
  return useContext(SnackbarContext);
}

export {
  useSnackbar,
};

const DEFAULT_SNACKBAR_STATE: SnackbarState = {
  open: false,
  severity: "info",
  message: "",
};

type SnackbarState = {
  open: boolean;
  severity: AlertColor;
  message: string;
};

const DEFAULT_SNACKBAR_PROVIDER_STATE: EnqueueSnackbar = () => {
  return;
};

const SnackbarContext = createContext<EnqueueSnackbar>(
  DEFAULT_SNACKBAR_PROVIDER_STATE,
);

type EnqueueSnackbar = (
  message: string,
  severity: AlertColor,
) => void;

import React, { createContext, ReactNode, useContext } from "react";

import { IDevelopmentOpportunityService } from "../types/services/development-opportunity-service";

const DevelopmentOpportunityServiceContext = createContext<
  IDevelopmentOpportunityService
>(undefined as any);

interface DevelopmentOpportunityServiceProviderProps {
  service: IDevelopmentOpportunityService;
  children: ReactNode;
}

function DevelopmentOpportunityServiceProvider(
  props: DevelopmentOpportunityServiceProviderProps,
) {
  const { service, children } = props;

  return (
    <DevelopmentOpportunityServiceContext.Provider value={service}>
      {children}
    </DevelopmentOpportunityServiceContext.Provider>
  );
}

function useDevelopmentOpportunityService() {
  return useContext(DevelopmentOpportunityServiceContext);
}

export {
  DevelopmentOpportunityServiceProvider,
  useDevelopmentOpportunityService,
};

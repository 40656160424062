import {NameAddrHeader, URI} from "jssip";
import {causes} from "jssip/lib/Constants";
import {
  EndEvent,
  RTCSession,
  RTCSessionEventMap,
} from "jssip/lib/RTCSession";

class SipCall {
  private readonly session: RTCSession;
  readonly from: NameAddrHeader;
  readonly to: NameAddrHeader;
  private eventHandler?: EventHandler;

  private _doneResolve?: (value: void) => void;
  private readonly _done: Promise<void>;

  constructor(
    session: RTCSession,
    from: NameAddrHeader,
    to: NameAddrHeader,
  ) {
    this.answer = this.answer.bind(this);
    this.hangup = this.hangup.bind(this);
    this.mute = this.mute.bind(this);
    this.unmute = this.unmute.bind(this);
    this.hold = this.hold.bind(this);
    this.unhold = this.unhold.bind(this);
    this.transfer = this.transfer.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
    this.setOnEventHandler = this.setOnEventHandler.bind(this);
    this.log = this.log.bind(this);

    this._done = new Promise<void>(resolve => this._doneResolve = resolve);
    this.session = session;
    this.from = from;
    this.to = to;

    const events: (keyof RTCSessionEventMap)[] = [
      "accepted",
      "confirmed",
      "progress",
      "hold",
      "unhold",
      "muted",
      "unmuted",
    ];

    events.forEach(
      (each: keyof RTCSessionEventMap) =>
        this.session.on(each, this.log.bind(null, `sip:session:${each}`)),
    );

    this.session.on("failed", this.handleEnded);

    this.session.on("ended", this.handleEnded)
  }

  answer(): Promise<MediaStream> {
    return new Promise<MediaStream>(
      resolve => {
        this.session.answer(
          {
            mediaConstraints: {audio: true, video: false},
            sessionTimersExpires: 180,
          },
        );

        this.session.connection.addEventListener(
          "addstream",
          (e: any) => {
            this.log("sip:session:addstream", [e]);

            resolve(e.stream);
          },
        );
      },
    );
  }

  get isEstablished() {
    return this.session.isEstablished();
  }

  hangup() {
    this.session.terminate(
      {
        cause: causes.BUSY,
        status_code: 486,
        reason_phrase: "Busy Here",
      },
    );
  }

  mute() {
    this.session.mute();
  }

  get isMuted() {
    return this.session.isMuted().audio ?? false;
  }

  unmute() {
    this.session.unmute();
  }

  hold(): Promise<void> {
    return new Promise<void>(
      resolve => this.session.hold(
        {useUpdate: true},
        () => resolve(),
      ),
    );
  }

  get isOnHold() {
    return this.session.isOnHold().local;
  }

  unhold(): Promise<void> {
    return new Promise<void>(
      resolve => this.session.unhold(
        {useUpdate: true},
        () => resolve(),
      ),
    );
  }

  get hasEnded() {
    return this.session.isEnded();
  }

  transfer(args: TransferArgs) {
    const cursor = this.session.direction === "incoming" ? this.to : this.from;

    const {
      scheme = cursor.uri.scheme,
      host = cursor.uri.host,
      port = cursor.uri.port,
      user,
    } = args;

    const uri = new URI(scheme, user, host, port);

    return this.session.refer(uri);
  }

  done(): Promise<void> {
    return this._done;
  }

  get concluded() {
    return this._doneResolve === undefined;
  }

  get mode() {
    return this.from.display_name ? "incoming" : "outgoing";
  }

  private handleEnded(e: EndEvent) {
    this.log("sip:session:ended", [e]);

    const resolve = this._doneResolve;

    this._doneResolve = undefined;

    resolve?.();
  }

  private log(event: string, ...args: any[]) {
    console.info(event, args);

    this.eventHandler?.(event);
  }

  setOnEventHandler(eventHandler: EventHandler) {
    this.eventHandler = eventHandler;
  }
}

export default SipCall;

type TransferArgs = {
  scheme?: string;
  user: string;
  host?: string;
  port?: number;
};

type EventHandler = (event: string) => void;

import React, { createContext, ReactNode, useContext } from "react";

import { ICustomerRelationService } from "../types/services/customer-relation-service";

const CustomerRelationServiceContext = createContext<ICustomerRelationService>(
  undefined as any,
);

interface CustomerRelationServiceProviderProps {
  service: ICustomerRelationService;
  children: ReactNode;
}

function CustomerRelationServiceProvider(
  props: CustomerRelationServiceProviderProps,
) {
  const { service, children } = props;
  return (
    <CustomerRelationServiceContext.Provider value={service}>
      {children}
    </CustomerRelationServiceContext.Provider>
  );
}

function useCustomerRelationService() {
  return useContext(CustomerRelationServiceContext);
}

export { CustomerRelationServiceProvider, useCustomerRelationService };
